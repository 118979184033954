import {
  AppstoreAddOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Layout,
  Modal,
  Pagination,
  Select,
  Spin,
  Tooltip,
  Typography,
  message,
  Row,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  PageTitleHeading,
  debounceTimeOut,
  default_pageSize,
  getSelectProperties,
  removeEmptyArrObjOrval,
} from "../../../utils/Common";
import FormFields from "../../../utils/FormFields";
import { staticSettingPortfolioList } from "../../../utils/settingCommon";
import PortfolioRelationshipServices from "../../../services/services/PortfolioRelationshipService";
import { normalizeString } from "../../../utils/linksCommon";
import FilterRightSideBar from "../../applicationlist/filter/FilterRightSideBar";
import useDebounce from "../../../helper/useDebounce";
import { dynamicRoutesLinks } from "../../../utils/portfolioRelationshipCommon";
import useDynamicNavigate from "../../../utils/navigateUtils";
import Delayed from "../../../utils/Delayed";
import { PortfolioItemDetails } from "../../portfolio/common/PortfolioCommon";
import { useLocation } from "react-router";

const { Option } = Select;

const styleBar = {
  overflow: "auto",
  minHeight: window.innerHeight / 2,
};

const rotate90 = {
  marginTop: "50px",
  marginLeft: "-10px",
};

const date = new Date();
var deadlines = [];
for (let index = date.getFullYear(); index <= date.getFullYear() + 2; index++) {
  for (let ind = 1; ind <= 4; ind++) {
    deadlines.push({
      value: "Q" + ind + "-" + index,
      label: "Q" + ind + "-" + index,
    });
  }
}

const changeTypeDummyValues = [
  { id: 1, label: "Retire", value: 1 },
  { id: 2, label: "Replace", value: 2 },
  { id: 3, label: "Upgrade", value: 3 },
  { id: 4, label: "Retain", value: 4 },
];

const ApplicationList = ({
  heading,
  setOpenPortfolioList,
  selectedModuleItems,
  selectedPortfolioItems,
  setSelectedPortfolioItems,
  // portfolioloading,
  // setPortfolioLoading,
  loading: portfolioloading,
  setLoading: setPortfolioLoading,
  setPage,
  page,
  limit,
  setLimit,
  scenarioId,
  setOpenChoosePortfolioType,
  portfolioTypeForm,
  onFormSubmit,
  selectedItemId,
  setSelectedItemId,
  changeTypeModal,
  setChangeTypeModal,
  changeTypeForm,
  changeTypeLoading,
  setChangeTypeLoading,
  portfolioItemIds,
  showConfirmDeleteScenarioItem,
  settingPortfolioList,
  setRelationshipPortfolioId,
  getItems,
  showFilter,
  setShowFilter,
  filterData,
  setFilterData,
  selectedFilters,
  setSelectedFilters,
  totalRecords,
  setTotalRecords,
  changeTypeData,
  setOpenPortfolioList_changeType,
  setselectedItemIDRows,
  filterSubmit,
  setFilterSubmit,
  selectedRelationFilters,
  setSelectedRelationFilters,
  ...props
}) => {
  const location = useLocation();
  const [openReplaceWithForm] = Form.useForm();
  const [isOpenReplaceWithModel, openReplaceWithModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [changeTypeFormData, setChangeTypeFormData] = useState({});
  const [addRelationhipModal, setRelationhipModal] = useState(false);
  const [selectedPortfolio, setSelectedPortfolio] = useState([]);
  const [relationshipItems, setRelationshipItems] = useState({});
  const [relationsList, setRelationsList] = useState([]);
  const navigateTo = useDynamicNavigate();
  const [changeTypeName, setChangeTypeName] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [changeTypeForm1] = Form.useForm();

  //   Filtration states

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 20) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const getSortedRecords = (list) => {
    let records = [];

    if (list) {
      list?.forEach((level_1) => {
        records.push(level_1);
        level_1?.child?.forEach((level_2) => {
          records.push(level_2);
          level_2?.child?.forEach((level_3) => {
            records.push(level_3);
          });
        });
      });
    }

    return records
  };

  useEffect(() => {
    setSelectedRows([]);
    setselectedItemIDRows([]);
  }, []);

  useEffect(() => {
    setList([]);
    setLoading(true);
    setTimeout(() => {
      if (
        selectedModuleItems?.name ===
        staticSettingPortfolioList.businessCapability
      ) {
        const sortedData = getSortedRecords(selectedPortfolioItems);
        setList(sortedData?.length > 0 ? sortedData : []);
      } else {
        setList(
          selectedPortfolioItems?.length > 0 ? selectedPortfolioItems : []
        );
      }
      setLoading(false);
    }, 1000);

    setSelectedRows([]);
    setselectedItemIDRows([]);
  }, [selectedPortfolioItems]);

  useEffect(() => {
    setSelectedPortfolio(portfolioTypeForm?.getFieldValue()?.portfolioType);
  }, [portfolioTypeForm?.getFieldValue()?.portfolioType]);

  const onDynamicLinkClick = (id = null, moduleName = "", rest = {}) => {
    const redirectLink = dynamicRoutesLinks?.find(
      (item) => item?.type === moduleName
    );
    if (redirectLink?.link && redirectLink?.moduleName && id) {
      const newLink = redirectLink?.link + redirectLink?.moduleName + "/" + id;
      navigateTo(newLink, true);
    }
  };

  const pascalToSnakeCase = (str) => {
    return str.replace(
      /[A-Z]/g,
      (match, offset) => (offset ? "_" : "") + match.toLowerCase()
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
      setselectedItemIDRows([]);
    } else {
      const allIds = [];
      list?.map((row) => {
        allIds.push(row.id?.toString());
      });

      setSelectedRows(allIds);
      setselectedItemIDRows(allIds);
    }
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
      setselectedItemIDRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
      setselectedItemIDRows([...selectedRows, id]);
    }
  };

  const getAllConnectedRelations = async (
    id = null,
    selectedPortfolio = null
  ) => {
    try {
      setLoading(true);
      setRelationshipItems({});
      setRelationsList([]);
      const nodes = { nodeId: id, nodeTag: selectedPortfolio, isSnake: true };
      const response = await PortfolioRelationshipServices.getAllNode(nodes);
      if (response?.statusText !== "OK") {
        return message.error("Failed to fetch data.");
      }

      const newData = response?.data?.data;
      if (newData && Object.keys(newData)?.length > 0) {
        setLoading(false);
        setSelectedItemId(id);
        setRelationhipModal(true);
        setRelationshipItems(response?.data?.data);
      } else {
        message.info("No items found.");
      }
    } catch (error) {
      console.error("Error fetching Scenario items:", error);
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  const GetAddOrDeleteBtn = ({
    portfolioItemIds,
    itemId,
    name,
    selectedPortfolioName = null,
    relations = [],
    portfolioSettingId = null,
  }) => {
    const portfolioName = selectedPortfolioName
      ? selectedPortfolioName
      : portfolioTypeForm?.getFieldValue()?.portfolioType;
    const findPortfolioItem = portfolioItemIds?.find(
      (item) => item?.itemId === itemId
    );

    return (
      <div className="app-actions">
        <Button
          type={`primary`}
          className="btnAction scenario-color-replacewith"
          // ghost
          onClick={() => {
            setOpenPortfolioList_changeType(true);
            setOpenPortfolioList(false);
            setSelectedItemId(itemId);

            setChangeTypeName("Replace");
          }}
        >
          Replace
        </Button>
        <Button
          type={`primary`}
          className="btnAction scenario-color-upgradeto"
          // ghost
          onClick={() => {
            if (portfolioSettingId) {
              setRelationshipPortfolioId(portfolioSettingId);
            }
            if (changeTypeData?.length === 0) {
              return message.error("Change type not found.");
            }
            setSelectedItemId(itemId);

            if (relations && relations?.length > 0) {
              setRelationsList(relations);
            }
            setChangeTypeModal(true);
            setChangeTypeName("Upgrade");
          }}
        >
          Upgrade
        </Button>

        <Button
          type={`primary`}
          className="scenario-color-retain"
          // ghost
          onClick={() => {
            if (portfolioSettingId) {
              setRelationshipPortfolioId(portfolioSettingId);
            }
            if (changeTypeData?.length === 0) {
              return message.error("Change type not found.");
            }
            setSelectedItemId(itemId);
            if (relations && relations?.length > 0) {
              setRelationsList(relations);
            }
            setChangeTypeModal(true);
            setChangeTypeName("Retain");
          }}
        >
          Retain
        </Button>
        <Button
          type={`primary`}
          className="btnAction scenario-color-retire"
          // ghost
          onClick={() => {
            if (portfolioSettingId) {
              setRelationshipPortfolioId(portfolioSettingId);
            }
            if (changeTypeData?.length === 0) {
              return message.error("Change type not found.");
            }
            setSelectedItemId(itemId);
            if (relations && relations?.length > 0) {
              setRelationsList(relations);
            }
            setChangeTypeModal(true);
            setChangeTypeName("Retire");
          }}
        >
          Retire
        </Button>
      </div>

      /* <Tooltip title="Add">
        <Button
          type={`primary`}
          disabled={portfolioloading}
          icon={<PlusOutlined />}
          onClick={() => {
            if (portfolioSettingId) {
              //   const currentValues = changeTypeForm.getFieldsValue();
              //   // Merge the new values with the previous ones
              //   const mergedValues = {
              //     ...currentValues,
              //     portfolioSettingsId: portfolioSettingId,
              //   };
              //   // Set the merged values to the form
              //   changeTypeForm.setFieldsValue(mergedValues);

              setRelationshipPortfolioId(portfolioSettingId);
            }
            if (changeTypeData?.length === 0) {
              return message.error("Change type not found.");
            }
            setSelectedItemId(itemId);
            if (relations && relations?.length > 0) {
              setRelationsList(relations);
            }
            setChangeTypeModal(true);
            setChangeTypeName("");
          }}
        />
      </Tooltip>  */
    );

    // if (!findPortfolioItem) {
    //   return (
    //     <div className="app-actions">
    //       <div>
    //         <Button type={`primary`} className="btnAction" ghost>
    //           Replace
    //         </Button>
    //         <Button
    //           type={`primary`}
    //           className="btnAction"
    //           ghost
    //           onClick={() => {
    //             if (portfolioSettingId) {
    //               setRelationshipPortfolioId(portfolioSettingId);
    //             }
    //             if (changeTypeData?.length === 0) {
    //               return message.error("Change type not found.");
    //             }
    //             setSelectedItemId(itemId);
    //             if (relations && relations?.length > 0) {
    //               setRelationsList(relations);
    //             }
    //             setChangeTypeModal(true);
    //             setChangeTypeName("");
    //           }}
    //         >
    //           Upgrade
    //         </Button>
    //       </div>
    //       <div style={{ marginTop: "5px" }}>
    //         <Button type={`primary`} className="btnAction" ghost>
    //           Retain
    //         </Button>
    //         <Button type={`primary`} className="btnAction" ghost>
    //           Retire
    //         </Button>
    //       </div>
    //     </div>

    //     /* <Tooltip title="Add">
    //       <Button
    //         type={`primary`}
    //         disabled={portfolioloading}
    //         icon={<PlusOutlined />}
    //         onClick={() => {
    //           if (portfolioSettingId) {
    //             //   const currentValues = changeTypeForm.getFieldsValue();
    //             //   // Merge the new values with the previous ones
    //             //   const mergedValues = {
    //             //     ...currentValues,
    //             //     portfolioSettingsId: portfolioSettingId,
    //             //   };
    //             //   // Set the merged values to the form
    //             //   changeTypeForm.setFieldsValue(mergedValues);

    //             setRelationshipPortfolioId(portfolioSettingId);
    //           }
    //           if (changeTypeData?.length === 0) {
    //             return message.error("Change type not found.");
    //           }
    //           setSelectedItemId(itemId);
    //           if (relations && relations?.length > 0) {
    //             setRelationsList(relations);
    //           }
    //           setChangeTypeModal(true);
    //           setChangeTypeName("");
    //         }}
    //       />
    //     </Tooltip>  */
    //   );
    // } else {
    //   const { id = null, itemId = null } = findPortfolioItem;
    //   return (
    //     <Tooltip title="Added">
    //       <Button
    //         type={`default`}
    //         // disabled={portfolioloading}
    //         disabled={true}
    //         onClick={async () => {
    //           await showConfirmDeleteScenarioItem({
    //             id,
    //             name,
    //             itemId,
    //             portfolioName,
    //           });
    //         }}
    //       >
    //         Added
    //       </Button>
    //     </Tooltip>
    //   );
    // }
  };

  const GetBPLContentProcess = ({
    displayname,
    description,
    id,
    rest,
    item,
  }) => {
    const { moduleName = "" } = rest;

    return (
      <div key={Math.random()} className="portfolio-listbx">
        <table width={`100%`} className="mb-1">
          <tbody>
            <tr>
              <td width={`5%`}>
                <Checkbox
                  checked={selectedRows.includes(id?.toString())}
                  onChange={() => handleCheckboxChange(id?.toString())}
                />
              </td>
              <td width={`90%`}>
                <PortfolioItemDetails
                  item={item}
                  moduleName={moduleName}
                  basePath={location?.pathname}
                  defaultActiveKey={1}
                />
                
              </td>
              <td width={`5%`} className="text-nowrap">
                <GetAddOrDeleteBtn
                  portfolioItemIds={portfolioItemIds[selectedPortfolio]}
                  itemId={id}
                  name={displayname}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const getBPLContentCapability = (item) => {
    let {
      displayname,
      description,
      moduleName = "",
      id = null,
    } = item;
    return (
      <div key={Math.random()} className=" portfolio-listbx">
        <table width={`100%`} className="mb-1">
          <tbody>
            <tr>
              <td width={`5%`}>
                <Checkbox
                  checked={selectedRows.includes(id?.toString())}
                  onChange={() => handleCheckboxChange(id?.toString())}
                />
              </td>
              <td width={`90%`}>
                <PortfolioItemDetails
                  item={item}
                  moduleName={moduleName}
                  basePath={location?.pathname}
                  defaultActiveKey={1}
                />
                
              </td>
              <td width={`5%`} className="text-nowrap">
                <GetAddOrDeleteBtn
                  portfolioItemIds={portfolioItemIds[selectedPortfolio]}
                  itemId={id}
                  name={displayname}
                />
                {/* &emsp;
                  <Tooltip title="View and Add relationship">
                  <Button
                    type={`primary`}
                    disabled={loading}
                    loading={loading}
                    onClick={async () => {
                      await getAllConnectedRelations(id, selectedPortfolio);
                    }}
                    icon={<AppstoreAddOutlined />}
                  >  
                  </Button>
                </Tooltip>   */}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const GetBPLContentPortfolios = (rowData) => {
    let {
      //referenceID,
      referenceid,
      name,
      description,
      id,
      rest,
      item,
    } = rowData;
    const { moduleName = "" } = rest;
    return (
      <div key={Math.random()} className=" portfolio-listbx">
        <table width={`100%`} className="mb-1">
          <tbody>
            <tr>
              <td width={`5%`}>
                <Checkbox
                  checked={selectedRows.includes(id?.toString())}
                  onChange={() => handleCheckboxChange(id?.toString())}
                />
              </td>
              <td width={`90%`}>
                <PortfolioItemDetails
                  item={item}
                  moduleName={moduleName}
                  basePath={location?.pathname}
                  defaultActiveKey={1}
                  badgeValue={referenceid}
                />
                {/* <div className="my-1 portfolio-item">
                  <div>
                    <Badge
                      count={referenceid?.toString()}
                      className="fw-small  badge_id"
                      overflowCount={9999999999}
                    />
                  </div>
                  <div className="des-bx">
                    <h4
                      className="text-primary cursor-pointer"
                      onClick={() => {
                        onDynamicLinkClick(id, moduleName, rest);
                      }}
                    >
                      {name}
                    </h4>
                    <p>{description}</p>{" "}
                  </div>
                </div> */}
              </td>
              <td width={`5%`} className="text-nowrap">
                <GetAddOrDeleteBtn
                  portfolioItemIds={portfolioItemIds[selectedPortfolio]}
                  itemId={id}
                  name={name}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const ChangeType = ({
    type,
    name,
    label,
    formClassName,
    options,
    onChange,
    rules,
  }) => {
    return (
      <Form.Item
        label={label}
        name={name}
        className={formClassName}
        rules={rules}
      >
        <Select placeholder="Select" onChange={onChange}>
          {options.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
    );
  };

  const getTitle = () => {
    const findItem = list?.find((item) => item?.id === selectedItemId);
    let title = "";
    if (findItem) {
      title = findItem?.displayname || "";
    }
    return title;
  };

  //   Relationship card
  const TableItems = ({ item, index, moduleName, relations }) => {
    return (
      <div key={index} className=" portfolio-listbx">
        <table width="100%" className="mb-1">
          <tbody>
            <tr>
              <td width="95%">
                <div className="text-wrap">
                  {/* <span className="fw-medium">4245</span> */}
                  <span className="rounded-sm text-wrap text-lg text-primary">
                    {item?.displayname}
                  </span>
                </div>
                <div className="des-bx">{item?.description}</div>
              </td>
              <td width="5%" className="text-nowrap">
                <GetAddOrDeleteBtn
                  portfolioItemIds={portfolioItemIds[moduleName]}
                  itemId={item?.id}
                  name={item?.displayName}
                  selectedPortfolioName={moduleName}
                  relations={relations || []}
                  portfolioSettingId={item?.portfolioSettingId}
                />
                {/* <Button
                  type={`primary`}
                  onClick={() => {
                    setSelectedItemId(item?.id);
                    setChangeTypeModal(true);
                  }}
                >
                  <span>Add</span>
                </Button> */}
              </td>
            </tr>
            <hr />
          </tbody>
        </table>
      </div>
    );
  };
  const RelationshipCard = ({ title, items }) => {
    const moduleName = pascalToSnakeCase(title);
    const getColor =
      settingPortfolioList?.find(
        (item) => item?.moduleName?.toLowerCase() === moduleName?.toLowerCase()
      ) || {};
    const color = getColor && getColor?.color ? getColor?.color : "#000";
    return (
      <Card
        title={<span className="h4">{normalizeString(title)}</span>}
        style={{ backgroundColor: `${color}`, marginBottom: "10px" }}
        headStyle={{ backgroundColor: `${color}`, padding: "0px 10px" }}
        bodyStyle={{ backgroundColor: `#fff`, padding: "0px 10px" }}
      >
        {(items &&
          items?.length > 0 &&
          items?.map((item, index) => (
            <TableItems
              item={{
                ...item,
                portfolioSettingId: getColor?.id ? getColor?.id : null,
              }}
              index={index}
              moduleName={moduleName}
              relations={items}
            />
          ))) || <div>Relations not found.</div>}
      </Card>
    );
  };

  const getAllSelectionFields = () => {
    const currentPortfolio = settingPortfolioList?.find(
      (item) =>
        item?.name?.toLowerCase() === selectedModuleItems?.name?.toLowerCase()
    );

    let properties = [];
    if (currentPortfolio) {
      properties = getSelectProperties(
        currentPortfolio?.portfolioSections,
        true
      );
    }

    return properties;
  };

  useEffect(() => {
    // if (showFilter) {
    const getAllProperties = getAllSelectionFields() || [];

    setFilterData(getAllProperties);
    // }
  }, [selectedModuleItems?.name]);

  //   Filter
  const getListItems = async (selectedModuleItems, queryParams) => {
    await getItems(selectedModuleItems, {
      ...queryParams,
      page: 1,
      limit: default_pageSize,
    });
  };
  const debouncedFilter = useDebounce(selectedFilters, debounceTimeOut);
  useEffect(() => {
    if (Object.keys(debouncedFilter)?.length > 0 && filterSubmit) {
      setPage(1);
      const removedItems = removeEmptyArrObjOrval(debouncedFilter);
      getListItems(selectedModuleItems, removedItems);
    }
    // fetchFilteredData(debouncedFilter);
  }, [filterSubmit, debouncedFilter]);

  return (
    <div>
      <div className="d-flex justify-content-between modelpopup_bx">
        <div id="portfolioList" className="container-fluid popup_leftpnlbx">
          <div className="popup_headerbx">
            <div className="inputsec_bx">
              <Checkbox checked={selectAll} onChange={handleSelectAll} />
              <span>Select All </span>
              {(selectedRows.length && (
                <h5>{"   (" + selectedRows.length + " selected )"} </h5>
              )) ||
                ""}
            </div>

            <div className="bulk-operation">
              <div>Bulk Operation</div>

              <div className="formctrl">
                <Form
                  form={changeTypeForm1}
                  // labelCol={{ span: 12 }}
                  // wrapperCol={{ span: 16 }}
                  initialValues={{}}
                  className=" "
                  onFinish={async (formData) => {
                    if (selectedRows.length) {
                      if (changeTypeName === "Replace") {
                        setOpenPortfolioList_changeType(true);
                        setOpenPortfolioList(false);
                        setChangeTypeName("Replace");
                      } else {
                        setChangeTypeModal(true);
                        setChangeTypeName(changeTypeName);
                      }
                    } else {
                      message.error("Select atleast one record ");
                    }
                  }}
                >
                  <div className="inputbx">
                    <ChangeType
                      type={`select`}
                      name={`changeTypeId`}
                      formClassName={`mb-0 selectinput`}
                      options={changeTypeData}
                      rules={[
                        {
                          required: true,
                          message: "Please select Change Type",
                        },
                      ]}
                      onChange={(value) => {
                        const selectedChangeType = changeTypeData.find(
                          (type) => type.value === value
                        );
                        setChangeTypeFormData({
                          ...changeTypeFormData,
                          ["changeType"]: value,
                        });
                        setChangeTypeName(
                          selectedChangeType ? selectedChangeType.name : ""
                        );
                      }}
                    />

                    <Button
                      type={`primary`}
                      htmlType="submit"
                      loading={loading}
                    >
                      Proceed
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>

          <Spin spinning={portfolioloading || loading}>
            <div
              className="overflow-y listview_bx"
              style={{ height: window.innerHeight / 1.5 }}
              // onScroll={handleScroll}
            >
              {portfolioloading || loading ? (
                <Delayed waitBeforeShow={2000}>
                  <div
                    className={`text-base font-bold mt-4 ${
                      portfolioloading || loading ? "d-none" : ""
                    }`}
                  >
                    {portfolioloading || loading
                      ? "Loading..."
                      : "No items found."}
                  </div>
                </Delayed>
              ) : list?.length > 0 ? (
                <>
                  {selectedModuleItems?.name ===
                  staticSettingPortfolioList.businessCapability ? (
                    list?.map((el, index) => {
                      return getBPLContentCapability(el);
                    })
                  ) : selectedModuleItems?.name ===
                    staticSettingPortfolioList.businessProcess ? (
                    list?.map((item, index) => {
                      let {
                        referenceid = "",
                        nameinsourcesystem = "",
                        description = "",
                        displayname = "",
                        id,
                        ...rest
                      } = item;
                      return (
                        <GetBPLContentProcess
                          description={description}
                          displayname={displayname}
                          id={id}
                          item={item}
                          rest={rest}
                          index={index}
                        />
                      );
                    })
                  ) : selectedModuleItems?.name ? (
                    list?.map((item, index) => {
                      let {
                        referenceid = "",
                        nameinsourcesystem = "",
                        displayname = "",
                        description = "",
                        id = null,
                        ...rest
                      } = item;
                      return (
                        <GetBPLContentPortfolios
                          description={description}
                          name={displayname}
                          referenceid={referenceid}
                          id={id}
                          rest={rest}
                          index={index}
                          item={item}
                        />
                      );
                    })
                  ) : (
                    <Delayed waitBeforeShow={2000}>
                      <div
                        className={`text-base font-bold mt-4 ${
                          portfolioloading || loading ? "d-none" : ""
                        }`}
                      >
                        {portfolioloading || loading
                          ? "Loading..."
                          : "No items found."}
                      </div>
                    </Delayed>
                  )}
                </>
              ) : (
                <Delayed waitBeforeShow={2000}>
                  <div
                    className={`text-base font-bold mt-4 ${
                      portfolioloading || loading ? "d-none" : ""
                    }`}
                  >
                    No items found.
                  </div>
                </Delayed>
              )}
            </div>
            {(list?.length && (
              <Pagination
                className="mt-2 text-right"
                total={totalRecords}
                showTotal={(total) => `Total ${total} items`}
                defaultPageSize={default_pageSize}
                defaultCurrent={page}
                showSizeChanger={false}
                // pageSizeOptions={[10, 20, 50, 100, 200, 500, 1000, "Show All"]}
                onChange={(p, ps) => {
                  setFilterSubmit(true);
                  setLimit(ps);
                  setPage(p);
                }}
              />
            )) || <div></div>}
          </Spin>
        </div>
        {/* {(settingPortfolioList?.length > 0 && ( */}

        <div className="popup_rightpnlbx">
          {/* <Filter portfolioData={settingPortfolioList} /> */}
          <FilterRightSideBar
            activeGridStyle={1}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            portfolioData={filterData}
            selectedFilters={selectedFilters}
            setSelectedFilters={(value) => {
              setPage(1);
              setSelectedFilters(value);
            }}
            optionalLevel={
              selectedModuleItems?.name ===
              staticSettingPortfolioList.businessCapability
            }
            allowSlider={true}
            //Filter relations
            filterSubmit={filterSubmit}
            setFilterSubmit={(value) => {
              setPage(1);
              setFilterSubmit(value);
            }}
            selectedRelationFilters={selectedRelationFilters}
            setSelectedRelationFilters={setSelectedRelationFilters}
          />
        </div>

        {/* )) || <></>} */}
      </div>

      <Modal
        destroyOnClose
        header={false}
        footer={false}
        requiredMark={true}
        maskClosable={false}
        open={changeTypeModal}
        onCancel={() => {
          changeTypeForm?.resetFields();
          setChangeTypeLoading(false);
          setChangeTypeName("");
          setChangeTypeModal(false);

          setSelectedRows([]);
          setselectedItemIDRows([]);
        }}
      >
        <Form
          form={changeTypeForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{}}
          className="mt-4"
          onFinish={async (formData) => {
            await setChangeTypeLoading(true);

            const changeTypeId = changeTypeData.find(
              (type) => type.name === changeTypeName
            );

            formData["changeType"] = changeTypeName;
            formData["changeTypeId"] = changeTypeId.id;
            onFormSubmit(formData);

            setSelectedRows([]);
            setselectedItemIDRows([]);
          }}
        >
           
          {changeTypeName === "Upgrade" && (
            <FormFields
              type={`input`}
              name={`version`}
              label={`Version`}
              formClassName={`mb-2`}
              rules={[{ required: true, message: "Please enter Version!" }]}
            />
          )}
          {changeTypeName === "Replace" && (
            <>
              <FormFields
                type={`select`}
                width={`100%`}
                name={`replaceWith`}
                label={`Replace with`}
                formClassName={`mb-2`}
                rules={[
                  {
                    required: true,
                    message: "Please select replace with",
                  },
                ]}
                options={
                  !addRelationhipModal
                    ? list
                        ?.filter((item) => item?.id !== selectedItemId)
                        ?.map((el) => {
                          return {
                            ...el,
                            value: el?.id,
                            label: `${el?.referenceid} ${
                              el?.displayname
                            }`,
                          };
                        })
                    : relationsList
                        ?.filter((item) => item?.id !== selectedItemId)
                        ?.map((el) => {
                          return {
                            ...el,
                            value: el?.id,
                            label:
                              el?.nameinsourcesystem ||
                              el?.displayname ||
                              el?.displayname ||
                              el?.nameinsourcesystem,
                          };
                        }) || []
                }
                columns={{ wrapperCol: { span: 24 } }}
                onChange={(value) => {
                  setChangeTypeFormData({
                    ...changeTypeFormData,
                    ["replaceWith"]: value,
                  });
                }}
              />
            </>
          )}
          <FormFields
            type={`textarea`}
            label={`Comment`}
            name={`comment`}
            formClassName={`mb-2`}
            rows={3}
          />
          <Form.Item
            label={`Deadline`}
            name="deadline"
            formClassName={`mb-2`}
            key={`deadline`}
            rules={[
              {
                required: false,
                message: "Please select deadline",
              },
            ]}
            onChange={(value) => {
              setChangeTypeFormData({
                ...changeTypeFormData,
                ["deadline"]: value,
              });
            }}
          >
            <DatePicker className="mb-2 text-start w-full" />
          </Form.Item>
           
          <Button
            type={`primary`}
            htmlType={`submit`}
            className="w-100"
            loading={changeTypeLoading}
          >
            Submit
          </Button>
        </Form>
      </Modal>

      <Modal
        destroyOnClose
        header={false}
        footer={false}
        maskClosable={false}
        open={isOpenReplaceWithModel}
        onCancel={() => {
          //   setChangeTypeModal(false);
          openReplaceWithModel(false);
        }}
        closable
      >
        <Form
          form={openReplaceWithForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{}}
          className="mt-4"
          onFinish={(formData) => {
            console.log("formData", formData);
          }}
        >
          <FormFields
            type={`input`}
            label={`Name`}
            name="replaceWith"
            formClassName={`my-4`}
          />
          <Button type={`primary`} htmlType={`submit`} className="w-100">
            Submit
          </Button>
        </Form>
      </Modal>

      {/* Business Capabilities */}
      <Modal
        destroyOnClose
        header={false}
        footer={false}
        maskClosable={false}
        title={getTitle()}
        open={addRelationhipModal}
        onCancel={() => {
          setRelationhipModal(false);
          setRelationshipPortfolioId(null);
        }}
        width={(window?.innerWidth * 80) / 100}
        closable
      >
        {(relationshipItems &&
          relationshipItems?.relations?.length > 0 &&
          relationshipItems?.relations?.map((item) => {
            return (
              <RelationshipCard
                title={item?.moduleName}
                items={item?.tags || []}
              />
            );
          })) || <></>}
        {/* <Card
          title={<span className="h4">Business Process</span>}
          style={{ backgroundColor: `#fff280`, marginBottom: "10px" }}
          headStyle={{ backgroundColor: `#fff280`, padding: "0px 10px" }}
          bodyStyle={{ backgroundColor: `#fff`, padding: "0px 10px" }}
        >
          <div>
            <table width="100%" className="mb-1">
              <tbody>
                <tr>
                  <td width="95%">
                    <div className="text-wrap">
                      <span className="fw-medium">4245</span>
                      <span className="rounded-sm text-wrap text-lg text-primary">
                        TBD (2026 Q1)
                      </span>
                    </div>
                    <div className="whitespace-normal overflow-hidden fs-small">
                      gghg
                    </div>
                  </td>
                  <td width="5%" className="text-nowrap">
                    <Button
                      type="button"
                      onClick={() => {
                        setChangeTypeModal(true);
                      }}
                    >
                      <span>Add</span>
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
            <hr />
          </div>
          <div>
            <table width="100%" className="mb-1">
              <tbody>
                <tr>
                  <td width="95%">
                    <div className="text-wrap">
                      <span className="fw-medium">423</span>
                      <span className="rounded-sm text-wrap text-lg text-primary">
                        TBD (2025 Q1)
                      </span>
                    </div>
                    <div className="whitespace-normal overflow-hidden fs-small"></div>
                  </td>
                  <td width="5%" className="text-nowrap">
                    <Button
                      type="button"
                      onClick={() => {
                        setChangeTypeModal(true);
                      }}
                    >
                      <span>Add</span>
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
            <hr />
          </div>
        </Card> */}
      </Modal>
    </div>
  );
};

ApplicationList.propTypes = {};

export default ApplicationList;
