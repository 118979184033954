import { Button, Flex } from "antd";
import React, { useEffect, useState } from "react";
import DCBarCharts from "./DCBarCharts";
import { CloseOutlined } from "@ant-design/icons";
import CompletenessDataTable from "./CompletenessDataTable";
import { getAllMenuPortfolio } from "../../../utils/settingCommon";

const DCViewDetails = ({
  portfolioData,
  data,
  dataRelations = [],
  handleGetDetail,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [detailsData, setDetailsData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const fetchDetailsData = async (page, pageSize) => {
    setDetailsLoading(true);
    try {
      let data = await handleGetDetail(portfolioData?.id, page, pageSize);
      const selectedItem = getAllMenuPortfolio()?.find((item)=> item?.id === portfolioData?.id);
      if (selectedItem) {
        const allProperties = selectedItem?.portfolioSections?.flatMap(section => 
          section?.properties ?? []
        ) ?? [];
        const keyName = allProperties?.length && allProperties?.find((property)=>property?.name === 'displayname')?.displayName || "";
        if (data?.length > 0 && keyName) {
           data = data?.map(item => {
            const { displayname, ...rest } = item;
            const newItem = { [`${keyName || selectedItem?.name}s`]: displayname, ...rest }
            return newItem; 
          });
        }
      }
      
      setDetailsData(data);
    } catch (error) {
      console.error("Error fetching details data:", error);
    } finally {
      setDetailsLoading(false);
    }
  };

  useEffect(() => {
    if (showDetails) {
      fetchDetailsData(pagination.current, pagination.pageSize);
    }
  }, [showDetails, pagination.current, pagination.pageSize]);

  const handlePageChange = (newPagination, filters, sorter) => {
    const { current, pageSize } = newPagination;
    if (current !== pagination.current || pageSize !== pagination.pageSize) {
      setPagination((prev) => ({
        ...prev,
        current: current,
        total: Number(portfolioData?.totalRecords) || 0,
        pageSize,
      }));
    }
  };
  const checkBoolean = (obj) =>
    Object.values(obj).some((value) => (Array.isArray(value) ? true : false));

  return (
    <React.Fragment>
      <Flex justify="space-between" align="flex-end" className="w-full">
        <Flex
          justify="space-start"
          className="gap-2 w-full"
        >
          <div className={`gap-4 ${data?.length > 2 ? "w-1/2" : "w-1/4"} overflow-x`}>
            {(data?.length > 0 && (
              <DCBarCharts portfolioData={portfolioData} data={data} width={50}/>
            )) || (
              <div className="no-results-message">No Properties found!</div>
            )}
          </div>
          <div className="gap-4 w-1/2 overflow-x">
            {(dataRelations?.length > 0 && (
              <DCBarCharts
                portfolioData={portfolioData}
                data={dataRelations || []}
                width={50}
              />
            )) || <div className="no-results-message">No relations found!</div>}
          </div>
        </Flex>
        <div>
          <Button
            type="link"
            onClick={() => {
              setPagination((prev) => ({
                ...prev,
                current: 1,
                pageSize: 10,
                total: Number(portfolioData?.totalRecords) || 0,
              }));
              setShowDetails(!showDetails);
            }}
          >
            View Details
          </Button>
        </div>
      </Flex>
      {showDetails && (
        <div justify="space-between" align="flex-end" className="w-full">
          <CompletenessDataTable
            data={detailsData}
            width="95%"
            height={440}
            style={{ margin: "20px" }}
            paginationOptions={{
              ...pagination,
              showSizeChanger: false,
              pageSizeOptions: ["5", "10", "20", "50", "100", "200"], // Options for page size
            }}
            columnHeight={60} // Custom column height
            columnWidth={200} // Custom column width
            position="left" // Position for the first column
            rowHeight={20}
            rowKey="displayname"
            size="small"
            loading={detailsLoading}
            onChange={handlePageChange}
          />
        </div>
      )}
    </React.Fragment>
  );
};

DCViewDetails.defaultProps = {};

export default DCViewDetails;

const randomIntFromInterval = (min, max) => {
  // min and max included
  return parseInt(Math.floor(Math.random() * (max - min + 1) + min));
};
