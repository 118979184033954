import { encodeQueryData } from "../../utils/Common";
import http from './httpService';
const API_BASE_URL = process.env.REACT_APP_API_URL

// filterOrder
async function getList(filterBy, filter_ByText, filter_levels,filterOrder) {
    const url = `${API_BASE_URL}apqc/filter`;
    let filter;

    if (filterOrder === "Level,ASC"){
        filter = [["Level","ASC"]]
    }else if (filterOrder === "Level,DESC"){
        filter = [["Level","DESC"]]
    }else if (filterOrder === "name,ASC"){
        filter = [["DisplayName","ASC"]]
    }else if (filterOrder === "name,DESC"){
        filter = [["DisplayName","DESC"]]
    }else {
        console.log("wrong input");
    }

    const requestData = {
        [filterBy]: filter_ByText,
        "level": filter_levels,
        "sortBy":filter
    };

    const response = await http.post(url, requestData);
    return response;
}


async function create(data) {
    const url = `${API_BASE_URL}apqc`;
    let response = await http.post(url, data);
    return response;
}

async function drag_update(data) {
    const url = `${API_BASE_URL}apqc/drag-update`;
    let response = await http.post(url, data);
    return response;
}

async function update(data) {
    const url = `${API_BASE_URL}apqc`;
    let response = await http.put(url, data);
    return response;
}

async function deletebusinessProcess(id) {
    const url = `${API_BASE_URL}apqc/`;
    let response = await http.delete(url + id);
    return response;
}

async function getListByID(id) {
    const url = `${API_BASE_URL}apqc/`;
    let response = await http.get(url + id);
    return response;
}

const ApqcProcessServices = {
    getList,
    create,
    update,
    deletebusinessProcess,
    getListByID,
    drag_update
}


export default ApqcProcessServices;
