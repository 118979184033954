import "./BusinessCapabilityEntry.scss";
import { Divider, Tabs, Typography } from "antd";
import TabBCBasicInformation from "./BCETabs/TabBCBasicInformation/TabBCBasicInformation";
import TabComments from "./BCETabs/TabBCComments/TabComments";
import TabConnections from "./BCETabs/TabBCConnections/TabConnections";
import React, { useState } from "react";
import TabLinks from "../portfoliosingleentry/portfolioTabs/tablinks/TabLinks";
import TabChangeLogAlerts from "../portfoliosingleentry/portfolioTabs/tabchangelogalerts/TabChangeLogAlerts";
import { useLocation, useParams } from "react-router";
import { applicationsList } from "../../utils/businessApplication";
import { useTabContext } from "../portfoliosingleentry/TabProvider";
import TabPane from "antd/es/tabs/TabPane";
import { Link } from "react-router-dom";
import TabDiagrams from "../portfoliosingleentry/portfolioTabs/tabdiagrams/TabDiagrams";
import { PageTitleHeading } from "../../utils/Common";
import { useMenu } from "../../Context/MenuProvider";
import { useEffect } from "react";
import { GoBack } from "../../utils/GoBackHistory";


const BusinessCapabilityEntry = (props) => {
  const location = useLocation();

  // if(!location?.state){
  //     location.state = JSON.parse(sessionStorage.getItem('editData-BC')) || null;
  // }else{
  //     sessionStorage.setItem('editData-BC',JSON.stringify(location?.state))
  // }

  const { stateMenu, dispatchMenu } = useMenu();

  //let moduleName=applicationsList.businessCapability;
  // let moduleName=stateMenu?.MenuID?.moduleName;
  
  const [activeTabKey, setActiveTabKey] = useState("tab_information");
  const [businessCapabilityData, setBusinessCapabilityData] = useState({});
  const [portfolioId, setPortfolioId] = useState(null);
  // const { tabDisabled, toggleTabDisabled } = useTabContext();
  const { id = null, moduleName, scenarioId } = useParams();

  const handleTabChange = (key) => {
    console.log("key", key);
    setActiveTabKey(key);
  };

 
  
  useEffect(() => {
    if (location?.state?.activeTabKey) {
      handleTabChange(location?.state?.activeTabKey);
    }
    if (id?.toLowerCase() === "add") {
      setPortfolioId(null);
    } else {
      setPortfolioId(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const tabsProps = {
    itemData: businessCapabilityData,
    setActiveTabKey,
    handleTabChange,
    // toggleTabDisabled,
    // tabDisabled,
    id: portfolioId,
    moduleName,
  };

  const BusinessCapabilityTabs = [
    {
      label: `Information`,
      key: "tab_information",
      disabled: portfolioId ? false : true,
      children: (
        <TabBCBasicInformation
          {...tabsProps}
          setBusinessCapabilityData={setBusinessCapabilityData}
          defaultFormData={{
            sourceid: 0,
          }}
        />
      ),
    },
    {
      label: `Relations`,
      key: "tab_connections",
      disabled: portfolioId ? false : true,
      children: <TabConnections {...tabsProps} />,
    },
    {
      label: `External Links`,
      key: "tab_links",
      disabled: portfolioId ? false : true,
      // children: <TabBCExternalLinks/>,
      children: <TabLinks {...tabsProps} />,
    },
    {
      label: `Diagram`,
      key: "tab_diagram",
      disabled: portfolioId ? false : true,
      children: (
        <TabDiagrams
          {...tabsProps}
          titleName={businessCapabilityData?.DisplayName}
        />
      ),
    },
    {
      label: `Comments`,
      key: "tab_comments",
      disabled: portfolioId ? false : true,
      children: <TabComments {...tabsProps} />,
    },
    // {
    //     label: `Survey`,
    //     key: 'tab_survey',
    //     children: <TabBCBasicInformation {...tabsProps}/>,
    // }
    // ,
    {
      label: `Change log/ Alerts`,
      key: "tab_changelogAlert",
      disabled: portfolioId ? false : true,
      children: <TabChangeLogAlerts {...tabsProps} />,
    },
  ];

  return (
    <div className=" mb-5 text-left">
      <div className="d-flex justify-content-between">
        <div>
          <PageTitleHeading
            text={(() => {
              if (businessCapabilityData?.displayname) {
                return (
                  <span>
                    Business Capability: {" "}{businessCapabilityData?.displayname}
                  </span>
                );
              }
              return <span>Add New Business Capability</span>;
            })()}
          />
        </div>
        <div className="my-3">
          <GoBack
            className={`ml-2 pull-right`}
            path={location?.state?.from}
            state={location?.state}
            back
          />
        </div>
      </div>
      <Tabs
        type="card"
        className="business-capability-entry"
        activeKey={activeTabKey}
        onChange={handleTabChange}
        items={BusinessCapabilityTabs}
      />
    </div>
  );
};

export default BusinessCapabilityEntry;
