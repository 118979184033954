import http from "./httpService";
const API_BASE_URL = process.env.REACT_APP_API_URL;

async function getList(searchTerm = '') {
  const url = `${API_BASE_URL}globalsearch?q=${searchTerm}`;
  let response = await http.get(url);
  return response;
}

async function getSettings(linkId) {
  
  const url = `${API_BASE_URL}settings?linkId=`+linkId;
  let response = await http.get(url);
  return response;
}

async function setSettings(formData) {
  const url = `${API_BASE_URL}settings`;
  let response = await http.post(url,formData);
  return response;
}

async function FetchShortGuideVideo(formData) {
  const url = `${API_BASE_URL}settings/fetch-shortguide-video`;
  let response = await http.post(url,formData);
  return response;
}

async function DeleteShortGuideVideo(formData) {
  const url = `${API_BASE_URL}settings/delete-shortguide-video`;
  let response = await http.post(url,formData);
  return response;
}

async function UpdateAndRemoveHomeVideo(formData) {
  const url = `${API_BASE_URL}settings/update-remove-homevideo`;
  let response = await http.post(url,formData);
  return response;
}
 
async function fetchShortGuideVideoForHome(formData) {
  const url = `${API_BASE_URL}settings/fetch-shortguide-video-forhome`;
  let response = await http.post(url,formData);
  return response;
}

async function fetchUserGuideVideo(formData) {
  const url = `${API_BASE_URL}settings/fetch-user-guidevideo`;
  let response = await http.post(url,formData);
  return response;
}


async function createOrUpdateWelcomeVideo(formData) {
  const url = `${API_BASE_URL}settings/create-update-welcomevideo`;
  let response = await http.post(url,formData);
  return response;
}

async function fetchWelcomeVideo(formData) {
  const url = `${API_BASE_URL}settings/fetch-welcomevideo`;
  let response = await http.post(url,formData);
  return response;
}

async function UpdateActiveWelcomeVideo(formData) {
  const url = `${API_BASE_URL}settings/update-active-welcomevideo`;
  let response = await http.post(url,formData);
  return response;
}


async function fetchWelcomeVideoByID(formData) {
  const url = `${API_BASE_URL}settings/fetch-welcomevideo-byid`;
  let response = await http.post(url,formData);
  return response;
}


async function DeleteWelcomeVideo(formData) {
  const url = `${API_BASE_URL}settings/delete-welcomevideo`;
  let response = await http.post(url,formData);
  return response;
}



async function FetchWelcomeVideo_ForHome(formData) {
  const url = `${API_BASE_URL}settings/fetch-welcomevideo-homepage`;
  let response = await http.post(url,formData);
  return response;
}





const GlobalSearchServices = {
  getList,
  setSettings,
  getSettings,
  FetchShortGuideVideo,
  DeleteShortGuideVideo,
  UpdateAndRemoveHomeVideo,
  fetchShortGuideVideoForHome,
  fetchUserGuideVideo,
  createOrUpdateWelcomeVideo,
  fetchWelcomeVideo,
  UpdateActiveWelcomeVideo,
  fetchWelcomeVideoByID,
  DeleteWelcomeVideo,
  FetchWelcomeVideo_ForHome
};

export default GlobalSearchServices;
