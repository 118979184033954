import React, { useEffect, useState } from 'react'
import { Pie } from '@ant-design/plots';
import HomePageServices from '../../../services/services/HomePageServices';

const LifeCycleStatusChart = ({lifeCyclechartData}) => {

  // const config = {
  //   data: [
  //     { type: 'Research', value: 27 },
  //     { type: 'Emerging', value: 25  },
  //     { type: 'Terminated', value: 18 },
  //     { type: 'Declining', value: 15 },
  //     { type: 'Core', value: 10 },
  //   ],
  //   angleField: 'value',
  //   colorField: 'type',
  //   label: {
  //     // text: 'type',
  //     // style: {
  //     //     fontSize: 12,
  //     //     fontWeight: 500,
  //     //     transform: 'rotate(40)',
  //     // },
  //     // transform: [
  //     //   {
  //     //     type: 'overlapHide',
  //     //   },
  //     // ],
  //     // position: 'spider',
  //     // position: 'outside',
  //     text: (d) => `${d.type}: ${d.value}`,
  //     position: 'outside',
  //   },
  //   radius: 0.5,
  //   // tooltip: {
  //   //     title: 'type',
  //   // },
  //   // interaction: {
  //   //     elementHighlight: true,
  //   // },
  //   // state: {
  //   //     inactive: { opacity: 0.5 },
  //   // },
  //   legend: false,
  //   tooltip: false,
  //   interaction: {
  //     elementSelect: true,
  //   },
  // };

  const config = {
    data: [
    ],
    angleField: 'value',
    colorField: 'type',
    label: {
      // text: 'type',
      // style: {
      //     fontSize: 12,
      //     fontWeight: 500,
      //     transform: 'rotate(40)',
      // },
      // transform: [
      //   {
      //     type: 'overlapHide',
      //   },
      // ],
      // position: 'spider',
      // position: 'outside',
      text: (d) => `${d.type}: ${d.value}`,
      // position: 'outside',
      position: 'spider',
    },
    radius: 0.5,
    // tooltip: {
    //     title: 'type',
    // },
    // interaction: {
    //     elementHighlight: true,
    // },
    // state: {
    //     inactive: { opacity: 0.5 },
    // },
    legend: false,
    tooltip: false,
    interaction: {
      elementSelect: true,
    },
  };

  const [chartData, setChartData] = useState();

  useEffect(() => {
    if (lifeCyclechartData.length) {
      config.data.push(...lifeCyclechartData);
      setChartData(config); 
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lifeCyclechartData]);




  return <>
    {/* <Pie {...config} width={400} height={350} /> */}
    {chartData && <Pie {...chartData} width={400} height={350} />}
  </>

}

export default LifeCycleStatusChart
