import React, { useState, useEffect } from 'react';
import { Bar } from '@ant-design/plots';
import HomePageServices from '../../../services/services/HomePageServices';


const data1 = [
    { year: 'Applied', value: 38, color: '#fff280' },
    { year: 'Work In Progress', value: 52, color: '#c0b4fc' },
    { year: 'Total', value: 61, color: '#98dc9c' },
];

const ScenarioChart = ({ scenarioChartData }) => {
    const config = {
        data: [],
        xField: 'year',
        yField: 'value',
        sort: {
            reverse: true,
        },
        label: {
            // text: 'frequency',
            // formatter: '.1%',
            style: {
                // textAlign: (d) => (+d.value > 0.008 ? 'right' : 'start'),
                // fill: (d) => (+d.value > 0.008 ? '#fff' : '#000'),
                dx: (d) => (+d.value > 0.008 ? 25 : 5),
            },
            text: 'value',
            // textBaseline: 'bottom',
            position: 'right',
            textAlign: 'center',
        },
        tooltip: false,
        style: {
            fill: ({ color }) => {
                return color;
            },
            maxWidth: 40,
        },
        state: {
            // unselected: { opacity: 0.5 },
            // selected: { lineWidth: 3, stroke: 'red' },
        },
        interaction: {
            elementSelect: true,
        },

        scale: {
            x: {
                padding: 2,
            },
        },

        legend: {
        position: 'top-right', // Position of the legend (e.g., 'top-left', 'top-right', 'bottom-left', 'bottom-right')
        layout: 'horizontal', // Layout type ('horizontal' or 'vertical')
        marker: {
            symbol: 'circle', // Legend item shape (e.g., 'circle', 'square', 'line')
        },
        itemName: {
            style: {
                fontSize: 12, // Font size for legend items
                fill: '#000',  // Color for legend item text
            },
        },
        itemValue: {
            style: {
                fontSize: 12,
                fill: '#888', // Color for legend value text
            },
        },
    },
    };
    const [chartData, setChartData] = useState();


    useEffect(() => {
        if (scenarioChartData?.length) {
            
             
            const convertedData = scenarioChartData.filter((f)=>f.year!='Total')?.map(item => ({
                year: item.year,
                color:item.color,
                value: parseInt(item.value, 10)  // Convert string to integer
            }));

            
            config.data.push(...convertedData);
            setChartData(config);
 
        }
    }, [scenarioChartData]);



    return <Bar {...chartData} height={350} />;
}

export default ScenarioChart
