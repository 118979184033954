import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Select,
  Spin,
  Typography,
  message,
  Table,
  Layout,
  Checkbox,
} from "antd";
import Delayed from "../../../utils/Delayed";
import PortfolioSettingServices from "../../../services/services/PortfolioSettingsService";
import TextWithTooltip from "../../../utils/TextWithTooltip";
import { PortfolioItemDetails } from "../../portfolio/common/PortfolioCommon";
import ScenarioServices from "../../../services/services/ScenarioServices";

function ScenarioPortfolioItemImport({
  scenarioId,
  heading,
  listsScenarioPortfolioItem,
  setselectedItemIDRows,
  setScenarioPortfolioItemModal,
  portfolioItemLoading: loading,
  setPortfolioItemLoading,
  moduleName,
  freeText,
  onCheckboxChange,
  selectedRows,
  scenarioPortfolioItem,
  ...props
}) {
  const [lists, setLists] = useState([]);
  const [scenarioPortfolioIds, setScenarioPortfolioIds] = useState([]);
  const [importPortfolioItemsID, setImportPortfolioItemsID] = useState([]);

  useEffect(() => {
    setLists(listsScenarioPortfolioItem);
  }, [listsScenarioPortfolioItem]);

  // const onCheckboxChange = (e) => {
  //   let { value, checked } = e?.target;
  //   let ary = [...scenarioPortfolioIds];
  //   if (checked) {
  //     ary.push(value);
  //   } else {
  //     ary = ary.filter((f) => f?.toString() !== value?.toString());
  //   }
  //   setScenarioPortfolioIds(ary);
  // };

  useEffect(() => {
    setImportPortfolioItemsID(scenarioPortfolioIds);
  }, [scenarioPortfolioIds]);

  const getCardDescription = (item) => {
    let obj = { ...item };
    //obj.BadgeData = item.ReferenceID;
    obj.BadgeData = item.referenceid;
    //console.log(cardModuleName,"====cardModuleNamecardModuleName")  

    return { ...obj };
  };

  const getCard = (item) => {
    const sss = scenarioPortfolioItem.includes(item?.id?.toString());

    const { BadgeData, displayname, description } = getCardDescription(item);
    return (
      <div key={item?.id} className="w-100">
        <table width={`100%`} className="">
          <tbody>
            <tr>
              <td>
                <Checkbox
                  value={item?.id}
                  onChange={onCheckboxChange}
                  checked={selectedRows.includes(item?.id?.toString())}
                  disabled={scenarioPortfolioItem.includes(
                    item?.id?.toString()
                  )}
                />
              </td>
              <td width={`95%`}>
                <PortfolioItemDetails
                  moduleName={moduleName}
                  item={item}
                  // basePath={basePath}
                  defaultActiveKey={1}
                  badgeValue={BadgeData}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <hr className="my-2" />
      </div>
    );
  };

  return (
    <>
      <div>
        {lists?.length === 0 && (
          <Spin
            size="small"
            spinning={
              freeText === "Data Not Found" || freeText === "No items found."
                ? false
                : true
            }
          >
            <div style={{ fontSize: 16, fontWeight: 600 }}>{freeText}</div>
          </Spin>
        )}
        
        {lists?.map((element, i) => {
          let children = [element];

          element?.child?.forEach((element2) => {
            children.push(element2);
            element2?.child?.forEach((element3) => {
              children.push(element3);
              element3?.child?.forEach((element4) => {
                children.push(element4);
              });
            });
          });

          //   if (props.filterText.length > 0) {
          //     if (props.filterBy === "Name") {
          //       children = children.filter((item) =>
          //         item?.DisplayName.includes(props.filterText)
          //       );
          //     } else if (props.filterBy === "Level") {
          //       children = children.filter((item) =>
          //         item?.Level.includes(props.filterText)
          //       );
          //     } else if (props.filterBy === "Description") {
          //       children = children.filter((item) =>
          //         item?.DisplayName.includes(props.filterText)
          //       );
          //     }
          //   }

          return children?.map((item) => {
            return getCard(item);
          });
        })}
      </div>
    </>
  );
}

export default ScenarioPortfolioItemImport;
