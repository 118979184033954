import './BusinessProcessEntry.scss';
import { Divider, Tabs,Typography } from "antd";   
import TabBPBasicInformation from './BPETabs/TabBPBasicInformation/TabBPBasicInformation';
import TabComments from "./BPETabs/TabBPComments/TabComments";
// import TabConnections from "./BPETabs/TabBPConnections/TabConnections";
import TabConnections from "./../businessCapabilityEntry/BCETabs/TabBCConnections/TabConnections"
import React, { useState } from "react";
import TabLinks from '../portfoliosingleentry/portfolioTabs/tablinks/TabLinks';
import TabChangeLogAlerts from "../portfoliosingleentry/portfolioTabs/tabchangelogalerts/TabChangeLogAlerts";
import { useLocation, useParams } from "react-router";
import TabPane from "antd/es/tabs/TabPane";
import { Link } from "react-router-dom";
import TabDiagrams from '../portfoliosingleentry/portfolioTabs/tabdiagrams/TabDiagrams';
import { PageTitleHeading } from '../../utils/Common';
import {useMenu} from "../../Context/MenuProvider";
import { useEffect } from 'react';
import { GoBack } from '../../utils/GoBackHistory';

const BusinessProcessEntry = (props) => {
    let location = useLocation();
    if(!location?.state){
        location.state = JSON.parse(sessionStorage.getItem('editData-BP')) || null;
    }else{
        sessionStorage.setItem('editData-BP',JSON.stringify(location?.state))
    }
    const { stateMenu, dispatchMenu } = useMenu();

    //let moduleName=applicationsList.businessProcess;
   // let moduleName=stateMenu?.MenuID?.moduleName;
     
    const [activeTabKey, setActiveTabKey] = useState("tab_information");
    const [businessProcessData, setBusinessProcessData] = useState({});
    // const { tabDisabled, toggleTabDisabled } = useTabContext();
    const { id=null, moduleName } = useParams();
    const [portfolioId,setPortfolioId] = useState(null);

    const handleTabChange = (key) => {
      setActiveTabKey(key);
    };

    useEffect(()=>{
        if(location?.state?.activeTabKey){
            handleTabChange(location?.state?.activeTabKey);
        }
        if(id?.toLowerCase()==="add"){
            setPortfolioId(null);
        }else{
            setPortfolioId(id);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[id])

    
    const tabsProps = {
        itemData:businessProcessData,
        setActiveTabKey,
        handleTabChange, 
        // toggleTabDisabled,
        // tabDisabled,
        id:(id?.toLowerCase()==="add")?null:id,
        moduleName:moduleName,
    };
    const tabsProps2 = {
        itemData:businessProcessData,
        setActiveTabKey,
        handleTabChange, 
        // toggleTabDisabled,
        // tabDisabled,
        id:(id?.toLowerCase()==="add")?null:id,
        moduleName:"BusinessProcess",
    };

    const BusinessProcessTabs = [
        {
            label: `Information`,
            key: 'tab_information',
            children: <TabBPBasicInformation {...tabsProps} setBusinessProcessData ={setBusinessProcessData } defaultFormData={{sourceid:0,parentid:location?.state?.ParentID}} locationData = {location?.state}/>,
        }  
        ,
        {
            label: `Relations`,
            key: 'tab_connections',
            disabled:portfolioId?false:true,
            children: <TabConnections {...tabsProps}/>,
        }
        ,
        {
            label: `External Links`,
            key: 'tab_links',
            disabled:portfolioId?false:true,
            children: <TabLinks {...tabsProps2}/> ,
        }
        ,
        {
            label: `Diagram`,
            key: 'tab_diagram',
            disabled:portfolioId?false:true,
            children: <TabDiagrams {...tabsProps2} titleName = {businessProcessData?.DisplayName}/>,
        }
        ,
        {
            label: `Comments`,
            key: 'tab_comments',
            disabled:portfolioId?false:true,
            children: <TabComments {...tabsProps}/>,
        },
        {
            label: `Change log/ Alerts`,
            key: 'tab_changelogAlert',
            disabled:portfolioId?false:true,
            children: <TabChangeLogAlerts {...tabsProps}/>,
        }
    ];

    return (
        <div className=' mb-5 text-left'>
            <div className='d-flex justify-content-between'>
                <div>
                    <PageTitleHeading text={
                            ((()=>{
                                if(businessProcessData?.displayname){
                                    return <span>Business Process: {businessProcessData?.displayname}</span>;
                                }
                                return <span>Add New Business Process</span>;
                            })())
                        }
                    />
                </div>
                <div className='my-3'>
                    <GoBack className={`ml-2 pull-right`} path={location?.state?.from} state={location?.state} back/>
                </div>
            </div>
            <Tabs
                type="card"
                className="business-capability-entry"
                activeKey={activeTabKey}
                 onChange={handleTabChange}
                items={BusinessProcessTabs}
            />
        </div>
    );
}

export default BusinessProcessEntry;