import React, { useEffect, useState } from "react";
//import { GanttOriginal, Task, ViewMode } from "react-gantt-chart";
import { Gantt, Task, ViewMode } from "gantt-task-react-awa";

import "gantt-task-react-awa/dist/index.css";


function CompareChart({ events }) {
 
  const data = [
    {
      type: "project",
      id: 332,
      name: "Sprint Demo 15",
      start: new Date("2024-10-02"),
      end: new Date("2025-05-08"),
      progress: 100,
      hideChildren: false
    },
    {
      type: "task",
      id: 4,
      name: "Application",
      start: new Date("2024-10-17"),
      end: new Date("2024-12-07"),
      color: "#FFB381",
      count_items: 2,
      progress: 100,
      project: 332
    },
    {
      type: "task",
      id: 18,
      name: "APQC Process",
      start: new Date("2024-12-09"),
      end: new Date("2024-12-09"),
      color: "#D4E2C6",
      count_items: 1,
      progress: 100,
      project: 332
    },
    {
      type: "task",
      id: 1,
      name: "Business Capability",
      start: new Date("2024-10-02"),
      end: new Date("2024-10-10"),
      color: "#B2E5E5",
      count_items: 2,
      progress: 100,
      project: 332
    },
    {
      type: "task",
      id: 5,
      name: "Business Process",
      start: new Date("2025-05-08"),
      end: new Date("2025-05-08"),
      color: "#FFF280",
      count_items: 1,
      progress: 100,
      project: 332
    },
    {
      type: "project",
      id: 346,
      name: "Test  1809 Awa",
      start: new Date("2024-09-01"),
      end: new Date("2025-02-07"),
      progress: 100,
      "hideChildren": false
    },
    {
      type: "task",
      id: 4,
      name: "Application",
      start: new Date("2024-09-01"),
      end: new Date("2025-02-07"),
      color: "#FFB381",
      count_items: 4,
      progress: 100,
      project: 346
    },
    {
      type: "task",
      id: 18,
      name: "APQC Process",
      start: new Date("2024-10-08"),
      end: new Date("2024-11-09"),
      color: "#D4E2C6",
      count_items: 2,
      progress: 100,
      project: 346
    },
    {
      type: "task",
      id: 5,
      name: "Business Process",
      start: new Date("2024-10-02"),
      end: new Date("2024-12-07"),
      color: "#FFF280",
      count_items: 2,
      progress: 100,
      project: 346
    },
    {
      type: "task",
      id: 11,
      name: "Initiative",
      start: new Date("2024-09-25"),
      end: new Date("2024-11-09"),
      color: "#D4E2D4",
      count_items: 6,
      progress: 100,
      project: 346
    },
    {
      type: "task",
      id: 12,
      name: "Organization",
      start: new Date("2024-10-10"),
      end: new Date("2024-11-11"),
      color: "#C4DFDF",
      count_items: 3,
      progress: 100,
      project: 346
    }
  ]

  const [taskList, setTaskList] = useState([]);

  //   {
  // 	type: "project",
  // 	id: "0",
  // 	name: "",
  // 	start: new Date('2021-06-01'),
  // 	end: new Date(2021, 9, 30),
  // 	progress: 100,
  // 	hideChildren: false,
  // }


  useEffect(() => {
    if (events) {
      setTaskList(events);
    }
  }, [events]);

  //   const events = [
  //     { name: "event planning", startTime: "2024-04-01", endTime: "2024-12-04" },
  //     {
  //       name: "layout logistics",
  //       startTime: "2024-09-03",
  //       endTime: "2024-11-13",
  //     },
  //     { name: "select vendors", startTime: "2024-10-05", endTime: "2025-03-01" },
  //     { name: "hire venue", startTime: "2024-09-09", endTime: "2024-12-01" },
  //     { name: "hire caterer", startTime: "2024-10-01", endTime: "2025-09-14" },
  //     {
  //       name: "hire event decorators",
  //       startTime: "2024-11-12",
  //       endTime: "2025-10-17",
  //     },
  //     { name: "rehearsal", startTime: "2024-09-14", endTime: "2026-09-16" },
  //     {
  //       name: "event celebration",
  //       startTime: "2024-12-17",
  //       endTime: "2025-01-01",
  //     },
  //   ];

  const handleDblClick = (task) => {
    //console.log("On Double Click event Id:" + task.id);
  };

  const handleExpanderClick = (task) => {
    //console.log("On expander click Id:", task, task.id);
    setTaskList(() => taskList.map((t) => (t.id === task.id ? task : t)));
  };

  return (

    <div className="Wrapper">
      {taskList && taskList.length &&

        // <GanttOriginal
        //   tasks={taskList}
        //   viewMode={ViewMode.Month}
        //   columnWidth={70}
        //   rowHeight={50}
        //   ganttHeight={500}
        //   listCellWidth={true ? "100px" : ""}
        //   onDoubleClick={handleDblClick}
        //   onExpanderClick={handleExpanderClick}
        //   //projectBackgroundColor={'#fff187'}
        //   //arrowColor={'#000fff'}
        //   //TooltipContent= {MyToolTipComponent}
        //   locale={"en-IN"}
        //   rtl={false}
        // />

        <Gantt
          tasks={taskList}
          viewMode={ViewMode.Month}
          columnWidth={70}
          rowHeight={30}
          //ganttHeight={500}
          listCellWidth={true ? "300px" : ""}
          onDoubleClick={handleDblClick}
          onExpanderClick={handleExpanderClick}
        // onDateChange={handleTaskChange}
        // onDelete={handleTaskDelete}
        // onProgressChange={handleProgressChange}
        // onClick={handleClick}
        // onSelect={handleSelect}

        projectBackgroundColor={'#59a985'}
        //arrowColor={'#000fff'}
        //TooltipContent= {MyToolTipComponent}
        //locale={"en-IN"}
        // rtl={false}
        />

      }

    </div>
  );
}

const MyToolTipComponent = () => (
  <div
    style={{
      background: "#fff",
      padding: "0px",
      boxShadow:
        "0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)",
    }}
  >
    {/* DATES RANGE */}
    {/* <b>{`${task.name}`} </b> */}


    {/* DURATION */}

    {/* PROGRESS */}
    <p
      style={{
        fontSize: "12px",
        marginBottom: "6px",
        color: "#666",
      }}
    >
    </p>
  </div>
);


export default CompareChart;
