import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, message, Row, Spin, Upload, Form, Select } from "antd";
import GlobalSearchServices from "../../services/services/GlobalSearchServices";
import Learnig from "./../../assets/learning.jpeg";
import { settings } from "../../utils/settingCommon";
import Delayed from "../../utils/Delayed";
import FormFields from "../../utils/FormFields";

const ShortVideoGuide = ({
  fetchShortGuideVideo,
  initialValue,
  setInitialValue,
  modelHeaderText,
}) => {
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [shortVideo, setShortVideo] = useState(null);
  const [VideoForm] = Form.useForm();

  useEffect(() => {
    // getShortVideoUpload();

    if (initialValue?.linkUrl) {
      setShortVideo(initialValue?.linkUrl);
    }
  }, []);

  const handleUpload = async () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("image", file);
    });
    setUploading(true);

    try {
      let res = [];
      if (fileList.length > 0) {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}etl/uploadMedia`,
          {
            method: "POST",
            body: formData,
          }
        );
        res = await response.json();
      }

      if (res?.blobUrls?.length > 0) {
        let obj = {
          linkUrl: res?.blobUrls[0],
          linkId: settings?.home_page || "",
        };
        setShortVideo(null);
        let response = await GlobalSearchServices?.setSettings(obj);
        if (response?.data?.linkUrl) {
          setShortVideo(response?.data?.linkUrl);
        }
        message.success("upload successfully.");
      } else {
        message.error("upload failed.");
      }
    } catch (error) {
      message.error("upload failed.");
    } finally {
      setUploading(false);
    }
  };

  const onFormSubmit = async (data) => {
    const userid = localStorage.getItem("email");
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("image", file);
    });
    setUploading(true);

    try {
      let res = [];
      let obj ={};

      if (modelHeaderText === "Add") {
        if (fileList.length <= 0)
        {
          message.error("Select Video file");
          return;
        }
      }

      if (fileList.length > 0) {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}etl/uploadMedia`,
          {
            method: "POST",
            body: formData,
          }
        );
        res = await response.json();
      }

      obj = {
        id: initialValue.id || 0,
        linkId: settings?.home_page || "", 
        caption: data.caption,
        description: data.description,
        uploadedby: userid || "",
        tags: data.tags,
      };

      if (modelHeaderText === "Add") {
        if (res?.blobUrls?.length <= 0) {
          message.error("Video upload failed.");  
          return ;
        }else  
        {
          //Add Mode 
          obj.linkUrl=res?.blobUrls[0];
        }
      }
      else 
      {
        //Edit Mode
        obj.linkUrl =initialValue?.linkUrl; 
      } 

      setShortVideo(null);
      let response = await GlobalSearchServices?.setSettings(obj);
      if (response?.data?.linkUrl) {
        setShortVideo(response?.data?.linkUrl);
      }

      await getShortVideoUpload();
      fetchShortGuideVideo();
      message.success("upload successfully.");
    } catch (error) {
      message.error("upload failed.");
    } finally {
      setUploading(false);
    }
  };

  const uploadProps = {
    multiple: false,
    accept: "video/*",
    maxCount: 1,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      // setFileList([...fileList, file]);
      setFileList([file]);
      return false;
    },
    fileList,
  };

  const getShortVideoUpload = async () => {
    try {
      setUploading(true);
      let response = await GlobalSearchServices?.getSettings(
        //settings?.home_page || ""
        initialValue.id || 0
      );
      if (response?.data?.linkUrl) {
        setShortVideo(response?.data?.linkUrl);
      }
    } catch (error) {
      console.error("error:", error);
    } finally {
      setUploading(false);
    }
  };

  return (
    // <Spin spinning={uploading}>

    <Row>
      <Col span={12}>
        <Form
          form={VideoForm}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          className="mt-4"
          onFinish={async (formData) => {
            onFormSubmit(formData);
          }}
          initialValues={initialValue}
        >
          <Row>
            <Col span={24}>
              <FormFields
                type={`input`}
                name={`caption`}
                label={`Title`}
                formClassName={`mb-2`}
                rules={[{ required: true, message: "Please enter caption!" }]}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormFields
                rows={4}
                type={`textarea`}
                name={`description`}
                label={`Description`}
                formClassName={`mb-2`}
                rules={[
                  { required: false, message: "Please enter description!" },
                ]}
              />
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                label={`Tags`}
                name={`tags`}
                // className={formClassName}
                // rules={rules}
              >
                <Select
                  name={"selectTags"}
                  mode="tags"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please add tags"
                  // value={collaborateList}
                  // onChange={handleChange}
                  // options={userDataSelectOption}
                  // filterOption={(input, option) =>
                  //   option.label.toLowerCase().includes(input.toLowerCase())
                  // }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />}>Select File</Button>
              </Upload>
              <Button
                type="primary"
                htmlType={`submit`}
                // onClick={handleUpload}
                //disabled={fileList.length === 0}
                loading={uploading}
                style={{
                  marginTop: 16,
                }}
              >
                {uploading ? "Uploading" : "Start Upload"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
      <Col span={12}>
        {(() => {
          if (shortVideo) {
            return (
              <React.Fragment>
                <div class="embed-responsive embed-responsive-21by9">
                  <video width="320" height="240" controls>
                    <source
                      src={shortVideo}
                      type="video/mp4"
                      className="embed-responsive-item mt-0 pt-0"
                    />
                    <source
                      src={shortVideo}
                      type="video/ogg"
                      className="embed-responsive-item mt-0 pt-0"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment>
                <Delayed waitBeforeShow={1000}>
                  <img src={Learnig} alt="Learning" />
                </Delayed>
              </React.Fragment>
            );
          }
        })()}
      </Col>
    </Row>

    // </Spin>
  );
};
export default ShortVideoGuide;
