const tabsList = [
  { name: "tab_information", disabled: false },
  { name: "tab_connections", disabled: true },
  { name: "tab_links", disabled: true },
  { name: "tab_diagram", disabled: true },
  { name: "tab_comments", disabled: true },
  { name: "tab_survey", disabled: true },
  //{ name: "tab_logalerts", disabled: true },
  { name: "tab_changelogAlert", disabled: true },
];
// const normalizeString = (str) => {
//   return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
// };

const normalizeString = (str) => {
  return str?.split(" ")
            .map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase())
            .join(" ");
};
export { tabsList, normalizeString };
