import React,{useEffect, useState} from 'react'
import { PageTitleHeading } from '../../../utils/Common'
import { NotificationListItem } from '../../home/home-v2/HomeNotifications';
import { Link } from 'react-router-dom';
import { Flex } from 'antd';
import {useNotificationProvider} from '../../../Context/NotificationProvider';


const data = [
    {
        shortname: "App",
        displayname: "3D Experience",
        discription: "",
        username: "Ronald Richards",
        datetime: "30/08/2024 - 11:00AM",
    },
    {
        shortname: "App",
        displayname: "3DXCAE",
        discription: "",
        username: "Cody Fisher",
        datetime: "02/09/2024 - 10:00AM",
    },
    {
        shortname: "App",
        displayname: "KeyCloak",
        discription: "Changed checker & OPEX and 2 More Properties",
        username: "Marvin McKinney",
        datetime: "05/09/2024 - 14:00PM",
    },
];

const NotificationHeader = () => {
    const { state:notificatioDataProvider, dispatch:dispatchNotificatioData } =useNotificationProvider();
    const[notificationData, setNotificationData]=useState([]);
    const[notificationDataAll, setNotificationDataAll]=useState([]);
    
    useEffect(()=>{ 
        
        const notificationDatatemp=notificatioDataProvider?.data?.filter((f)=> f.notificationtype!=="Request");
        const notificationDataLess = notificationDatatemp.slice(0, 5);
        setNotificationDataAll(notificationDatatemp);
        setNotificationData(notificationDataLess);

    },[notificatioDataProvider]);


    return (
        <div className='home-v2 '>
            <PageTitleHeading text={"Notifications"} />
            <div className='notifications mb-2 card card-body border-0'>
                <ul>
                    {
                        notificationData?.length > 0 &&
                        notificationData?.map(item=>{
                            return <React.Fragment key={Math.random()}>
                                <li>
                                    <NotificationListItem item={item}/>
                                </li>
                            </React.Fragment>
                        })
                    }
                </ul>
                <Flex justify='flex-end' >
                    <Link to='/notifications' className='btn btn-sm btn-primary pull-right'>View All ({notificationDataAll.length})</Link>
                </Flex>
            </div>
        </div>
    )
}

export default NotificationHeader
