import { Button, Flex, Tooltip } from 'antd'
import React from 'react'
import { PageTitleHeading } from '../../utils/Common'
import { PlusOutlined, UnorderedListOutlined } from '@ant-design/icons'
import ActionsBtn from '../applicationlist/ActionsBtn'
import gridSvg from "./../../assets/Two columns layout.svg";
import { useNavigate } from 'react-router'


const IntegrationHeader = ({moduleName,setActivePage,activePage}) => {
    const navigate = useNavigate();

    return (
        <>
            <div className='container-fluid'>
                <Flex wrap gap="small" className="ml-2 headingbx" justify='space-between'>
                    <PageTitleHeading text={moduleName} />
                    <Flex wrap gap="small" className="ml-2" align="center">
                        <Button 
                            onClick={()=>{setActivePage(1)}}
                            icon={<UnorderedListOutlined />}
                            {...(activePage === 1 ? {type:"primary",ghost:true}:{block:true})}
                            style={{width:'30px'}}
                        />
                        <Button 
                            onClick={()=>{setActivePage(2)}}
                            icon={<img src={gridSvg} alt="Grid" width={20}/>}
                            {...(activePage === 2 ? {type:"primary",ghost:true}:{block:true})}
                            style={{width:'30px'}}
                        />
                        <Tooltip title="Level 1">
                            <Button 
                                className='btn-level-2' 
                                icon={<PlusOutlined />} 
                                onClick={() => { 
                                    navigate('/portfolio-integration/'+moduleName+'/add' , { state: {Level:1,ParentID: 0, ParentName: '',underLevel: ''} })
                                }}
                            />
                        </Tooltip>
                        <ActionsBtn name={moduleName}/>
                    </Flex>
                </Flex>
            </div>
        </>
    )
}
IntegrationHeader.defaultProps = {}
export default IntegrationHeader

export const IntegrationHeader2 = ({moduleName,Buttons}) => {
    const navigate = useNavigate();

    return (
        <>
            <div className='container-fluid'>
                <Flex wrap gap="small" className="ml-2 headingbx" justify='space-between'>
                    <PageTitleHeading text={'Add '+moduleName} />
                    <Flex wrap gap="small" className="ml-2" align="center">
                        <Button 
                            onClick={()=>{ 
                                navigate('/portfolio-integration/'+moduleName , { state: {} })
                            }}
                            icon={<UnorderedListOutlined />}
                            block
                            style={{width:'30px'}}
                        />
                        <Button 
                            onClick={()=>{
                                navigate('/portfolio-integration/'+moduleName , { state: {activePage:2} })
                            }}
                            icon={<img src={gridSvg} alt="Grid" width={20}/>}
                            block
                            style={{width:'30px'}}
                        />
                        {Buttons}
                    </Flex>
                </Flex>
            </div>
        </>
    )
}