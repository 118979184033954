import {
    LockOutlined,
    SearchOutlined,
    UnlockOutlined,
} from "@ant-design/icons";
import {
    Badge,
    Button,
    Checkbox,
    Form,
    Input,
    Layout,
    Modal,
    Select,
    Spin,
    Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import businessCapabilityServices from "../../../services/services/BusinessCapabilityService";
import { PageTitleHeading } from "../../../utils/Common";
import FormFields from "../../../utils/FormFields";
import FilterButtonToggle from "../../layouts/FilterButtonToggle";

const styleBar = {
    overflow: "auto",
    minHeight: window.innerHeight / 2,
};

const rotate90 = {
    marginTop: "50px",
    marginLeft: "-10px",
};

const checkBoxeItems = [
    {
        label: "1",
        value: "L1",
    },
    {
        label: "2",
        value: "L2",
    },
    {
        label: "3",
        value: "L3",
    },
];

const date = new Date();
var deadlines = [];
for (let index = date.getFullYear(); index <= date.getFullYear() + 2; index++) {
    for (let ind = 1; ind <= 4; ind++) {
        deadlines.push({
            value: "Q" + ind + "-" + index,
            label: "Q" + ind + "-" + index,
        });
    }
}

const BusinessProcessList = ({ heading }) => {
    const [changeTypeForm] = Form.useForm();
    const [changeTypeModal, setChangeTypeModal] = useState(false);
    const [showFilter, setshowFilter] = useState(false);
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [filterBy, setFilterBy] = useState("");
    const [filterText, setFilterText] = useState("");
    const [filterLevels, setfilterLevels] = useState("");
    const [filterOrder, setfilterOrder] = useState("label,ASC");
  
    useEffect(() => {
        getBusinessCapabilityList(filterBy,filterText,filterLevels,filterOrder,);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterBy,filterText, filterLevels, filterOrder]);

    const getBusinessCapabilityList = async (filterBy,filterText,filterLevels,filterOrder,) => {
        setLoading(true);
        const list = await businessCapabilityServices.getList( filterBy,filterText,filterLevels,filterOrder,);
        const resultData = list?.data?.data;
        setList(resultData);
        setLoading(false);
    };

    const getBPLContent = ({DisplayName, Description}) => {
        return (
            <div key={Math.random()}>
                <table width={`100%`} className="my-1" >
                    <tbody>
                        <tr>
                            <td width={`95%`}>
                                <div className="my-1">
                                    <div>
                                        <Badge style={{background:"#00000040",color:"#000",minWidth:"25px",borderRadius:"8px"}} className="fw-small" overflowCount={9999999999}/>
                                        <span className="fw-medium text-primary px-3">{DisplayName}</span>
                                    </div>
                                </div>
                                <div className="whitespace-normal overflow-hidden fs-small">{Description}</div>
                            </td>
                            <td width={`5%`} className="text-nowrap">
                                <Button type={`primary`} onClick={() => { setChangeTypeModal(true); }} >Add </Button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr />
            </div>
        ); 
    }

    return (
        <div>
            <Layout className="applicationlist-page">
                <Layout.Content className="p-2 rounded left_pnlbx">
                    <div className="d-flex justify-content-between">
                        <PageTitleHeading text={heading + " List"} className={`my-0`}/>
                        <div className="ml-auto">
                            <Input
                                placeholder="Search"
                                prefix={<SearchOutlined />}
                            />
                        </div>
                    </div>
                    <Spin className="loading_bx" size="small" spinning={loading}>
                        <div className="overflow-y listview_bx" style={{ height: window.innerHeight / 1.2 }}>
                            {
                                ((() => {
                                    let records = [];
                                    
                                    if(list){
                                        list?.forEach(level_1=>{
                                            records.push(level_1);
                                            level_1?.child?.forEach(level_2=>{
                                                records.push(level_2);
                                                level_2?.child?.forEach(level_3=>{
                                                    records.push(level_3);
                                                })
                                            })
                                        })
                                    }

                                    return records
                                    
                                })())
                            }
                        </div>
                    </Spin>
                </Layout.Content>
                <Layout.Sider
                    theme={`light`}
                    width={showFilter ? `250px` : `50px`}
                    className="p-3 pt-0 left_pnlbx"
                    // style={styleBar}
                >
                    <FilterButtonToggle setShowFilter={setshowFilter} showFilter={showFilter}/>
                    <div className={showFilter ? `` : `d-none`}>
                        <div className="d-flex justify-content-between mb-2">
                            <div className="w-50">
                                <label>Sort By</label>
                            </div>
                            <div className="w-100">
                                <Select
                                    style={{ width: "100%" }}
                                    value={filterOrder}
                                    onChange={(selectedOption) =>
                                        setfilterOrder(selectedOption)
                                    }
                                    options={[
                                        {
                                            value: "Level,ASC",
                                            label: "Sort By Level ASC",
                                        },
                                        {
                                            value: "Level,DESC",
                                            label: "Sort By Level DESC",
                                        },
                                        {
                                            value: "name,ASC",
                                            label: "Sort By Name ASC",
                                        },
                                        {
                                            value: "name,DESC",
                                            label: "Sort By Name DESC",
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                        <Select
                            defaultValue="displayName"
                            style={{ width: "100%", }}
                            onChange={(value) => { setFilterBy(value); }}
                            options={[
                                {
                                    options: [
                                        {
                                            label: <span>Name</span>,
                                            value: "displayName",
                                        },
                                        {
                                            label: <span>Description</span>,
                                            value: "description",
                                        },
                                    ],
                                },
                            ]}
                        />
                        <Input
                            className="mt-2"
                            onChange={(e) => { setFilterText(e?.target?.value); }}
                            placeholder="Search "
                            allowClear
                        />
                        <Typography.Title level={5} className="text-start mt-2"> Levels </Typography.Title>
                        <Checkbox.Group
                            className="mt-2"
                            style={{ width: "100%", display: "block" }}
                            onChange={(value) => {
                                setfilterLevels(value);
                            }}
                        >
                            {checkBoxeItems?.map((item) => {
                                return (
                                    <div key={Math.random()}>
                                        <Checkbox value={item?.value}>
                                            {item?.label}
                                        </Checkbox>
                                    </div>
                                );
                            })}
                        </Checkbox.Group>
                        <hr style={{ borderTop: "2px dashed" }} />
                    </div>
                </Layout.Sider>
            </Layout>
            <Modal
                destroyOnClose
                header={false}
                footer={false}
                open={changeTypeModal}
                onCancel={() => {
                    setChangeTypeModal(false);
                }}
            >
                <Form
                    form={changeTypeForm}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{}}
                    className="mt-4"
                    onFinish={(formData) => {
                        console.log("formData", formData);
                    }}
                >
                    <FormFields
                        type={`select`}
                        name={`changeType`}
                        label={`Change Type`}
                        formClassName={`mb-2`}
                        options={[
                            { label: "Select", value: "" },
                            { label: "Modify", value: "modify" },
                        ]}
                    />
                    <FormFields
                        type={`textarea`}
                        label={`Comment`}
                        formClassName={`mb-2`}
                        rows={3}
                    />
                    <FormFields
                        type={`select`}
                        label={`Deadline`}
                        name="deadline"
                        formClassName={`mb-2`}
                        options={deadlines}
                    />
                    <Button type={`primary`} htmlType={`submit`} className="w-100"> Next </Button>
                </Form>
            </Modal>
        </div>
    );
};

BusinessProcessList.propTypes = {};

export default BusinessProcessList;
