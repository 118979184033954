import {
  Layout,
  Modal,
  Pagination,
  Spin,
  message,
  Checkbox,
  Flex,
  Button,
  Form,
  Input,
  Card,
} from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router";
import LoadingBar from "react-top-loading-bar";
import { ExclamationCircleFilled } from "@ant-design/icons";
import usePortfolioFetcher from "../../../hooks/usePortfolioFetcher";
import useFetchPortfolioTag from "../../../hooks/useFetchPortfolioTag";
import useDebounce from "../../../helper/useDebounce";
import PortfolioSettingServices from "../../../services/services/PortfolioSettingsService";
import UpdateGlobalState from "../../portfolio/common/UpdateGlobalState";
import BusinessProcessListFilter from "../../businessProcess/list/BusinessProcessListFilter";
import BusinessProcessListContent from "../../businessProcess/list/BusinessProcessListContent";
import { usePortfolioSetting } from "../../../Context/portfolioSettingProvider";
import { usePortfolioTag } from "../../../Context/portfolioTagProvider";
import {
  getMenuPortfolio,
  staticSettingPortfolioList2,
} from "../../../utils/settingCommon";
import {
  debounceTimeOut,
  deepFindByName,
  default_pageSize,
  default_pageSizeOptions,
  getSelectProperties,
  removeEmptyArrObjOrval,
  removeEmptyKeys,
} from "../../../utils/Common";
import BusinessProcessHeader from "../../businessProcess/BusinessProcessHeader";

const lifeCycleStagesColors = {
  active: "#FFB381",
  phaseIn: "#FFF280",
  phaseOut: "#FC819E",
  endOfLife: "#B2B377",
  color5: "#B5C0D0",
  color6: "#BFEF82",
  color7: "#90DDF9",
};

const sortByValue = [
  { value: "level", title: "Level" },
  { value: "displayname", title: "Name" },
];
const BusinessProcessList = ({
  Buttons,
  moduleName,
  setFilterOptions,
  deleteStart,
  setDeleteStart,
  setBulkDeleteButton,
  ...props
}) => {
  const { updateLocale } = UpdateGlobalState();
  const location = useLocation();
  const ref = useRef(null);
  const [form] = Form.useForm();
  const [showFilter, setShowFilter] = useState(true);
  const [portfolioData, setPortfolioData] = useState({});
  const { state: portfolioSettingState, dispatch: portfolioSettingDispatch } =
    usePortfolioSetting();
  const { loading: PortfolioLoading, fetchPortfolio } = usePortfolioFetcher();
  const [selectedFilters, setSelectedFilters] = useState({
    sortBy: "level,ASC",
  });
  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterChanges, setFilterChanges] = useState(false);

  // Submit filter state it should work only apply is clicked
  const [filterSubmit, setFilterSubmit] = useState(false);
  const [selectedRelationFilters, setSelectedRelationFilters] = useState({});
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(default_pageSize);
  const { state: stateTag, dispatch: dispatchTag } = usePortfolioTag();
  const { loading: tagLoading, error: tagError } = stateTag;
  const { error: errorTag, fetchTagsData } = useFetchPortfolioTag();
  const [bulkUpdateIds, setBulkUpdateIds] = useState([]);
  const [checkAllList, setCheckAllList] = useState(false);

  useEffect(() => {
    if (location?.state) {
      setPage(location?.state?.page);
      setLimit(location?.state?.limit);
    }
  }, [location?.state]);

  const getPageNumber = () => {
    let paze;
    if (location?.state?.page) {
      paze = location?.state?.page;
    } else {
      paze = page;
    }
    return paze;
  };

  const getPageLimit = () => {
    let limitz;
    if (location?.state?.limit) {
      limitz = location?.state?.limit;
    } else {
      limitz = limit;
    }
    return limitz;
  };

  useEffect(() => {
    if (portfolioSettingState?.data?.length) {
      const portfolioValues = getMenuPortfolio({ moduleName });
      if (portfolioValues?.portfolioSections?.length) {
        if (portfolioValues?.moduleName) {
          dispatchTag({ type: "EMPTY_TAGS" });
          dispatchTag({
            type: "SET_MODULE_NAME",
            payload: portfolioValues?.moduleName,
          });
        }
        setPortfolioData(portfolioValues);
      } else {
        setPortfolioData({});
      }
    } else {
      fetchAllPortfolio();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioSettingState?.data]);

  const fetchAllPortfolio = async () => {
    try {
      const response = await fetchPortfolio(portfolioSettingDispatch, 1, 100);
      if (response) {
        // message.success("Portfolio fetched successfully.");
      } else {
        message.error("Portfolio not found.");
      }
    } catch (error) {
      console.error("Error fetching portfolio:", error);
      message.error("Something went wrong.");
    }
  };

  useEffect(() => {
    setLoading(true);
    const pageNumber = getPageNumber();
    const pageLimit = getPageLimit();
    fetchFilteredData(selectedFilters, pageNumber, pageLimit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioSettingState]);

  // Filter and debounce
  const debouncedFilter = useDebounce(selectedFilters, debounceTimeOut);
  useEffect(() => {
    if (filterSubmit) {
      fetchFilteredData(removeEmptyArrObjOrval(debouncedFilter), page, limit);
    }
    //Export with filters
    setFilterOptions({
      ...removeEmptyArrObjOrval(debouncedFilter),
      page: page,
      limit: limit,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSubmit, debouncedFilter, page, limit]);

  //Fetch tags items
  const fetchAllPortfolioTags = async () => {
    try {
      await fetchTagsData();
    } catch (error) {
      console.error("Error fetching portfolio:", error);
      message.error("Something went wrong.");
    }
  };

  useEffect(() => {
    if (stateTag?.moduleName) {
      fetchAllPortfolioTags();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateTag?.moduleName]);

  const fetchFilteredData = async (filter, pageNumber, pageLimit) => {
    try {
      const modelval = staticSettingPortfolioList2(moduleName);
      setLoading(true);
      if (ref.current) {
        ref.current.continuousStart();
      }
      if (portfolioSettingState?.data?.length) {
        setCheckAllList(false);
        filter = removeEmptyKeys(filter);
        const portfolio = await deepFindByName(
          portfolioSettingState?.data,
          modelval,
          false
        );
        const updatedFilter = {
          moduleName: portfolio?.moduleName || "business_processes",
          ...filter,
        };

        const response = await PortfolioSettingServices?.getFilteredPortfolios(
          updatedFilter,
          pageNumber,
          pageLimit
        );
        if (response?.data?.data?.length) {
          setTimeout(() => {
            setLoading(false);
            setLists(response?.data?.data);
            setTotalRecords(response?.data?.totalCount || 0);
            updateLocale({
              key: portfolio?.moduleName,
              items: response?.data?.data,
            });
          }, 500);
        } else {
          //message.info("No records foundd.");
          setTimeout(() => {
            setLists(response?.data?.data);
            setTotalRecords(response?.data?.totalCount || 0);
            setFilterChanges(true);
          }, 500);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
      setFilterSubmit(false);
      if (ref.current) {
        ref.current.complete();
      }
    }
  };

  useEffect(() => {
    if (deleteStart === true && bulkUpdateIds.length > 0) {
      Modal.confirm({
        title: "Are you sure want to delete?",
        icon: <ExclamationCircleFilled />,
        async onOk() {
          try {
            let result;
            result = await PortfolioSettingServices.delete_bulk_Portfolio_ids({
              data: {
                id: bulkUpdateIds,
                moduleName: moduleName,
              },
            });
            if (result) {
              setBulkUpdateIds([]);
              setDeleteStart(false);
              fetchFilteredData(selectedFilters, page, limit);
              message.success("Deleted Successfully");
            } else {
              message.error(result?.data?.msg);
            }
          } catch (error) {
            console.log(error);

            message.error(error?.message);
          }
        },
        onCancel() {
          console.log("Cancel");
          setDeleteStart(false);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStart === true]);

  const onCheckboxChange = (e) => {
    let { value, checked } = e?.target;
    let ary = [...bulkUpdateIds];
    if (checked) {
      ary.push(value);
    } else {
      ary = ary.filter((f) => f?.toString() !== value?.toString());
    }
    setBulkUpdateIds(ary);
  };

  const setCheckAll = (check) => {
    let its = lists?.map((item) => item?.id);
    if (check) {
      setBulkUpdateIds([...bulkUpdateIds, ...its]);
    } else {
      setBulkUpdateIds([]);
    }
    setCheckAllList(check);
  };

  const onFinish = async (formData) => {
    let userData = JSON?.parse(localStorage?.getItem("userData"));
    try {
      formData = {
        modelName: portfolioData?.name,
        tableName: portfolioData?.routeModuleName,
        type: "summary",
        isDataExport: 1,
        userData: userData,
        jobId: null,
        dataids: bulkUpdateIds,
        ...formData,
      };
      if (bulkUpdateIds?.length === 0) {
        return message.error(`${portfolioData?.name} is required`);
      }
      await portfolioData?.services
        ?.getPortfolioSummaryReport(formData)
        .then((response) => {
          if (response?.data?.code === 200) {
            message.success(`Report saved successfully.`);
            form.resetFields();
            setBulkUpdateIds([]);
            props?.setGenerateModal(!props?.generateModal);
          } else {
            message.error(
              `Failed to generate to successfully.Please try again.`
            );
          }
        })
        .catch((error) => {
          console.log("error", error);
          message.error(`Failed to generate to successfully.Please try again.`);
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <LoadingBar color="#1f1f1f" ref={ref} />
      <Layout className="applicationlist-page">
        <Layout.Content className="left_pnlbx">
          <Spin className="loading_bx" size="small" spinning={loading}>
            <div className="container-fluid">
              <BusinessProcessHeader
                activePage={0}
                setActivePage={() => {}}
                heading={"Capability Solution Report"}
                matrix={false}
                grid={false}
                list={false}
                addBtn={false}
                bulk_delete={false}
                showActionBtn={false}
                Buttons={
                  <Buttons2
                    Buttons={Buttons}
                    setCheckAll={(value) => {
                      setCheckAll(value);
                    }}
                  />
                }
              />

              <div
                className="overflow-y listview_bx"
                style={{ maxHeight: window?.innerHeight - 200 }}
              >
                <Checkbox
                  onChange={(e) => {
                    setCheckAll(e.target.checked);
                  }}
                  checked={checkAllList}
                  style={{ marginLeft: 10 }}
                >
                  Select All
                </Checkbox>
                <Checkbox.Group className="w-100" value={bulkUpdateIds}>
                  <BusinessProcessListContent
                    lists={lists}
                    loading={loading}
                    moduleName={moduleName}
                    basePath={location?.pathname}
                    isListCheckbox={true}
                    onCheckboxChange={onCheckboxChange}
                    page={page}
                    limit={limit}
                  />
                </Checkbox.Group>
              </div>
              {totalRecords > 0 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span className="mt-2" style={{ fontSize: 14 }}>
                    {" "}
                    {bulkUpdateIds?.length} Selected{" "}
                    {`${bulkUpdateIds?.length > 1 ? "Items" : "Item"}`}{" "}
                  </span>
                  <Pagination
                    className="mt-2 text-right"
                    total={totalRecords}
                    showTotal={(total) => `Total ${total} Items`}
                    defaultPageSize={limit || default_pageSize}
                    defaultCurrent={page}
                    pageSizeOptions={default_pageSizeOptions}
                    showSizeChanger
                    onChange={(p, ps) => {
                      setFilterSubmit(true);
                      setLimit(ps);
                      setPage(p);
                    }}
                  />
                </div>
              )}
            </div>
          </Spin>
        </Layout.Content>
        <Layout.Sider width={!showFilter ? 50 : 240} className="right_pnlbx">
          {portfolioData && (
            <BusinessProcessListFilter
              lifeCycleStagesColors={lifeCycleStagesColors}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              portfolioData={getSelectProperties(
                portfolioData?.portfolioSections,
                true
              )}
              selectedFilters={selectedFilters}
              // setSelectedFilters={setSelectedFilters}
              setSelectedFilters={(value) => {
                setSelectedFilters(value);
              }}
              sortValues={sortByValue}
              optionalLevel={true}
              //Filter relations
              filterSubmit={filterSubmit}
              setFilterSubmit={(value) => {
                setFilterSubmit(value);
              }}
              selectedRelationFilters={selectedRelationFilters}
              setSelectedRelationFilters={setSelectedRelationFilters}
              isSourceTargetModuleName={portfolioData?.moduleName}
            />
          )}
        </Layout.Sider>
      </Layout>
      <Modal
        open={props?.generateModal}
        onCancel={() => {
          props?.setGenerateModal(!props?.generateModal);
        }}
        footer={false}
      >
        <Form form={form} onFinish={onFinish} requiredMark={false}>
          <Card title={`Generate Report`} className="border" size={`small`}>
            <Form.Item
              name="name"
              label="Report Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Card>
          <Flex gap={8} align="center" justify="flex-end" className="my-2">
            <Button type="primary" htmlType="submit" className="pull-right">
              Submit
            </Button>
          </Flex>
        </Form>
      </Modal>
    </>
  );
};

BusinessProcessList.defaultProps = {
  setFilterOptions: () => {},
  Buttons: () => {
    return <></>;
  },
};

export default BusinessProcessList;

const Buttons2 = ({ Buttons = () => {}, setCheckAll, checkAll }) => {
  return (
    <React.Fragment>
      <Flex gap={8} align="center">
        {/* <Button onClick={()=>{ setCheckAll(true); }}>Check All</Button>
                <Button onClick={()=>{ setCheckAll(false); }}>Uncheck All</Button> */}
        <Buttons />
      </Flex>
    </React.Fragment>
  );
};
