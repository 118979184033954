import React, { useEffect } from 'react'
import { PageTitleHeading } from '../../utils/Common'
import { Button, Flex, Tooltip, Modal,message } from 'antd'
import ActionsBtn from '../applicationlist/ActionsBtn'
import { ApartmentOutlined, PlusOutlined, UnorderedListOutlined, DeleteRowOutlined, ExclamationCircleFilled, RadarChartOutlined } from '@ant-design/icons'
import gridSvg from "./../../assets/Two columns layout.svg";
import matrixSvg from "./../../assets/Matrix.svg";
import { useLocation, useNavigate, useParams } from 'react-router'
import PortfolioSettingServices from '../../services/services/PortfolioSettingsService'
import { param } from 'jquery'
import { checkRole } from '../../helper/useUserData'

const BusinessProcessHeader = ({setActivePage,activePage,setDeleteStart,heading,grid,bulk_delete,list,matrix,filterOptions,tree,...props}) => {
    const {moduleName} = useParams();
    const navigate = useNavigate();
    const location = useLocation();          

    return (
        
            <Flex wrap gap="small" className="headingbx" justify='space-between'>
                <PageTitleHeading text={heading} />
                <Flex wrap gap="small" className="ml-2" align="center">
                    {moduleName && moduleName == 'Technology' && <Tooltip title={"Radar View"}><Button 
                        onClick={()=>{setActivePage(3)}}
                        icon={<RadarChartOutlined />}
                        style={{width:'30px'}}
                    /></Tooltip>}
                    {list && <Button 
                        onClick={()=>{setActivePage(1)}}
                        icon={<UnorderedListOutlined />}
                        {...(activePage === 1 ? {type:"primary",ghost:true}:{block:true})}
                        style={{width:'30px'}}
                    />}
                    {grid && <Button 
                        onClick={()=>{setActivePage(2)}}
                        icon={<img src={gridSvg} alt="Grid" width={20}/>}
                        {...(activePage === 2 ? {type:"primary",ghost:true}:{block:true})}
                        style={{width:'30px'}}
                    />}                                       
                    {matrix && <Button 
                        onClick={()=>{setActivePage(3)}}
                        icon={<img src={matrixSvg} alt="Matrix" width={20}/>}
                        {...(activePage === 3 ? {type:"primary",ghost:true}:{block:true})}
                        style={{width:'30px'}}
                    />}
                    {tree && <Button 
                        onClick={()=>{setActivePage(4)}}
                        icon={<ApartmentOutlined />}
                        {...(activePage === 4 ? {type:"primary",ghost:true}:{block:true})}
                        style={{width:'30px'}}
                    />}
                    {props?.Buttons}
                    {/* {console.log(location?.pathname,"===location?.pathname")}
                    {activePage === 1 && checkRole('delete') && location?.pathname !== "/reports/application-landscape" && location?.pathname !== "/reports/integration-landscape" && location?.pathname !== "/reports/technology-landscape" && <Tooltip title={"Bulk Delete"}><Button  */}
                    {props?.bulkDelete && activePage === 1 && checkRole('delete')  && <Tooltip title={"Bulk Delete"}><Button 
                        disabled={bulk_delete ? false :true }
                        onClick={()=>{setDeleteStart(true)}}
                        icon={<DeleteRowOutlined style={{color:'#2a609d'}} />}
                       // {...(activePage === 1 ? {type:"primary",ghost:true}:{block:true})}
                        style={{width:'30px'}}
                    /></Tooltip>} 
                    {props?.addBtn && checkRole('add') && <Tooltip title={props?.addBtnTooltip}>
                        <Button 
                            //className='btn-level-2' 
                            icon={<PlusOutlined style={{color:'#2a609d'}}/>} 
                            onClick={() => { 
                                navigate(location?.pathname+'/add' , { state: {Level:1,ParentID: 0, ParentName: '',underLevel: '',from:location?.pathname,defaultActiveKey:activePage } })
                            }}
                        />
                    </Tooltip>}                    
                    {
                        props?.showActionBtn && 
                        <ActionsBtn name={heading} showImport={props?.showImport} showExport={props?.showExport} exportWithFilter={filterOptions}/>
                    }
                </Flex>
            </Flex>
        
    )
}

BusinessProcessHeader.defaultProps = {
    heading:"Business Process",
    grid:true,
    list:true,
    matrix:false,
    tree:false,
    addBtn:true,
    bulkDelete:true,
    addBtnTooltip:"",
    Buttons:()=>{},
    showActionBtn:true,
    showImport:true,
    showExport:true,
}

export default BusinessProcessHeader