import React, { useEffect, useState } from "react";
import Delayed from "../../../utils/Delayed";
import { Link } from "react-router-dom";

function HomeVideo({ data }) {
  const ExpandableText = ({ text, maxLength }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
    };

    if (text?.length <= maxLength) {
      return <p>{text}</p>;
    }

    if (text?.length > maxLength) {
      return (
        <div>
          <p>{isExpanded ? text : `${text?.substring(0, maxLength)}...`}</p>
          {/* <button onClick={toggleExpand} style={{ color: "blue", cursor: "pointer" }}>
                  {isExpanded ? 'Show Less' : 'Show More'}
                </button> */}
        </div>
      );
    }
  };

  const Row = ({ item }) => {
    return (
      <React.Fragment>
        <hr style={{ marginTop: 5, marginBottom: 5 }} />
        <h4>
          <ExpandableText text={item?.caption} maxLength={150} />
        </h4>
        <div className="embed-responsive embed-responsive-16by9">
          <video width="320" height="240" controls>
            <source
              src={item?.linkUrl}
              type="video/mp4"
              className="embed-responsive-item mt-0 pt-0"
            />
            <source
              src={item?.linkUrl}
              type="video/ogg"
              className="embed-responsive-item mt-0 pt-0"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </React.Fragment>
    );
  };

  return (
    <>
      <div
        className=" pr-3 listview_bx"
        style={{ maxHeight: window?.innerHeight - 100, width: "100%" }}
      >
        {(data?.length > 0 &&
          data?.map((item, index) => <Row item={item} />)) || (
          <Delayed waitBeforeShow={2000}>No items found.</Delayed>
        )}

        <br/>
        <Link to="/userguidevideo" className="">
          see more...
        </Link>
        <br/>
        <br/>
      </div>
    </>
  );
}

export default HomeVideo;
