import { useState, useEffect } from "react";
import PortfolioRelationshipServices from "../services/services/PortfolioRelationshipService";
import { useApplicationlink } from "../Context/linkProvider";
import { usePortfolioRelationship } from "../Context/portfolioRelationshipProvider";
import { message } from "antd";

const usePortfolioRelationshipModify = () => {
  const { dispatch } = usePortfolioRelationship();
  const { state: linkState, dispatch: linkDispatch } = useApplicationlink();
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);

  const addPortfolioRelationship = async (relationshipData, callback) => {
    setDataLoading(true);

     
    try {
      if (
        !linkState?.businessApplicationId &&
        !linkState?.businessApplicationModel
      ) {
        return message.error("Module not found.");
      }
      const newRelationshipData = {
        nodeOne: linkState?.businessApplicationId,
        nodeOneTag: linkState?.businessApplicationModel,
        nodeTwo: relationshipData?.id,
        nodeTwoTag: relationshipData.moduleName,
      };
	  if (newRelationshipData && typeof newRelationshipData === 'object') {
		newRelationshipData.UserId = localStorage?.email;
	  }
      const request = [newRelationshipData];
		
      const response = await PortfolioRelationshipServices.create(request);
      if (!response) {
        throw new Error("Failed to add relationship");
      }
      const newRelationship = response.data;
      console.log("response", newRelationship);
      // Dispatch action to add the new relationship
      dispatch({
        type: "ADD_PORTFOLIO_RELATIONSHIP",
        payload: newRelationship,
      });
      callback();
      //message.success("Portfolio relationship added successfully.");
      message.success("Created Successfully.");
      return newRelationship; // Return the new relationship data
    } catch (error) {
      console.log("Error adding relationship:", error);
      message.error("Error adding relationship");
      setError(error); // Set error state
    } finally {
      setDataLoading(false);
    }
  };

  const removePortfolioRelationship = async (relationshipId, callback) => {
    setDataLoading(true);
    try {

      //console.log("relationshipId remove",relationshipId, uniqueid);
		const relData = {
            "id": relationshipId,
            "UserId": localStorage?.email
        };
      const response =
        await PortfolioRelationshipServices.deletePortfolioRelationship(
          relData
        );
      callback();
      //message.success("Portfolio relationship removed successfully.");
      message.success("Deleted Successfully.");
      return response.data; // Return the response data
    } catch (error) {
      console.log("Error removing relationship:", error);
      message.error("Error removing relationship");
      setError(error); // Set error state
    } finally {
      setDataLoading(false);
    }
  };

  return {
    addPortfolioRelationship,
    removePortfolioRelationship,
    dataLoading,
    error,
  };
};

export default usePortfolioRelationshipModify;
