import http from "./httpService";
const API_BASE_URL = process.env.REACT_APP_API_URL;

async function getList({ limit = 20, page = 1 }) {
  const url = `${API_BASE_URL}portfoliorelationship?page=${page}&limit=${limit}`;
  let response = await http.get(url);
  return response;
}

async function create(data) {
  const url = `${API_BASE_URL}portfoliorelationship`;
  let response = await http.post(url, data);
  return response;
}

async function update(id, data) {
  const url = `${API_BASE_URL}portfoliorelationship/${id}`;
  let response = await http.put(url, data);
  return response;
}

async function deletePortfolioRelationship(data) {
  const url = `${API_BASE_URL}portfoliorelationship/deleterelation`;
  let response = await http.post(url,data);
  return response;
}


// async function getAllNode({ limit = 20, page = 1 }) {
//   const url = `${API_BASE_URL}portfoliorelationship/getbynode`;
//   let response = await http.get(url);
//   return response;
// }
async function getAllNode(props) {
  const url = `${API_BASE_URL}portfoliorelationship/getbynode`;
  const queryString = Object.keys(props)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(props[key])}`
    )
    .join("&");
  const requestUrl = queryString ? `${url}?${queryString}` : url;
  let response = await http.get(requestUrl);
  return response;
}
async function getById(id) {
  const url = `${API_BASE_URL}portfoliorelationship/`;
  let response = await http.get(url + id);
  return response;
} 

async function getModuleData(data) {
  const url = `${API_BASE_URL}portfoliorelationship/get-module-data`;
  let response = await http.post(url, data);
  return response;
}

async function deletePortfolioBulkRelationship(data) {
  const url = `${API_BASE_URL}portfoliorelationship/delete_bulk`;
  let response = await http.post(url,data);
  return response;
}
const PortfolioRelationshipServices = {
  getList,
  create,
  update,
  deletePortfolioRelationship,
  getAllNode,
  getById,
  getModuleData,
  deletePortfolioBulkRelationship
};

export default PortfolioRelationshipServices;
