import React, { useEffect, useState } from 'react'
import BusinessProcessHeader from '../../businessProcess/BusinessProcessHeader';
import TechnologyGrid from '../plant/PlantGrid';
import TechnologyList from '../plant/PlantList';
import {getMenuPortfolio} from '../../../utils/settingCommon'
import "./Technology.scss";
import { useLocation } from 'react-router';
import { showViewType } from '../common/PortfolioCommon';
import TechRadar from "./TechRadar";

const Technology = ({moduleName}) => {
    const location = useLocation()
    const [activePage,setActivePage] = useState(0);
    const [moduleData,setModuleData] = useState(null);
    const [filterOptions, setFilterOptions] = useState({});   
    const [bulkDeleteButton,setBulkDeleteButton] = useState(false);
    const [deleteStart,setDeleteStart] = useState(false);

    useEffect(()=>{
        const params = new URLSearchParams(location.search);
        const techRadarParam = params.get('techradar');
        if (techRadarParam === '1') {
            setActivePage(3);
        } else {
            setActivePage(location?.state?.defaultActiveKey || 1);
        }
    },[location]);
    
    useEffect(()=>{
        let module = getMenuPortfolio({routeModuleName:moduleName});
        setModuleData(module);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const HeaderContnet = <BusinessProcessHeader 
        activePage={activePage}
        setActivePage={setActivePage}
        heading={(moduleData?.name || moduleName)}
        addBtnTooltip={`Add New`}
        grid={false}
        list={false}
        filterOptions={filterOptions}              
        bulk_delete={bulkDeleteButton}
        setDeleteStart={setDeleteStart}
    />

    return (
        <div className='applicationlist-page'>
            { 
                activePage === 2 && showViewType({moduleName:moduleData?.moduleName,viewType:"tower_view"}) &&
                <TechnologyGrid 
                    HeaderContnet = {HeaderContnet}
                    moduleName={moduleName}
                />
            }
            { 
                activePage === 1 && showViewType({moduleName:moduleData?.moduleName,viewType:"list_view"}) &&
                <TechnologyList 
                    HeaderContnet = {HeaderContnet}
                    moduleName={moduleName}
                    staticModel = {moduleData?.name}
                    filterOptions={filterOptions}
                    setFilterOptions={setFilterOptions}                                        
                    setBulkDeleteButton={setBulkDeleteButton}
                    deleteStart={deleteStart}
                    setDeleteStart={setDeleteStart}
                />
            }
            { 
                activePage === 3 &&
                <TechRadar setActivePage={setActivePage} />
            }
        </div>
    )
}

export default Technology