import "./ApplicationList.scss";
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { message, Modal, Button, Upload, Select, Form, Spin, Typography } from "antd";
import * as XLSX from "xlsx";
 
import DataImportScenarioServices from "../../services/services/DataImportScenarioServices";
import {
  addIsTextToProperties,
  deepFindByName,
  exportWitStatic,
  objectToQueryParams,
  portfolioSortingValues,
  statusColors,
} from "../../utils/Common";
import PortfolioSettingServices from "../../services/services/PortfolioSettingsService";
import {
  setMenuPortfolio,
} from "../../utils/settingCommon";
import { cleanData } from "jquery";
import { LoadingOutlined } from "@ant-design/icons";
import BpmnServices from "../../services/services/BpmnServices";

const { Text } = Typography;

const tableData = [
  ["App ID", "App Name", "Status", "Application Type", "TBD title"],
  ["App-126", "CF", "Approved", "Web Application", "TBD"],
  ["App-116", "", "Approved", "Client Server", "TBD"],
];

const samplejson1 = [
  {
    NameInSourceSystem: "",
    DisplayName: "",
    // Attributes: "",
    ParentID: "",
    SourceID: "",
    Level: "",
    Description: "",
  },
];

const ImportApplicationData = ({isDiagram=false,...props}) => {
  const [form] = Form.useForm();
  const [messageApi] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [uploadedData, setUploadedData] = useState([]);
  const [formModalData, setFormModalData] = useState({});
  const [openPreDefinedFormModal, setOpenPreDefinedFormModal] = useState(false);
  const [previewDataModal, setPreviewDataModal] = useState(false);
  const [openOwnFormatFormModal, setOpenOwnFormatFormModal] = useState(false);
  const [errorCells, setErrorCells] = useState([[2, 1]]);
  const [openOwnFormatFormMappingModal, setOpenOwnFormatFormMappingModal] =
    useState(false);
  const [errorData, setErrorData] = useState([]);

  const [model, setModel] = useState("");
  const [view, setView] = useState("");

  const [formItems, setFormItems] = useState([]);
  const [portfolioData, setPortfolioData] = useState({});
  let { moduleName } = useParams();
  const [templateData, setTemplateData] = useState([]);
  const [openUploadProcessModel, setOpenUploadProcessModel] = useState(false);
  const [preInsertedItems, setPreInsertedItems] = useState([]);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  //New states
  const [excelJsonData, setExcelJsonData] = useState([]);
  const [updatedItems, setUpdatedItems] = useState([]);
  const [directImport, setDirectImport] = useState(false);
  const previousUpdatedItemsRef = useRef(0);
  const uploadRef = useRef(null);

  //Get all settings
  const fetchAllPortfolio = async () => {
    try {
      const response = await PortfolioSettingServices.getList({
        page: 1,
        limit: 200,
      });
      setPortfolioData(response);
      setMenuPortfolio(response?.data?.data);
    } catch (error) {
      console.error("Error fetching portfolio:", error);
      message.error("Something went wrong.");
    }
  };

  //Get Template
  const getExcelTemplate = async () => {
    try {
      const isFound = exportWitStatic.includes(moduleName.toString().toLowerCase()) ? 1 : 0;
      const response = await DataImportScenarioServices.getExcelTemplate({
        moduleName: model,
        unknownSource: isFound,
      });

      setTemplateData(response);
    } catch (error) {
      console.error("Error fetching Template Data:", error);
      message.error("Something went wrong.");
    }
  };

  //Get all pre-inserted data
  const getPreInsertedData = async (moduleName,excelData)=>{
    try {
      setLoading(true);
      const moduleNameNew = portfolioData?.data?.data?.find(
        (item) => transformText(item.moduleName, "pascal") == moduleName
      );
      const isFound = exportWitStatic.includes(moduleNameNew?.moduleName) ? 1 : 0;
      const findDisplayName = formItems?.find((item)=> item?.name === 'displayname')
      if (!findDisplayName) {
        return message.error("Portfolio Item not found.");
      }
      const getAllExcelDisplayName = findDisplayName && excelData && excelData?.length > 0 ? excelData?.map((item)=> item[findDisplayName?.displayName]) : [];
      const response = await DataImportScenarioServices.ExportAllPortfolio({
        modelName: moduleNameNew.name,
        tableName: moduleName,
        view:view,
        unknownSource: isFound,
        isDataExport: 0,
        findByName: findDisplayName?.name,
        filterByName: getAllExcelDisplayName
      });
      setPreInsertedItems(response?.data?.data?.length ? response?.data?.data : []);
      if (!directImport) {
        setPreviewDataModal(!previewDataModal);
      }else{
        previousUpdatedItemsRef.current = response?.data?.code;
      }
    } catch (error) {
      setPreInsertedItems([]);
      message.error(error?.message);
      console.error(error);
    }finally{
      setLoading(false);
    }
  }

  useEffect(() => {
   if (uploadedData) {
    let checkFields = checkAndTagChanges(uploadedData, preInsertedItems);
    checkFields =
      Array.isArray(checkFields) && checkFields.length > 0
        ? checkFields
            .sort((a, b) => {
              const order = ["New", "Modified", "Old"];
              return (
                order.indexOf(a.importStatus) - order.indexOf(b.importStatus)
              );
            })
            // .filter((item) => item.importStatus !== "Old") // Remove all "Old" records
        : [];
    setUpdatedItems(checkFields);
   }
  }, [uploadedData,preInsertedItems]);


  useEffect(() => {
    if (directImport) {
      if (previousUpdatedItemsRef.current === 200) {
        setTimeout(() => {
            if (updatedItems?.filter((item) => item.importStatus !== "Old")?.length > 0) {
              uploadData();
            } else {
              message.error("New or Modified records not found.");
            }
        }, 700);
      }
    }
  }, [updatedItems,directImport]);

  useEffect(() => {
    if (moduleName == "BusinessApplication") {
      setModel("Application");
      setView("List");
    } else if (moduleName == "BusinessCapability") {
      setModel("BusinessCapability");
      setView("Grid");
    } else if (moduleName == "BusinessProcesses") {
      setModel("Business Process");
      setView("Grid");
    }
  }, []);

  useEffect(() => {
    fetchAllPortfolio();
  }, []);

  useEffect(() => {
    getExcelTemplate();
  }, []);

  useEffect(() => { 
  }, [formItems]);

  useEffect(() => {
    
    let moduleTemp = "";
 
    
    const moduleNameNew = portfolioData?.data?.data?.find(
      (item) => transformText(item.moduleName, "pascal") == moduleName
    ); 
 
    if (portfolioData?.data?.data?.length) {
      const portfolioValues = deepFindByName(
        portfolioData?.data?.data,
        moduleNameNew?.name,
        false
      );

      const formItem = [];
      portfolioValues?.portfolioSections.map((section, index) => {
        section?.properties?.length > 0 &&
          section?.properties?.map((property, index) => {
            formItem.push(property);
          });
      });
      setFormItems(formItem);
    }
  }, [portfolioData?.data?.data]);

  const checkTextCase = (name) => {
    if (isPascalCase(name) || isCamelCase(name)) {
      return name; // If already in PascalCase or camelCase, return unchanged
    } else {
      return transformText(name, "camel"); // Convert to camelCase otherwise
    }
  };

  const isPascalCase = (text) => {
    return /^[A-Z][a-zA-Z0-9]*$/.test(text);
  };

  const isCamelCase = (text) => {
    return /^[a-z][a-zA-Z0-9]*$/.test(text);
  };

  const transformText = (text, caseType) => {
    switch (caseType) {
      case "snake":
        return text.toLowerCase().replace(/\s+/g, "_");
      case "camel":
        return text
          .toLowerCase()
          .replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase());
      case "kebab":
        return text.toLowerCase().replace(/\s+/g, "-");
      case "pascal":
        return (" " + text)
          .toLowerCase()
          .replace(/[^a-zA-Z0-9]+(.)/g, function (match, chr) {
            return chr.toUpperCase();
          });
      default:
        return text;
    }
  };

  // Set auto-width for columns
  const autoWidth = async (ws) => {
    const colWidth = ws["!cols"] || [];
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
    data.forEach((row) => {
      row.forEach((cell, i) => {
        const length = cell ? cell.toString().length : 10;
        colWidth[i] = {
          wch: Math.max(colWidth[i] ? colWidth[i].wch : 0, length),
        };
      });
    });
    ws["!cols"] = colWidth;
  };

  // Apply styles
  const setSheetStyles = async (ws) => {
    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell_address = { c: C, r: R };
        const cell_ref = XLSX.utils.encode_cell(cell_address);
        if (!ws[cell_ref]) continue;
        if (R === 0) {
          // Header row

          ws[cell_ref].s = {
            font: { bold: true, color: { rgb: "#FFFFFF" } },
            fill: { fgColor: { rgb: "#000000" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
            },
            alignment: { horizontal: "center", vertical: "center" },
          };
        } else {
          // Data cells
          ws[cell_ref].s = {
            border: {
              top: { style: "thin", color: { rgb: "000000" } },
              bottom: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } },
            },
          };
        }
      }
    } 
  };

  const downloadExcelTemplate = async () => {
    try {
      let mmm = "";
      

      const res = await PortfolioSettingServices.getList({
        page: 1,
        limit: 100,
      });
      const newData = res.data.data;

      const moduleNameNew = newData.find(
        (item) => transformText(item.moduleName, "pascal") == moduleName
      ); //BusinessApplication

      const isFoundIntegrationSource = exportWitStatic.includes(moduleNameNew?.moduleName) ? 1 : 0;
      const response = await DataImportScenarioServices.getExcelTemplate({
        //moduleName: mmm,
        moduleName: moduleNameNew?.name,
        unknownSource: isFoundIntegrationSource,
      });

      setTemplateData(response);
      const data = response?.data?.data;

      //const worksheet = XLSX.utils.json_to_sheet([response?.data?.data]);
      const worksheet = XLSX.utils.json_to_sheet([data]);


      // Apply auto-width and styles to the worksheet
      await autoWidth(worksheet);
      await setSheetStyles(worksheet);

      ////////////////////////////////
      const cellStyle = {
        fill: {
            fgColor: { rgb: "FFFF00" } // Yellow background
        },
        font: {
            color: { rgb: "FF0000" } // Red text
        }
    };

    worksheet['A1'].s = cellStyle; // Apply style to cell A1
    worksheet['B1'].s = cellStyle; // Apply style to cell B1

       
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, moduleNameNew?.name);
      //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

      XLSX.writeFile(workbook, moduleNameNew?.name + ".xlsx");
    } catch (error) {
      console.error("Error fetching Template Data:", error);
      message.error("Something went wrong.");
    }
  };

  const uploadFile = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept:
      "xls,xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    beforeUpload: () => {
      return false;
    },
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        setIsFileUploaded(true);
      } else if (status === 'error' || status === 'removed') {
        setIsFileUploaded(false);
      }
    },

    onDrop(e) { 

    },
  };

  const onFinish = (formData) => {
    setLoading(true);
    const errData = [];
    const arrData = [];
    setFormModalData(formData);
    const file = formData?.upload_file?.file;
    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet,{
        defval: "",  // Ensure empty cells are represented as empty strings
        // raw: false,  // Parse the values into text (prevents dropping empty values)
        // header: 1,
      });
      setExcelJsonData(jsonData);

      ///Compare Columns

      let formItemFiled = [];  
      let missngField = "";
      let errorObj = [];
      let flag = false;
      let optionValueMissing = "";

      let formItems1 = [];

      // if (view === "Grid") {
      //   formItems1 = [
      //     ...formItems,
      //     { name: "Parent", displayName: "Parent", isRequired: true },
      //   ];
      // }


      const moduleNameNew = portfolioData?.data?.data?.find(
        (item) => transformText(item.moduleName, "pascal") == moduleName
      ); 

      
      if (portfolioData?.data?.data?.length) {
        const portfolioValues = deepFindByName(
          portfolioData?.data?.data,
          moduleNameNew.name,
          false
        );
  
        portfolioValues?.portfolioSections.map((section, index) => {
          section?.properties?.length > 0 &&
            section?.properties?.map((property, index) => {
              formItemFiled.push(property);
            });
        });
        setFormItems(formItemFiled);
      }
  
      if (formItemFiled.length) {
        formItemFiled.map((prop) => {
          if (jsonData.length) {
            const excelField = Object.keys(jsonData[0]);
            if (prop.isRequired) {
              if (excelField.includes(prop.displayName)) {
              } else {
                errorObj.push({
                  ErrorType: "Missing Field",
                  Error: prop.displayName.trim(),
                  Row: "",
                  Column: prop.displayName.trim(),
                });

                if (missngField.length) {
                  missngField = missngField + ", " + prop.displayName.trim();
                } else {
                  missngField = prop.displayName;
                }
              }
            }
          } else {
            if (prop.isRequired) {
              errorObj.push({
                ErrorType: "Missing Field",
                Error: prop.displayName.trim(),
                Row: "",
                Column: prop.displayName.trim(),
              });
            }

            if (missngField.length) {
              missngField = missngField + ", " + prop.displayName.trim();
            } else {
              missngField = prop.displayName;
            }
          }
        });
 
        //if (missngField == "") {
        let portfolioValues;
        portfolioValues = deepFindByName(
          portfolioData?.data?.data,
          moduleNameNew.name,
          false
        );

        const formItem = [];
        portfolioValues?.portfolioSections.map((section, index) => {
          section?.properties?.length > 0 &&
            section?.properties?.map((property, index) => {
              formItem.push(property);
            });
        });
        setFormItems(formItem);

        //getting prop columns Name
        const objData = {};
        formItem.forEach((item) => {
          objData[item.displayName] = "";
        });

        let columns = Object.keys(objData);
        columns = columns?.length ? ["id",...columns] : columns;
   
        // transformText(portfolioProperties?.name, "camel")

        if (jsonData.length) {
          jsonData.map((jData, iRow) => {
            let sData = {};

            columns.map((prop, colIndex) => {
              //let portfColumn=transformText( prop,"camel")
              //let portfColumn = prop.trim().replaceAll(" ", "");
              let portfColumn = prop;

              if (jData[prop] === undefined) {
                portfolioValues?.portfolioSections.map((section, index) => {
                  section?.properties?.length > 0 &&
                    section?.properties?.map((property, index) => {
                      // if (view === "Grid") {
                      //   if (prop == "Parent") {
                      //     errorObj.push({
                      //       ErrorType: "Required Field",
                      //       Error: prop,
                      //       Row: "",
                      //       Column: prop,
                      //     });
                      //   }
                      // }

                      if (prop == property.displayName) {
                        if (property.isRequired) {
                          //Check

                          errorObj = errorObj.filter(
                            (item) =>
                              !(
                                item.ErrorType === "Missing Field" &&
                                item.Column === property.displayName
                              )
                          );

                          errorObj.push({
                            ErrorType: "Required Field",
                            Error: property.displayName,
                            Row: "",
                            Column: property.displayName,
                          });
                        }

                        if (property?.type === "Single select") {
                          sData[portfColumn] = null;
                        } else if (property?.type === "Multi select") {
                          sData[portfColumn] = null;
                        } else {
                          //it will change as blank
                          console.log(
                            "Undefined property.name",
                            property.displayName
                          );

                          sData[portfColumn] = null;
                        }
                      } else {
                      }
                    });
                });
              } else {
                //sData[prop] = jData[prop];
                let jsonObject = {};
                flag = false;
                //Checking Prop and Its Type And  Popuate the Property
                portfolioValues?.portfolioSections.map((section, index) => {
                  section?.properties?.length > 0 &&
                    section?.properties?.map((property, index) => {
                      if (prop === property.displayName) {
                       
                        
                        if (property?.type === "Single select") {
                          if (property?.isRequired) {
                            if (jData[prop] != "") {
                              property?.propertyOptions.map((propvalue) => {
                                if (propvalue?.name == jData[prop]) {
                                  //oldList[dataItem] = propvalue?.name;
                                  sData[portfColumn] = propvalue?.id;
                                  //sData[transformText(prop, "camel")] = propvalue?.id;

                                  flag = true;
                                }
                              });
                            } else {
                              // errorObj.push({
                              //   ErrorType: "Wrong Value",
                              //   Error: jData[prop],
                              //   Row: parseInt(iRow, 10) + 1,
                              //   Column: prop,
                              // });
                              errorObj.push({
                                ErrorType: "Wrong Value",
                                Error: jData[property.displayName],
                                Row: parseInt(iRow, 10) + 1,
                                Column: property.displayName,
                              });
                            }
                          } else {
                            if (jData[prop] != "") {
                              property?.propertyOptions.map((propvalue) => {
                                if (propvalue?.name == jData[prop]) {
                                  //oldList[dataItem] = propvalue?.name;
                                  sData[portfColumn] = propvalue?.id;
                                  //sData[transformText(prop, "camel")] = propvalue?.id;

                                  flag = true;
                                }
                              });
                            } else {
                              flag = true;
                            }
                          }

                          if (!flag) {
                            sData[portfColumn] = 0;
                            errorObj.push({
                              ErrorType: "Wrong Value",
                              Error: jData[property.displayName],
                              Row: parseInt(iRow, 10) + 1,
                              Column: property.displayName,
                            });
                            //if (property?.isRequired) {
                            if (optionValueMissing.length) {
                              optionValueMissing =
                                optionValueMissing +
                                "<br/> Row No: " +
                                (parseInt(iRow, 10) + 1) +
                                "  Column " +
                                prop +
                                "  " +
                                jData[prop] +
                                " option(s) are wrong ";
                            } else {
                              optionValueMissing =
                                "Row No: " +
                                (parseInt(iRow, 10) + 1) +
                                "  Column " +
                                prop +
                                "  " +
                                jData[prop] +
                                " option(s) are wrong ";
                            }
                            //}
                          }
                        } else if (property?.type === "Multi select") {
                          //let lst = data[dataItem];
                          flag = false;

                          let lst = jData[prop].split(",");
                          if (property?.isRequired) {
                            if (lst?.length) {
                              let propValue = "";

                              for (let i = 0; i < lst?.length; i++) {
                                flag = false;

                                property?.propertyOptions.map((propvalue) => {
                                  if (
                                    checkTextCase(propvalue?.name) ===
                                    checkTextCase(lst[i])
                                  ) {
                                    flag = true;
                                    if (propValue == "") {
                                      propValue = propvalue?.id;
                                    } else {
                                      propValue =
                                        propValue + ", " + propvalue?.id;
                                    }
                                  }
                                });

                                ///message
                                if (!flag) {
                                  //if (property?.isRequired) {
                                  errorObj.push({
                                    ErrorType: "Wrong Value",
                                    Error: jData[property.displayName],
                                    Row: parseInt(iRow, 10) + 1,
                                    Column: property.displayName,
                                  });

                                  if (optionValueMissing.length) {
                                    optionValueMissing =
                                      optionValueMissing +
                                      "<br/> Row No: " +
                                      (parseInt(iRow, 10) + 1) +
                                      "  Column " +
                                      prop +
                                      "  " +
                                      lst[i] +
                                      " option(s) are wrong ";
                                  } else {
                                    optionValueMissing =
                                      "Row No: " +
                                      (parseInt(iRow, 10) + 1) +
                                      "  Column " +
                                      prop +
                                      "  " +
                                      lst[i] +
                                      " option(s) are wrong ";
                                  }
                                  //}
                                }
                                ///end Message
                              }
                              const str = "{" + propValue + "}";
                              sData[portfColumn] = str.replace(/"/g, "");
                              //sData[transformText(prop, "camel")]=str.replace(/"/g, "");
                            } else {
                              sData[portfColumn] = "";

                              errorObj.push({
                                ErrorType: "Wrong Value",
                                Error: jData[property.displayName],
                                Row: parseInt(iRow, 10) + 1,
                                Column: property.displayName,
                              });
                              //Error
                            }
                          } else {
                            if (lst?.length) {
                              let propValue = "";

                              for (let i = 0; i < lst?.length; i++) {
                                flag = false;

                                if (checkTextCase(lst[i]) != "") {
                                  property?.propertyOptions.map((propvalue) => {
                                    if (
                                      checkTextCase(propvalue?.name) ==
                                      checkTextCase(lst[i])
                                    ) {
                                      flag = true;
                                      if (propValue == "") {
                                        propValue = propvalue?.id;
                                      } else {
                                        propValue =
                                          propValue + ", " + propvalue?.id;
                                      }
                                    }
                                  });
                                } else {
                                  flag = true;
                                }

                                ///message
                                if (!flag) {
                                  //if (property?.isRequired) {
                                  errorObj.push({
                                    ErrorType: "Wrong Value",
                                    Error: jData[property.displayName],
                                    Row: parseInt(iRow, 10) + 1,
                                    Column: property.displayName,
                                  });

                                  if (optionValueMissing.length) {
                                    optionValueMissing =
                                      optionValueMissing +
                                      "<br/> Row No: " +
                                      (parseInt(iRow, 10) + 1) +
                                      "  Column " +
                                      prop +
                                      "  " +
                                      lst[i] +
                                      " option(s) are wrong ";
                                  } else {
                                    optionValueMissing =
                                      "Row No: " +
                                      (parseInt(iRow, 10) + 1) +
                                      "  Column " +
                                      prop +
                                      "  " +
                                      lst[i] +
                                      " option(s) are wrong ";
                                  }
                                  //}
                                }
                                ///end Message
                              }
                              const str = "{" + propValue + "}";
                              sData[portfColumn] = str.replace(/"/g, "");
                              //sData[transformText(prop, "camel")]=str.replace(/"/g, "");
                            } else {
                              flag = true;
                            }

                            console.log("object ss", sData);
                          }
                        } else {
                          //Text and Nemeric

                          //sData[prop] = jData[prop];
                          portfolioValues?.portfolioSections.map(
                            (section, index) => {
                              section?.properties?.length > 0 &&
                                section?.properties?.map((property, index) => {
                                  if (prop === property.displayName) {
                                    if (property?.type === "Numeric") {
                                      if (jData[prop] == "") {
                                        if (property.isRequired) {
                                          //Error
                                        } else {
                                          sData[portfColumn] = "0";
                                          //sData[transformText(prop, "camel")] = "0";
                                        }
                                      } else {
                                        sData[portfColumn] = jData[prop];
                                        //sData[transformText(prop, "camel")] = jData[prop];
                                      }
                                    } else {
                                      sData[portfColumn] = jData[prop];
                                      //sData[transformText(prop, "camel")] = jData[prop];
                                    }
                                  }
                                });
                            }
                          );
                        }
                      }
                    });
                });
              }
            });
            //Only for integration start
            const isFoundIntegrationSource = exportWitStatic.includes(moduleNameNew?.moduleName) ? 1 : 0;
            if (isFoundIntegrationSource && moduleNameNew?.moduleName === "integration") {
              sData["Source App Name"] = jData["Source App Name"];
              sData["Target App Name"] = jData["Target App Name"];
            }
            //Only for integration end
            arrData.push(sData);
          });
        } else {
          let sData = {};
          columns.map((prop, colIndex) => {
            sData[prop] = "";
          });
          arrData.push(sData);
        }

         
        setErrorData(errorObj);

        //return;

        //if (optionValueMissing == "" && !errorObj.length)
        {
          //Send to API
          console.log("populate Grid");
          const formExcelItem = [];
          let position = 0;
          portfolioValues?.portfolioSections
            .sort((a, b) => a.position - b.position)
            .map((section, index) => {
              //const nodeSection = section.get({ plain: true });
              section?.properties?.length > 0 &&
                section?.properties
                  ?.sort((a, b) => a.position - b.position)
                  .map((property, index) => {
                    property.position = position;
                    formExcelItem.push(property);
                    position++;
                  });
            });

          const objData = {};

          // if (view === "Grid") {
          //   objData["Parent"] = "";
          // }

          formExcelItem.forEach((item) => {
            objData[item.displayName] = "";
          });

          const arrExcelData = [];
          let columns = Object.keys(objData);
          columns = columns?.length ? ["id",...columns] : columns;

          if (jsonData.length) {
            jsonData.map((jData, rowIndex) => {
              let sData = {};
              columns.map((prop, colIndex) => {
                if (jData[prop] === undefined) {
                  sData[prop] = "";
                } else {
                  sData[prop] = jData[prop];
                }
              });
              //Only for integration start
              const isFoundIntegrationSource = exportWitStatic.includes(
                moduleNameNew?.moduleName
              )
                ? 1
                : 0;
              if (
                isFoundIntegrationSource &&
                moduleNameNew?.moduleName === "integration"
              ) {
                sData["Source App Name"] = jData["Source App Name"];
                sData["Target App Name"] = jData["Target App Name"];
              }
              //Only for integration end
              arrExcelData.push(sData);
            });
          } else {
            let sData = {};
            columns.map((prop, colIndex) => {
              sData[prop] = "";
            });
            arrExcelData.push(sData);
          }
          setUploadedData(arrExcelData);
          previousUpdatedItemsRef.current = 0;
          await getPreInsertedData(moduleName,arrExcelData);
        }
        // else {
        //   console.log("Wrong Option ", errorObj);
        // }

        //myresp = { code: 400, message: "Data uploaded", column: "", data: arrData };
        // } else {
        //   //Missing Field
        //   console.log("Columns Missing ", missngField);
        // }
      }else  
      {
        message.error("Portfolio property not found"); 
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const normalizeFileName = (fileName) => {
    // Remove anything in parentheses, and trim leading/trailing whitespace
    return fileName?.replace(/\s*\(.*?\)\s*/, '').trim();
  };

  const onFinishDiagram = async (formData)=>{
    try {
      setLoading(true);

      const filename = formData?.upload_file?.file?.name;
      // Normalizing both filenames for comparison
      const normalizedFilename = normalizeFileName(filename);
      const normalizedDiagramFileName = normalizeFileName(props?.diagramFileName);

      if (normalizedFilename !== normalizedDiagramFileName) {
        setLoading(false);
        message.error(`Invalid file name  file name should be ${props?.diagramFileName} please check your file.`);
        return;
      }

      if (!props?.diagramId) {
        setLoading(false);
        message.error("Diagram not found.");
        return;
      }

      let postData = {};
      postData.image = formData?.upload_file?.file;
      postData.diagramId = props?.diagramId;
      const response = await BpmnServices.SaveImportDiagram(postData);
      if (response?.data?.code === 200) {
        message.success(response?.data?.message,6);
        setLoading(false);
        setOpenPreDefinedFormModal(!openPreDefinedFormModal);
        setOpenUploadProcessModel(!openUploadProcessModel);
        setUpdatedItems([]);
        setPreInsertedItems([]);
        setExcelJsonData([]);
        setDirectImport(false);
        setTimeout(() => {
          props?.showDiagramData(props?.diagramId);
        }, 500);
      }else{
        message.error(response?.data?.message,6);
        setLoading(false);
      }
      
    } catch (err) {
      setLoading(false);
      message.error(err?.response?.data?.message || err?.message,6);
    }
  }

  // const uploadData = async () => {
  //   if (formModalData) {
  //       setOpenUploadProcessModel(!openUploadProcessModel);
  //     let mmm = "";
  //     // if (moduleName == "BusinessApplication") {
  //     //   mmm = "Application";
  //     //   setModel("Application");
  //     // } else if (moduleName == "BusinessCapability") {
  //     //   mmm = "Business Capability";
  //     //   setModel("Business Capability");
  //     // } else if (moduleName == "BusinessProcesses") {
  //     //   mmm = "Business Process";
  //     //   setModel("Business Process");
  //     // }

  //     if (errorData.length) {
  //       message.error(
  //         "Error found in uploaded data, Clear the errors in the Excel file and upload again."
  //       );

  //       return;
  //     }

  //     const res = await PortfolioSettingServices.getList({
  //       page: 1,
  //       limit: 100,
  //     });
  //     const newData = res.data.data;

  //     const moduleNameNew = newData.find(
  //       (item) => transformText(item.moduleName, "pascal") == moduleName
  //     ); //BusinessApplication

  //     const isFound = exportWitStatic.includes(moduleNameNew?.moduleName) ? 1 : 0;
  //     const preInsertedItemsData = [];
  //     const findDisplayNameValue = formItems?.find(
  //       (item) => item?.name === "displayname"
  //     )?.displayName;
  //     if (
  //       formItems?.length > 0 &&
  //       findDisplayNameValue &&
  //       preInsertedItems?.length > 0
  //     ) {
  //       updatedItems.map((uploadedItem) => {
  //         // Find the corresponding preInsertedItem with the same Display name
  //         // const preInsertedItem = preInsertedItems.find(
  //         //   (item) =>
  //         //     item[findDisplayNameValue] === uploadedItem[findDisplayNameValue]
  //         // );

  //         // if (preInsertedItem) {
  //           preInsertedItemsData.push({
  //             [findDisplayNameValue]: uploadedItem[findDisplayNameValue],
  //             importStatus: uploadedItem?.importStatus,
  //           });
  //         // }
  //       });
  //     }

  //     let postData = {};
  //     postData.image = formModalData?.upload_file?.file;
  //     postData.modelname = moduleNameNew.name;
  //     postData.tableName = moduleName;
  //     postData.view = view;
  //     postData.preInsertedItems = JSON.stringify(preInsertedItemsData)
  //     postData.unknownSource = isFound;
      
  //     DataImportScenarioServices.SaveImportMasters_AllPortfolio(postData)
  //       .then(async (response) => {
  //         if (response.data.code === 200) {
  //           message.success("Uploaded successfully.",6);

  //           setOpenPreDefinedFormModal(!openPreDefinedFormModal);
  //           setOpenUploadProcessModel(!openUploadProcessModel);

  //           setUpdatedItems([]);
  //           setPreInsertedItems([]);
  //           setExcelJsonData([]);
  //           setDirectImport(false);

  //           if (!directImport) {
  //             setPreviewDataModal(!previewDataModal);
  //           }

  //           form.resetFields();
  //           // const getSortValue = portfolioSortingValues(moduleName);
  //           await PortfolioSettingServices?.getFilteredPortfolios(
  //             {moduleName: moduleNameNew?.moduleName,sortBy:"displayname,ASC"},
  //             1,
  //             100
  //           );
  //         } else {
  //           // const msg = response.data.message + "  " + response.data.column;
  //           //message.error(msg);
  //           message.error(response.data.message,6);

  //           setOpenPreDefinedFormModal(false);
  //           setOpenUploadProcessModel(false);
  //         }
  //         setLoading(false);
  //       })
  //       .catch((err) => {
  //         message.error(err?.response?.data?.message || err?.message,6);
  //           setOpenPreDefinedFormModal(false);
  //           setOpenUploadProcessModel(false);
  //         setLoading(false);
  //       });
  //       setDirectImport(false);
  //   }
  // };
  const uploadData = async () => {
    try {
      if (formModalData) {
        setOpenUploadProcessModel(!openUploadProcessModel);
        let mmm = "";

        if (errorData.length) {
          message.error(
            "Error found in uploaded data, Clear the errors in the Excel file and upload again."
          );
          return;
        }

        const res = await PortfolioSettingServices.getList({
          page: 1,
          limit: 100,
        });
        const newData = res.data.data;

        const moduleNameNew = newData.find(
          (item) => transformText(item.moduleName, "pascal") == moduleName
        );

        const isFound = exportWitStatic.includes(moduleNameNew?.moduleName)
          ? 1
          : 0;
        const preInsertedItemsData = [];
        const findDisplayNameValue = formItems?.find(
          (item) => item?.name === "displayname"
        )?.displayName;

        if (
          formItems?.length > 0 &&
          findDisplayNameValue &&
          preInsertedItems?.length > 0
        ) {
          updatedItems.map((uploadedItem) => {
            preInsertedItemsData.push({
              [findDisplayNameValue]: uploadedItem[findDisplayNameValue],
              importStatus: uploadedItem?.importStatus,
            });
          });
        }

        let postData = {};
        postData.image = formModalData?.upload_file?.file;
        postData.modelname = moduleNameNew.name;
        postData.tableName = moduleName;
        postData.view = view;
        postData.preInsertedItems = JSON.stringify(preInsertedItemsData);
        postData.unknownSource = isFound;

        const response =
          await DataImportScenarioServices.SaveImportMasters_AllPortfolio(
            postData
          );

        if (response.data.code === 200) {
          message.success("Uploaded successfully.", 6);

          setOpenPreDefinedFormModal(!openPreDefinedFormModal);
          setOpenUploadProcessModel(!openUploadProcessModel);

          setUpdatedItems([]);
          setPreInsertedItems([]);
          setExcelJsonData([]);
          setDirectImport(false);

          if (!directImport) {
            setPreviewDataModal(!previewDataModal);
          }

          form.resetFields();

          await PortfolioSettingServices.getFilteredPortfolios(
            {
              moduleName: moduleNameNew?.moduleName,
              sortBy: "displayname,ASC",
            },
            1,
            100
          );
        } else {
          message.error(response.data.message, 6);
          setOpenPreDefinedFormModal(false);
          setOpenUploadProcessModel(false);
        }
        setLoading(false);
      }
    } catch (err) {
      message.error(err?.response?.data?.message || err?.response?.data?.error || err?.message, 6);
      setOpenPreDefinedFormModal(false);
      setOpenUploadProcessModel(false);
      setLoading(false);
    } finally {
      setDirectImport(false);
    }
  };
  


  const checkAndTagChanges = (uploadedData, preInsertedItems) => { 
    const formatValue = (value) =>
      value
        ? value
            .toString()
            .trim()
            .toLowerCase()
            .replace(/[^a-zA-Z0-9]/g, "")
        : "";

    const findDisplayNameValue = formItems?.find(
      (item) => item?.name === "displayname"
    )?.displayName;

    if (
      formItems?.length > 0 &&
      findDisplayNameValue &&
      uploadedData?.length > 0
    ) {
      let newUpdatedData = uploadedData?.map((uploadedItem) => {

        const preInsertedItem = preInsertedItems.find((item) => {
          const preInsertedDisplayName = formatValue(
            item[findDisplayNameValue]
          );
          const uploadedDisplayName = formatValue(
            uploadedItem[findDisplayNameValue]
          );
          return preInsertedDisplayName === uploadedDisplayName;
        });

        if (!preInsertedItem) {
          // If there's no matching preInsertedItem, tag all keys as "New"
          return {
            ...uploadedItem,
            importStatus: uploadedItem?.id ? "Modified" : "New",
          };
        } else {
          // If there is a matching preInsertedItem, check each key
          let isModified = false;
          const result = { ...uploadedItem };

          Object.keys(uploadedItem)?.forEach((key) => {
            if (key === findDisplayNameValue || key === "id") return; // Skip display name comparison

            const uploadedValue = formatValue(uploadedItem[key]);
            const preInsertedValue = formatValue(preInsertedItem[key]);

            // If values differ, mark as modified
            if (uploadedValue !== preInsertedValue) {
              isModified = true;
            }
          });

          // Set importStatus based on modification
          result.importStatus = isModified ? "Modified" : "Old";
          return result;
        }
      });

      //Check relation ship data
      const checkRelationShip = newUpdatedData?.filter(
        (item) => item?.importStatus === "Old"
      );
      const filteredRelations = excelJsonData
        .filter((item2) =>
          checkRelationShip.some(
            (item1) =>
              item1[findDisplayNameValue] === item2[findDisplayNameValue]
          )
        )
        ?.map(({ id, ...rest }) => rest);

      const updatedRelationStatus = filteredRelations?.map((uploadedItem) => {
        const preInsertedItem = preInsertedItems.find((item) => {
          const preInsertedDisplayName = formatValue(
            item[findDisplayNameValue]
          );
          const uploadedDisplayName = formatValue(
            uploadedItem[findDisplayNameValue]
          );
          return preInsertedDisplayName === uploadedDisplayName;
        });

        if (!preInsertedItem) {
          // If there's no matching preInsertedItem, tag all keys as "New"
          return {
            ...uploadedItem,
          };
        } else {
          // If there is a matching preInsertedItem, check each key
          let isModified = false;
          const result = {...uploadedItem};

          Object.keys(uploadedItem)?.forEach((key) => {
            if (key === findDisplayNameValue || key === "id") return;

            const uploadedValue = formatValue(uploadedItem[key]);
            const preInsertedValue = formatValue(preInsertedItem[key]);

            if (uploadedValue !== preInsertedValue) {
              isModified = true;
            }
          });

          result.importStatus = isModified ? "Modified" : "Old";
          return result;
        }
      });
      newUpdatedData = newUpdatedData?.map((newItem) => {
        const updatedRelation = updatedRelationStatus.find((item) => {
          const preInsertedDisplayName = formatValue(item[findDisplayNameValue]);
          const uploadedDisplayName = formatValue(newItem[findDisplayNameValue]);
          return preInsertedDisplayName === uploadedDisplayName;
        });

        return {
          ...newItem,
          importStatus: updatedRelation
            ? updatedRelation?.importStatus
            : newItem.importStatus,
        };
      });
      
      return (uploadedData = newUpdatedData);
    } else {
      return [];
    }
  };

  function getStatusColor(status) {
    const statusColor = statusColors.find(item => item.status === status);
    if (statusColor) {
        return { bgColor: statusColor.bgColor, textColor: statusColor.textColor,noBgColor: statusColor.noBgColor };
    } else {
        return { bgColor: "#FFFFFF", textColor: "#000000",noBgColor: "#000000"}; // Default colors
    }
}

  const TableBody = ({ updatedItems = [] }) => {
    let checkFields = updatedItems;

    if (checkFields?.length > 0) {
      return (
        <>
          {/* Header Row */}
          {checkFields?.map((trData, trIndex) => {
            return (
              <tr key={Math.random()}>
                {Object.keys(trData)?.map((tdData, tdIndex) => {
                  {
                    if (trIndex === 0) {
                      return <th key={Math.random()}>{tdData}</th>;
                    }
                  }
                })}
              </tr>
            );
          })}
          {/* Data Rows */}
          {checkFields?.map((trData, trIndex) => {
            return (
              <tr key={Math.random()}>
                {Object.keys(trData)?.map((tdData, tdIndex) => {
                  const { bgColor, textColor, noBgColor } = getStatusColor(
                    trData?.importStatus
                  );
                  if (
                    // errorCells.find(
                    //   (f) => f[0] === trIndex && f[1] === tdIndex)
                    (!trData[tdData] &&
                      errorData.find(function (error) {
                        return (
                          error.Column == tdData &&
                          error.ErrorType == "Missing Field"
                        );
                      })) ||
                    (!trData[tdData] &&
                      errorData.find(function (error) {
                        return (
                          error.Column == tdData &&
                          error.ErrorType == "Required Field"
                        );
                      })) ||
                    (trData[tdData] &&
                      errorData.find(function (error) {
                        return (
                          error.Column == tdData &&
                          error.ErrorType == "Wrong Value" &&
                          trData[tdData] == error.Error
                        );
                      }))
                  ) {
                    return (
                      <td
                        key={Math.random()}
                        className="error-cell"
                        bordercolor={`red`}
                      >
                        {trData[tdData]}
                      </td>
                    );
                  } else {
                    if (trIndex === 0) {
                      if (tdData === "importStatus") {
                        return (
                          <td key={Math.random()} style={{ color: noBgColor }}>
                            {trData[tdData]}
                          </td>
                        );
                      }
                      return <td key={Math.random()}>{trData[tdData]}</td>;
                    } else {
                      if (tdData === "importStatus") {
                        return (
                          <td key={Math.random()} style={{ color: noBgColor }}>
                            {trData[tdData]}
                          </td>
                        );
                      }
                      return <td key={Math.random()}>{trData[tdData]}</td>;
                    }
                  }
                })}
              </tr>
            );
          })}
        </>
      );
    } else {
      return (
        <>
          {/* Fallback Header Row */}
          {uploadedData.length > 0 && (
            <tr>
              {Object.keys(uploadedData[0]).map((key) => (
                <th key={key}>{key}</th>
              ))}
            </tr>
          )}
          {/* Fallback Message */}
          <tr className="mt-2">
            <td
              colSpan={Object.keys(uploadedData[0] || {}).length}
              style={{ borderColor:"gray", color: "red" }}
            >
              New or Modified records not found.
            </td>
          </tr>
        </>
      );
    }
  };
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const onValuesChange = (changedValues, allValues) => {
    if (allValues.upload_file) {
      setIsFileUploaded(allValues.upload_file.fileList.length > 0);
    } else {
      setIsFileUploaded(false);
    }
  };

  const handleCancel = () => {
    form.resetFields(); 
  };

  return (
    <>
      <Modal
        open={props?.open}
        title={`Import ${props?.name}`}
        onCancel={() => {
          handleCancel();

          props.setOpen(false);
        }}
        okButtonProps={{ style: { backgroundColor: "#9ba894 !important" } }}
        footer={false}
        width={`400px`}
        maskClosable={false}
      >
        <div className="text-center mt-5">
          <Button
            type={`primary`}
            onClick={async () => {
              // await getPreInsertedData(moduleName);
              form.resetFields();
              setLoading(false);
              setOpenPreDefinedFormModal(!openPreDefinedFormModal);
              setUploadedData([]);
              setPreInsertedItems([]);
              setExcelJsonData([]);
              setUpdatedItems([]);
              setIsFileUploaded(false);
              setDirectImport(false);
              previousUpdatedItemsRef.current = 0;
              setOpenUploadProcessModel(false);
              props.setOpen(false);
            }}
          >
            Import Pre-Defined File Format
          </Button>
          {/* <div className='text-center my-3'>OR</div>
                <Button type={`primary`} onClick={()=>{setOpenOwnFormatFormModal(!openOwnFormatFormModal);props.setOpen(false);}}>Import Your Own Format</Button> */}
        </div>
      </Modal>

      <Modal
        open={openPreDefinedFormModal}
        title={`Import Pre-Defined File Format`}
        footer={false}
        onCancel={() => {
          handleCancel();
          setOpenPreDefinedFormModal(!openPreDefinedFormModal);
        }}
        maskClosable={false}
      >
        <Form
          layout="vertical"
          onFinish={!isDiagram ? onFinish : onFinishDiagram}
          form={form}
          onValuesChange={onValuesChange}
        >
          <Form.Item
            name="upload_file"
            rules={[{ required: true, message: "Please Upload Excel File" }]}
          >
            <Upload.Dragger {...uploadFile} ref={uploadRef}>
              <p className="ant-upload-text text-center">
                Drag and Drop Your File
              </p>
              <p className=" text-center my-2">Or</p>
              <p className="btn btn-outline-primary text-center">Choose File</p>
            </Upload.Dragger>
          </Form.Item>
          {!isDiagram ? (
            <>
              <div className="text-center my-2">
                <div className="flex items-center justify-center gap-2">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="text-center"
                    onClick={() => {
                      // setPreviewDataModal(!previewDataModal);
                      setDirectImport(false);
                      props.setOpen(false);
                      setOpenUploadProcessModel(false);
                    }}
                    disabled={!isFileUploaded || loading}
                  >
                    Preview
                  </Button>

                  <Button
                    type="primary"
                    htmlType="submit"
                    className="text-center"
                    disabled={
                      !isFileUploaded || loading || openUploadProcessModel
                    }
                    style={{
                      backgroundColor:
                        !isFileUploaded || loading || openUploadProcessModel
                          ? "#f5f5f5"
                          : "#52c41a", // Light gray for disabled, green for enabled
                      borderColor:
                        !isFileUploaded || loading || openUploadProcessModel
                          ? "#dddddd"
                          : "#52c41a",
                    }}
                    onClick={() => {
                      setDirectImport(true);
                    }}
                  >
                    {openUploadProcessModel ? <Spin /> : "Import"}
                  </Button>
                </div>
                {loading && (
                  <div style={{ marginTop: 10 }}>
                    <Spin indicator={antIcon} />
                    <span style={{ marginLeft: 10 }}>
                      {"Fetching your details..."}
                    </span>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="text-center my-2">
                <div className="flex items-center justify-center gap-2">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="text-center"
                    onClick={() => {
                      props.setOpen(false);
                      setOpenUploadProcessModel(false);
                    }}
                    disabled={!isFileUploaded || loading}
                  >
                  {loading ? <Spin /> :  `Import ${props.name}`}
                  </Button>
                </div>
              </div>
            </>
          )}
        </Form>
        {!isDiagram ? (
          <>
            <p className="mb-0">Don't have import file format?</p>
            <Button
              type="text"
              onClick={() => {
                // downloadExcel([templateData?.data?.data], "Business Capability");
                downloadExcelTemplate();
              }}
              className="text-primary"
            >
              Click here to download sample file
            </Button>
          </>
        ) : (
          <></>
        )}
      </Modal>

      {/* Preview Data Model  */}
      <Modal
        open={previewDataModal}
        title={`${model} Import Preview`}
        footer={false}
        onCancel={() => {
          setPreviewDataModal(!previewDataModal);
        }}
        okButtonProps={{ style: { backgroundColor: "#9ba894 !important" } }}
        width={"80%"}
        maskClosable={false}
      >
        <div
          className="table-responsive mt-4"
          style={{ height: 300, overflow: "scroll" }}
        >
          <table
            width={`100%`}
            className="table table-sm cu-table-bordered excel-list "
            cellSpacing={1}
          >
            {/* New table body */}
            {<TableBody updatedItems={updatedItems?.filter((item) => item.importStatus !== "Old") || []} />}
            {/* old table body start*/}
            {/* {uploadedData?.map((trData, trIndex) => {
              return (
                <tr key={Math.random()}>
                  {Object.keys(trData)?.map((tdData, tdIndex) => {
                    {
                      if (trIndex === 0) {
                        return <th key={Math.random()}>{tdData}</th>;
                      }
                    }
                  })}
                </tr>
              );
            })}
            {uploadedData?.map((trData, trIndex) => {
 
              return (
                <tr key={Math.random()}>
                  {Object.keys(trData)?.map((tdData, tdIndex) => {
                    if (
                      // errorCells.find(
                      //   (f) => f[0] === trIndex && f[1] === tdIndex)
                      (!trData[tdData] &&
                        errorData.find(function (error) {
                          return (
                            error.Column == tdData &&
                            error.ErrorType == "Missing Field"
                          );
                        })) ||
                      (!trData[tdData] &&
                        errorData.find(function (error) {
                          return (
                            error.Column == tdData &&
                            error.ErrorType == "Required Field"
                          );
                        })) ||
                      (trData[tdData] &&
                        errorData.find(function (error) {
                          return (
                            error.Column == tdData &&
                            error.ErrorType == "Wrong Value" &&
                            trData[tdData] == error.Error
                          );
                        }))
                    ) {
                      return (
                        <td
                          key={Math.random()}
                          className="error-cell"
                          bordercolor={`red`}
                        >
                          {trData[tdData]}
                        </td>
                      );
                    } else {
                      if (trIndex === 0) {
                        return <td key={Math.random()}>{trData[tdData]}</td>;
                      } else {
                        return <td key={Math.random()}>{trData[tdData]}</td>;
                      }
                    }
                  })}
                </tr>
              );
            })} */}
            {/* old table body end*/}
          </table>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            {errorData.length > 0 && (
              <>
                <h5>
                  <span className="text-danger">
                    Error: {errorData.length} error(s) found
                  </span>
                </h5>
                <span className="text-danger">
                  Clear the errors in the Excel file and upload again.
                </span>
              </>
            )}
            <div
              className="table-responsive mt-4"
              style={{ height: 100, overflow: "auto" }}
            >
              <table
                width={`100%`}
                className="table table-sm cu-table-bordered excel-list"
                cellSpacing={1}
              >
                {errorData?.map((trData, trIndex) => {
                  return (
                    <tr key={Math.random()}>
                      {Object.keys(trData)?.map((tdData, tdIndex) => {
                        {
                          if (trIndex === 0) {
                            return <th key={Math.random()}>{tdData}</th>;
                          }
                        }
                      })}
                    </tr>
                  );
                })}

                {errorData?.map((trData, trIndex) => {
                  return (
                    <tr key={Math.random()}>
                      {Object.keys(trData)?.map((tdData, tdIndex) => {
                        // if (

                        //   (!trData[tdData] &&
                        //     errorData.find(function (error) {
                        //       return (
                        //         error.Error == tdData &&
                        //         error.ErrorType == "MissingField"
                        //       );
                        //     })) ||
                        //   (trData[tdData] &&
                        //     errorData.find(function (error) {
                        //       return (
                        //         error.column == tdData &&
                        //         error.ErrorType == "Wrong Value" &&
                        //         trData[tdData] == error.Error
                        //       );
                        //     }))
                        // )
                        // {
                        //   return (
                        //     <td
                        //       key={Math.random()}
                        //       className="error-cell"
                        //       bordercolor={`red`}
                        //     >
                        //       {trData[tdData]}
                        //     </td>
                        //   );
                        // } else
                        {
                          if (trIndex === 0) {
                            return (
                              <td key={Math.random()}>{trData[tdData]}</td>
                            );
                          } else {
                            return (
                              <td key={Math.random()}>{trData[tdData]}</td>
                            );
                          }
                        }
                      })}
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
          <div style={{ marginTop: "10px" }}>
            {/* <Button onClick={uploadData}>Finish Import</Button> */}
            <Button
              onClick={uploadData}
              disabled={openUploadProcessModel || updatedItems?.length === 0 || updatedItems?.filter((item) => item.importStatus !== "Old")?.length === 0}
            >
              {" "}
              {openUploadProcessModel ? <Spin /> : "Finish Import"}
            </Button>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            {/* <Text>
              <Text strong>Note:</Text> Only items with the status{" "}
              <Text
                style={{ color: `${getStatusColor("Modified").noBgColor}` }}
                strong
              >
                Modified
              </Text>{" "}
              and{" "}
              <Text
                style={{ color: `${getStatusColor("New").noBgColor}` }}
                strong
              >
                New
              </Text>{" "}
              will be imported; items with the status{" "}
              <Text
                style={{ color: `${getStatusColor("Old").noBgColor}` }}
                strong
              >
                Old
              </Text>{" "}
              will be disregarded.
            </Text> */}
            {/* <br /> */}
            <Text className="text-info">
              <Text strong>Note:</Text> New or modified relationship changes
              will not be shown here.
            </Text>
          </div>
        </div>
      </Modal>

      {/* Own File Format */}
      <Modal
        open={openOwnFormatFormModal}
        title={`Import Your Own Format`}
        footer={false}
        onCancel={() => {
          setOpenOwnFormatFormModal(!openOwnFormatFormModal);
        }}
        maskClosable={false}
      >
        <Upload.Dragger {...props}>
          <p className="ant-upload-text text-center">Drag and Drop Your File</p>
          <p className=" text-center my-2">Or</p>
          <p className="btn btn-outline-primary text-center">Choose File</p>
        </Upload.Dragger>
        <div className="text-center my-2">
          <Button
            type="primary"
            className="text-center px-5"
            onClick={() => {
              setOpenOwnFormatFormMappingModal(!openOwnFormatFormMappingModal);
              setOpenOwnFormatFormModal(!openOwnFormatFormModal);
            }}
          >
            Next
          </Button>
        </div>
      </Modal>

      <Modal
        open={openOwnFormatFormMappingModal}
        title={`Import Your own File Format`}
        footer={false}
        onCancel={() => {
          setOpenOwnFormatFormMappingModal(!openOwnFormatFormMappingModal);
        }}
        okButtonProps={{ style: { backgroundColor: "#9ba894 !important" } }}
        maskClosable={false}
      >
        <div>
          <Select
            options={[{ value: "sample", label: <span>sample</span> }]}
            defaultValue={`Sample`}
            placeholder={`Select`}
            style={{ width: "100%" }}
          ></Select>
        </div>
        <div className="my-3 text-center">OR</div>
        <div className={`bg-efefef p-2`}>
          <table width={`100%`}>
            <tr>
              <th width={`50%`}>File Column</th>
              <th className="text-right">Mapped Column</th>
            </tr>
            <tr>
              <td>Col A-Name</td>
              <td>
                <Select
                  options={[
                    {
                      value: "Application Name",
                      label: <span>Application Name</span>,
                    },
                  ]}
                  defaultValue={`Application Name`}
                  placeholder={`Select`}
                  style={{ width: "100%" }}
                ></Select>
              </td>
            </tr>
            <tr>
              <td>Col B-Comment</td>
              <td>
                <Select
                  options={[{ value: "Ignore", label: <span>Ignore</span> }]}
                  defaultValue={`Ignore`}
                  placeholder={`Select`}
                  style={{ width: "100%" }}
                ></Select>
              </td>
            </tr>
            <tr>
              <td>Col C-Id</td>
              <td>
                <Select
                  options={[{ value: "App Id", label: <span>App Id</span> }]}
                  defaultValue={`App Id`}
                  placeholder={`Select`}
                  style={{ width: "100%" }}
                ></Select>
              </td>
            </tr>
            <tr>
              <td>Col D-Description</td>
              <td>
                <Select
                  options={[
                    { value: "Description", label: <span>Description</span> },
                  ]}
                  defaultValue={`Description`}
                  placeholder={`Select`}
                  style={{ width: "100%" }}
                ></Select>
              </td>
            </tr>
          </table>
        </div>
        <Button
          type={`primary`}
          className="w-100 mt-2"
          onClick={() => {
            setOpenOwnFormatFormMappingModal(!openOwnFormatFormMappingModal);
            setPreviewDataModal(!previewDataModal);
          }}
        >
          Proceed
        </Button>
      </Modal>
    </>
  );
};

export default ImportApplicationData;
