import { DownloadOutlined, FieldTimeOutlined, SearchOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Flex, Form, Input, Layout, message, Select, Space, Spin, Table, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import BusinessProcessHeader from '../../businessProcess/BusinessProcessHeader';
import LoadingBar from 'react-top-loading-bar';
import { default_pageSize, default_pageSizeOptions, removeEmptyArrObjOrval, removeEmptyKeys } from '../../../utils/Common';
import { getMenuPortfolio } from '../../../utils/settingCommon';
import moment from 'moment';
import TextWithTooltip from '../../../utils/TextWithTooltip';
import PortfolioSettingServices from '../../../services/services/PortfolioSettingsService';

const SummaryReportList = ({Buttons,moduleName,...props}) => {
    const ref = useRef(null);
    const portfolioData = getMenuPortfolio({moduleName});
    const [loading,setLoading] = useState(false);
    const [totalRecords,setTotalRecords] = useState(0);
    const [records,setRecords] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(default_pageSize);
    const [recordFilter, setRecordFilter] = useState({});
    const [capabilityOptions,setCapabiltyOptions] = useState([]);
    const [capabiltyPage, setCapabiltyPage] = useState(1);

    useEffect(()=>{
        fetchCapablityData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(()=>{
        fetchFilteredData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[recordFilter]);

    const columns = [
        {
            title: 'Report Name',
            dataIndex: 'name',
            key: 'name',
        },
		{
            title: 'Business Capability',
            dataIndex: 'businessCapabilities',
            key: 'businessCapabilities',
            className:`cu-text-prewrap`,
            render : (businessCapabilities,data) =>{
                return businessCapabilities?.map(item=>item?.displayname).join(",\n")
            }
        },
		{
            title: 'Generated At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            className:'text-center',
            render:(value,data) =>{
                if(value){
                    return <>{moment(value).format('DD-MM-YYYY')}</>
                }
            }
        },
		{
            title: 'Requested By',
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: 'Action',
            dataIndex: 'filePath',
            key: 'filePath',
            width:200,
            className:'text-center',
            render : (value,data) =>{
                if(value){
                    return <Flex gap={8} align='center' justify='center'>
                        <Tooltip title="Download">
                            <Button type="primary" href={value} shape="round" size={`small`} icon={<DownloadOutlined />}></Button>
                        </Tooltip>
                        <Tooltip title="Regenerate">
                            <Button type="primary" shape="round" size={`small`} icon={<SyncOutlined />} onClick={()=> { onRegenerate(data); }}></Button>
                        </Tooltip>
                    </Flex>;
                } else if(data?.status==="started"){
                    return <Tooltip title="In-Progress">
                        <Button type="primary" shape="round" size={`small`} loading style={{ background: "#ffc107", borderColor: "#ffc107" }}></Button>
                    </Tooltip>
                } else if(data?.status==="pending"){
                    return <Tooltip title="Pending">
                        <Button type="primary" shape="round" size={`small`} icon={<FieldTimeOutlined />} style={{ background: "#15B392", borderColor: "#15B392" }}></Button>
                    </Tooltip>
                }
            }
        },
    ];

    const fetchFilteredData = async (pageNumber=page,pageLimit=limit) => {
        try {
            ref?.current?.continuousStart();
            let filter={
                filter:recordFilter,
            };
            filter  = removeEmptyKeys(recordFilter);
            portfolioData?.services?.getPortfolioSummaryReportData(filter,pageNumber,pageLimit)?.then(async (response)=>{
                if(response?.data?.data){
                    setRecords(response?.data?.data || []);
                    setPage(response?.data?.currentPage? Number(response?.data?.currentPage) : 1);
                    setTotalRecords(response?.data?.totalCount? Number(response?.data?.totalCount) : 0);
                }
            })?.catch(error=>{
                console.log("error:",error);
            });
        } catch (error) {
            console.error("Error fetching data:", error);
            ref?.current?.complete()
            setLoading(false);
        } finally {
            setLoading(false);
            ref?.current?.complete()
        }
    };

    const onFinishSearch = (searchData) => {
        searchData.capability = capabilityOptions.filter(f=>searchData?.capability?.includes(f?.id))?.map(f=>f.displayname);
        if(searchData?.capability?.length===0){
            delete searchData.capability;
        }
        setRecordFilter(searchData);
    }

    const onRegenerate =(formData) => {
        let userData = JSON?.parse(localStorage?.getItem('userData'))
        try {
            formData={
                "modelName": portfolioData?.name,
                "tableName": portfolioData?.routeModuleName,
                "type": "summary",
                "isDataExport": 1,
                "userData": userData,
                "jobId": null,
                "dataids":formData?.dataids,
                "name":formData?.name,
                // ...formData,
            }
            if(formData?.dataids?.length===0){
                return message.error(`${portfolioData?.name} is required`)
            }
            portfolioData
            ?.services
            ?.getPortfolioSummaryReport(formData)
            ?.then(response=>{
                if(response?.data?.code===200){
                    message.success(`Report regenerated successfully.`);
                    fetchFilteredData();
                }else{
                    message.error(`Failed to generate to successfully.Please try again.`)
                }
            }).catch(error=>{
                console.log("error",error);
                message.error(`Failed to generate to successfully.Please try again.`)
            }).finally((response) => {
                console.log('Experiment completed');
            });
        } catch (error) {
            console.log("error",error);
        }
    }

    const onCapabiltyClear = () => {
        setCapabiltyPage(0)
        fetchCapablityData({name:""});
    }

    var timeout = null;
    const onCapabiltySearch = (value) => {
        setCapabiltyPage(0);
        if (timeout) {  
            clearTimeout(timeout);
        }
        timeout = setTimeout(function() {
            fetchCapablityData({name:value});
        }, 2000);
    }

    const fetchCapablityData = ({ name=""} = {}) => {
        try {
            if (portfolioData?.moduleName && !loading) {
                setLoading(true);
                let updatedFilter = {
                    moduleName: portfolioData?.moduleName || "",
                    sortBy: "displayname,ASC",
                    name,
                };
                updatedFilter = removeEmptyArrObjOrval(updatedFilter);

                (async ()=>{
                    const response = await PortfolioSettingServices?.getFilteredPortfolios( updatedFilter, capabiltyPage+1, 20);
                    let items = [...new Map([...capabilityOptions,...response?.data?.data].map(item =>[item["id"], item])).values()]
                    if (response?.data?.data?.length>0) {
                        setCapabiltyOptions(items);
                        setCapabiltyPage(Number(response?.data?.currentPage))
                    }
                })()
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally{
            setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <LoadingBar color='#1f1f1f' ref={ref} />
            <Layout className='applicationlist-page'>
                <Layout.Content className='left_pnlbx'>
                    <Spin className="loading_bx" size="small" spinning={loading}>
                        <div className='container-fluid'>
                            <BusinessProcessHeader 
                                activePage={0}
                                setActivePage={()=>{}}
                                heading={"Capability Solution Report"}
                                matrix={false}
                                grid={false}
                                list={false}
                                addBtn={false}
                                bulk_delete={false}
                                showActionBtn={false}
                                Buttons={<Buttons/>}
                            />
                            <Form
                                onFinish={onFinishSearch}
                            >
                                <Flex gap={8} justify='flex-end' align='center' className='my-2'>
                                    <Form.Item name="capability">
                                        <Select
                                            showSearch
                                            allowClear
                                            mode={`multiple`}
                                            onClear={onCapabiltyClear}
                                            placeholder={portfolioData?.name}
                                            filterOption={(inputValue, currOption)=>{
                                                return `${currOption?.option?.displayname || ""} ${currOption?.option?.referenceid || ""}`?.toLowerCase().includes(inputValue?.toLowerCase())
                                            }}
                                            onSearch={onCapabiltySearch}
                                            style={{minWidth:175,maxWidth:350}}
                                            options={
                                                capabilityOptions?.map(option=>{
                                                    return {
                                                        value: Number(option?.id),
                                                        label: <TextWithTooltip text={`${option?.displayname}`} characterLimit={props?.characterLimit} />,
                                                        option,
                                                    }
                                                })
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item name="search">
                                        <Input 
                                            placeholder="Search" 
                                            onChange={(e)=>{  
                                                if(e?.target?.value===""){
                                                    setRecordFilter({
                                                        ...recordFilter,
                                                        search:e?.target?.value
                                                    });
                                                }
                                            }}
                                            style={{width:175}}
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button htmlType='submit' type="primary" icon={<SearchOutlined />}></Button>
                                    </Form.Item>
                                </Flex>
                            </Form>
                            <Table 
                                size="small" 
                                rowKey="id"
                                dataSource={records} 
                                columns={columns} 
                                pagination={{ 
                                    defaultPageSize: limit, 
                                    showSizeChanger: true, 
                                    pageSizeOptions: default_pageSizeOptions,
                                    onChange:(p, ps)=>{
                                        setLimit(ps)
                                        setPage(p);
                                        fetchFilteredData(p,ps);
                                    },
                                    total:totalRecords,
                                    showTotal:(total) => `Total ${total} Items`
                                }}
                            />
                        </div>
                    </Spin>
                </Layout.Content>
            </Layout>
        </React.Fragment>
    )
}

export default SummaryReportList