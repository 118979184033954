import React, { useState, useEffect } from 'react'
import { PageTitleHeading } from '../../utils/Common'
import { Flex, Tabs, message, Button, Modal, Form } from 'antd';

import { Link } from 'react-router-dom';
import { CheckCircleOutlined, CloseCircleOutlined, CheckOutlined, CloseOutlined, EyeOutlined } from '@ant-design/icons';
import NotificationApprovals from './NotificationApprovals';
import NotificationRequest from './NotificationRequest';
import NotificationAlerts from './NotificationAlerts';
import { useNotificationProvider } from '../../Context/NotificationProvider';

import HomePageServices from '../../services/services/HomePageServices';
import FormFields from "../../utils/FormFields";
import ScenarioServices from "../../services/services/ScenarioServices";
import { data } from 'autoprefixer';


const NotificationPage = () => {

    const [activeTabKey, setActiveTabKey] = useState('approvals');
    const [notificationData, setNotificationData] = useState([]);

    const { state: notificatioDataProvider, dispatch: dispatchNotificatioData } = useNotificationProvider();
    const [notificationDataAlert, setNotificationDataAlert] = useState([]);
    const [notificationDataRequest, setNotificationDataRequest] = useState([]);
    const [notificationDataApprovals, setNotificationDataApprovals] = useState([]);

    const [notificationDataAlertUnread, setNotificationDataAlertUnread] = useState([]);
    const [notificationDataRequestUnread, setNotificationDataRequestUnread] = useState([]);
    const [notificationDataApprovalsUnread, setNotificationDataApprovalsUnread] = useState([]);

    const getNotificationData = async () => {

        const email = localStorage.getItem('email');

        const data = {
            "userid": email
        }

        const notificationResult = await HomePageServices.getNotificationData(data);
        if (notificationResult.status === 200) {
            setNotificationData(notificationResult?.data)

            await dispatchNotificatioData({ type: "SET_DATA", payload: notificationResult?.data });
        }
        else {
            message.error(notificationResult.message);
        }
    }


    useEffect(() => {
        getNotificationData();
    }, []);


    useEffect(() => {

        const filteredNotificationsAlert = notificatioDataProvider?.data?.filter(
            (notification) => notification.notificationtype === 'Alert'
        );
        setNotificationDataAlert(filteredNotificationsAlert);

        const alertUnRead = filteredNotificationsAlert?.filter((f) =>
            f.action === "Unread"
        );

        setNotificationDataAlertUnread(alertUnRead);


        const filteredNotificationsRequest = notificatioDataProvider?.data?.filter(
            (notification) => notification.notificationtype === 'Request'
        );
        setNotificationDataRequest(filteredNotificationsRequest);

        const RequestUnRead = filteredNotificationsRequest?.filter((f) =>
            f.action === "Unread"
        );

        setNotificationDataRequestUnread(RequestUnRead);

        const filteredNotificationsApprovals = notificatioDataProvider?.data?.filter(
            (notification) => notification.notificationtype === 'Approvals'
        );
        setNotificationDataApprovals(filteredNotificationsApprovals);

        const ApprovalUnRead = filteredNotificationsApprovals?.filter((f) =>
            f.action === "Unread"
        );

        setNotificationDataApprovalsUnread(ApprovalUnRead);

    }, [notificatioDataProvider]);



    const items = [
        {
            key: 'approvals',
            label: <span>
                Approvals
                {notificationDataApprovalsUnread && notificationDataApprovalsUnread.length &&
                    ( // <span style={{
                    //     width: 20, height: 20, backgroundColor: 'red', borderRadius: 10, color: '#ffffff',
                    //     padding: 2, marginLeft: 5
                    // }}> {notificationDataApprovalsUnread?.length} </span>
                    <div className="bell-icon">
                                    <i className="fas fa-bell"></i>
                                    {notificationDataApprovalsUnread?.length > 0 && (
                                        <span className="notification-badge">{notificationDataApprovalsUnread?.length}</span>
                                    )}
                                </div>
                    ) || ""}
            </span>,
            children: <NotificationApprovals
                tabKey={activeTabKey}
                notificationDataApprovals={notificationDataApprovals}
                getNotificationData={getNotificationData}
            />,
        },
        {
            key: 'request',
            label: <span>
                My Request
                {/* {notificationDataRequestUnread && notificationDataRequestUnread.length &&
                    (<span style={{
                        width: 20, height: 20, backgroundColor: 'red', borderRadius: 10, color: '#ffffff',
                        padding: 2, marginLeft: 5
                    }}>{notificationDataRequestUnread?.length}</span>
                    ) || ""} */}
            </span>,
            children: <NotificationRequest
                tabKey={activeTabKey}
                notificationDataRequest={notificationDataRequest}
            />,
        },
        {
            key: 'alerts',
            label: <span>
                Alerts
                {notificationDataAlertUnread && notificationDataAlertUnread.length &&
                    (  
                    <div className="bell-icon">
                                    <i className="fas fa-bell"></i>
                                    {notificationDataAlertUnread?.length > 0 && (
                                        <span className="notification-badge">{notificationDataAlertUnread?.length}</span>
                                    )}
                                </div>
                    ) || ""}
            </span>,
            children: <NotificationAlerts
                tabKey={activeTabKey}
                notificationDataAlert={notificationDataAlert}
            />,
        },
    ];

    const onChange = (key) => {
        setActiveTabKey(key);
    };



    return (
        <React.Fragment>
            <div className='notifications-page'>
                <PageTitleHeading text={"Notifications"} />
                <Tabs
                    type="card"
                    defaultActiveKey={`approvals`}
                    items={items}
                    className="business-capability-entry home-v2"
                    onChange={onChange}
                />
            </div>
        </React.Fragment>
    )
}

export default NotificationPage;



export const NotificationItemContent = ({ item, getNotificationData, tabKey = '' }) => {

    const [rejectedModel, setRejectedModel] = useState(false);
    const [approvedModel, setApprovedModel] = useState(false);
    const [addApproverBtn, setaddApproverBtn] = useState(false);
    const [sendAction, setSendAction] = useState(false);
    const [rejectAction, setRejectAction] = useState(false);

    const [changeTypeForm] = Form.useForm();

    const [dataItem, setDataItem] = useState({});

    const handleView = async (dataObj) => {
        try {
            //console.log(dataObj);
            if (dataObj.notificationtype != "Request") {
                const paramsObj = { id: dataObj.id };
                const response = await HomePageServices.UpdateNotificationAsRead(
                    paramsObj
                );
            }
        } catch (err) {

        }
    }

    const handleApproval = (item) => {
        setDataItem(item);
        setApprovedModel(true);
    }


    const handleRejection = (item) => {
        setDataItem(item);
        setRejectedModel(true);
    }

    const updateScenarioV2Stage = async (data) => {
        try {
            const url = window.location.href;
            const approvers_emails = [data?.approvers_emails];
            const collaborators_emails = [data?.collaborators_emails];

            const paramsObj = {
                id: data?.scenarioId,
                Status: data?.status1,
                Reason: data?.RejectedReason,
                approverEmail: approvers_emails,
                collaboratorEmail: collaborators_emails,
                pageUrl: url,
                ApproverName: data?.name,
                scenarioName: data?.scenarioName,
            };

            const response = await ScenarioServices.updateScenarioV2LifecycleStage(
                paramsObj
            );

            if (response.data.code === 200) {

                setSendAction(false);
                setRejectAction(false);

                message.success("Updated successfully");
                setRejectedModel(false);
                setApprovedModel(false);
                await getNotificationData();

            } else {
                message.error(response.data.msg);
            }
        } catch (err) { }
    };

    return <>
        <div className='card card-body py-2 mb-2'>
            <Flex gap={2} justify='space-between' align='center'>
                <Flex gap={2} vertical justify='flex-start'>
                    <Flex gap={8} align='center' justify='flex-start'>

                        <Flex gap={8} align='center' justify='flex-start'  >
                            <div className='shortname' style={{ "--bg-color": item?.color }}>{item?.modulename}</div>
                            <div className={item?.action === 'Unread' && 'fw-bold'}>{item?.displayname}</div>
                        </Flex>

                    </Flex>
                    <div className='fw-bold'>{item?.discription}</div>
                    <Flex gap={8} align='center' justify='flex-start'>
                        <div>User: <span >{item?.name}</span></div>
                        <div>Date/Time: <span >{item?.formatted_date} {item?.formatted_time}</span></div>
                        {
                            (item?.notificationtype === "Approvals" || item?.notificationtype === "Request") &&
                            <div>Status: <span className='fw-bold' >{item?.scenariostatus || ""}</span></div>
                        }
                    </Flex>
                </Flex>

                <Flex gap={8}>
                    {(item?.notificationtype === "Approvals" || item?.notificationtype === "Request") && (
                        <Link to={`/scenario-list-v2/${item?.dataid}`} className="link"><Button type="text" onClick={() => handleView(item)} icon={<EyeOutlined style={{ color: "gray", }} />} />  </Link>)
                        || (<Link to={`/portfolio/${item?.modulename}/${item?.dataid}`} state={{ activeTabKey: 'tab_changelogAlert' }} className='link'><Button type="text" onClick={() => handleView(item)} icon={<EyeOutlined style={{ color: "gray", }} />} /> </Link>)}
                    {/* {
                       (item?.scenariostatus==="Approval Pending"   && tabKey === "approvals") && (<> 
                            <Button type="text"   icon={<CheckOutlined style={{ color: "green", }} onClick={()=> handleApproval(item)} />} />
                            <Button type="text"   icon={<CloseOutlined style={{ color: "red", }} onClick={()=>handleRejection(item)} />} />

                        </>)
                    } */}
                </Flex>

            </Flex>
        </div>

        <Modal
            destroyOnClose
            header={false}
            footer={false}
            requiredMark={true}
            maskClosable={false}
            open={rejectedModel}
            onCancel={() => {
                setSendAction(false);
                setRejectAction(false);
                setRejectedModel(false);
            }}
        >
            <Form
                form={changeTypeForm}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 19 }}
                // initialValues={initialValue}
                className="mt-4"
                onFinish={async (formData) => {
                    //Update Rejected
                    const dataParam = {
                        scenarioId: dataItem?.dataid,
                        status1: "Rejected",
                        RejectedReason: formData?.comment,
                        approvers_emails: dataItem?.userid,
                        collaborators_emails: dataItem?.sender,
                        url: "",
                        name: dataItem?.name,
                        scenarioName: dataItem?.displayname,
                    };

                    setRejectAction(true);
                    //updateScenarioV2Stage("Rejected", formData?.comment);
                    updateScenarioV2Stage(dataParam);
                }}
            >
                <FormFields
                    type={`textarea`}
                    label={`Reject Reason`}
                    name={`comment`}
                    formClassName={`mb-2`}
                    rows={3}
                />

                <Button
                    type={`primary`}
                    htmlType={`submit`}
                    className="w-100"
                    loading={rejectAction}
                >
                    Submit
                </Button>
            </Form>
        </Modal>

        <Modal
            destroyOnClose
            header={false}
            footer={false}
            requiredMark={true}
            maskClosable={false}
            open={approvedModel}
            onCancel={() => {
                setApprovedModel(false);
                setSendAction(false);
            }}
        >
            <Form
                form={changeTypeForm}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                // initialValues={initialValue}
                className="mt-4"
                onFinish={async (formData) => {
                    //Update Rejected
                    setSendAction(true);
                    const dataParam = {
                        scenarioId: dataItem?.dataid,
                        status1: "Approved",
                        RejectedReason: formData?.comment,
                        approvers_emails: dataItem?.userid,
                        collaborators_emails: dataItem?.sender,
                        url: "",
                        name: dataItem?.name,
                        scenarioName: dataItem?.displayname,
                    };
                    //updateScenarioV2Stage("Approved", formData?.comment);
                    updateScenarioV2Stage(dataParam);
                }}
            >
                <FormFields
                    type={`textarea`}
                    label={`Remarks`}
                    name={`comment`}
                    formClassName={`mb-2`}
                    rows={3}
                />

                <Button
                    type={`primary`}
                    htmlType={`submit`}
                    className="w-100"
                    loading={sendAction}
                >
                    Submit
                </Button>
            </Form>
        </Modal>
    </>
}