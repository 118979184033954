import React, { useEffect, useState } from 'react';
import { Layout, Col, Row, Button, Flex, Dropdown, Popover } from 'antd';
import './AppHeader.scss';
import { Link } from 'react-router-dom';
import { svgLogo } from '../../../assets/images';
import HeaderMenus from './HeaderMenus';
import HeaderMenusItems from './HeaderMenusItems';
import GlobalSearch from './GlobalSearch';
import "bootstrap";
import UserService from '../../../services/UserService';
import { BellOutlined } from '@ant-design/icons';
import NotificationHeader from './NotificationHeader';
import { useNotificationProvider } from '../../../Context/NotificationProvider';


const { Header } = Layout;

function AppHeader() {

    const [notificationDataUnread, setNotificationDataUnread] = useState([]);

    const { state: notificatioDataProvider, dispatch: dispatchNotificatioData } = useNotificationProvider();

    useEffect(() => {

        const filteredNotificationsAlert = notificatioDataProvider?.data?.filter(
            (notification) => (notification?.notificationtype === 'Alert' || notification?.notificationtype === 'Request')
        );

        const alertUnRead = filteredNotificationsAlert?.filter((f) =>
            f.action === "Unread"
        );

        setNotificationDataUnread(alertUnRead);

    }, [notificatioDataProvider]);

    return (
        <Header className="header">
            <nav className="menuBar">
                <Row>
                    <Col span={4} className="logo">
                        <Link to="/">
                            {/* <img src={svgLogo} alt="logo" /> */}
                        </Link>
                    </Col>
                    <Col span={5}>
                        <GlobalSearch />
                    </Col>
                    <Col flex="none" className='text-center'>
                        {
                            UserService.hasRole(['Admin', 'Editor']) ? "" : <span className='header-blinker' >You have view only access. For elevated access please contact <a href='mailto:saroj.kumar.bisi@volvocars.com??subject=Upgrade CA Tool Access in QA Environment'>support</a></span>
                        }
                    </Col>
                    <Col span={8}>
                        <Flex direction="row" gap={1} justify='flex-end'>
                            <Popover placement="bottomLeft" content={NotificationHeader}>

                                {/* <Button icon={<BellOutlined />} type='text' className='p-2'>
                                    {notificationDataUnread?.length && <span style={{
                                        width: 20, height: 20, backgroundColor: 'red', borderRadius: 10, color: '#ffffff',
                                        padding: 2, marginLeft: 5
                                    }}> {notificationDataUnread?.length} </span> || ""}
                                </Button> */}

                                <div className="bell-icon">
                                    <i className="fas fa-bell"></i>
                                    {notificationDataUnread?.length > 0 && (
                                        <span className="notification-badge">{notificationDataUnread?.length}</span>
                                    )}
                                </div>

                            </Popover>

                            <HeaderMenus menus={HeaderMenusItems} />
                        </Flex>
                    </Col>
                </Row>
            </nav>
        </Header>
    );
}

export default AppHeader;
