import BusinessApplicationServices from "../services/services/BusinessApplicationService";
import CompareScenarioServices from "../services/services/BusinessCapabilityService";
import BusinessCapabilityService from "../services/services/BusinessCapabilityService";
import BusinessProcessServices from "../services/services/BusinessProcessServices";
import CityMapServices from "../services/services/CityMapServices";
import DataImportScenarioServices from "../services/services/DataImportScenarioServices";
import InitiativeServices from "../services/services/InitiativeServices";
import * as DiagramServices from "../services/services/diagramServices";
import IntegrationServices from "../services/services/IntegrationServices";
import LinkService from "../services/services/LinkService";
import LinkTypeService from "../services/services/LinkTypeService";
import PlantServices from "../services/services/PlantServices";
import PortfolioPropertyService from "../services/services/PortfolioPropertyService";
import PortfolioRelationshipService from "../services/services/PortfolioRelationshipService";
import PortfolioSectionService from "../services/services/PortfolioSectionService";
import PortfolioSettingsService from "../services/services/PortfolioSettingsService";
import PortfolioTagService from "../services/services/PortfolioTagService";
import ScenarioItemsService from "../services/services/ScenarioItemsService";
import ScenarioServices from "../services/services/ScenarioServices";
import DataObjectServices from "../services/services/DataObjectServices";
import InformationObjectServices from "../services/services/InformationObjectServices";
import { transformText } from "./Common";
import { portfolioRoute } from "../components/shared/sideBar/SideMenuItems";
import TechnologyServices from "../services/services/TechnologyServices";
import ObjectiveOkrServices from "../services/services/ObjectiveOkrServices";

const staticSettingPortfolioList = {
    application: "Application",
    businessCapability: "Business Capability",
    businessProcess: "Business Process",
    dataObject: "Data Object",
    information: "Information Object",
    initiative: "Initiative",
    integration:"Integration",
    objectiveOKR: "Objective (OKR)",
    organization: "Organization",
    technology: "Technology",
    informationObject: "Information Object",
    plant: "Plant",
};

const staticSettingPortfolioList2  = (type) => {
    // console.log('testing switch modulename', type);
    switch (type) {
        case "Application":
            return "Application";
        case "Business Capability":
            return "Business Capability";
        case "BusinessProcesses":
            return "Business Process";    
        case "Dataobject":
            return "Data Object";
        case "Informationobject":
            return "Information Object";
        case "Initiative":
            return "Initiative";
        case "Integration":
            return "Integration";
        case "ObjectiveOkr":
            return "Objective(OKR)";
        case "Plant":
            return "Organization";
        case "Technology":
            return "Technology";
        case "ApqcsProcesses":
            return "Apqc Process";                      
        default:
            return "";
    }
}   

const Services = {
    BusinessApplication : BusinessApplicationServices,
    BusinessCapability : BusinessCapabilityService,
    BusinessProcesses : BusinessProcessServices,
    CityMap : CityMapServices,
    CompareScenario : CompareScenarioServices,
    DataImportScenario : DataImportScenarioServices,
    Diagram : DiagramServices,
    Initiative : InitiativeServices,
    Integration : IntegrationServices,
    Newintegration : IntegrationServices,
    Link : LinkService,
    LinkType : LinkTypeService,
    Plant : PlantServices,
    PortfolioProperty : PortfolioPropertyService,
    PortfolioRelationship : PortfolioRelationshipService,
    PortfolioSection : PortfolioSectionService,
    PortfolioSettings : PortfolioSettingsService,
    PortfolioTag : PortfolioTagService,
    ScenarioItems : ScenarioItemsService,
    Scenario : ScenarioServices,
    Dataobject : DataObjectServices,
    Informationobject : InformationObjectServices,
    Technology : TechnologyServices,
    ObjectiveOkr :ObjectiveOkrServices
};

const setMenuPortfolio = (array) => {
    let temp = array?.map(item=>{
        let routeModuleName = transformText( item.moduleName.replace("_"," "),"pascal");
        item.routeModuleName = routeModuleName;
        item.portfolioRoute = portfolioRoute({moduleName:item.moduleName});
        item.route = "/"+portfolioRoute({moduleName:item.moduleName})+'/'+routeModuleName;
        return item;
    })
	sessionStorage.setItem("menu-portfolios",JSON.stringify(temp));
}
const getMenuPortfolio = ({moduleName,getNameByRouteModule,routeModuleName,getServicesByRouteModule,getRouteByRouteModule,name}) => {
	
    let portfolios = sessionStorage.getItem("menu-portfolios");
    
   
	portfolios = JSON.parse(portfolios);
 
    portfolios = portfolios?.map(item=>{
        item.services = Services[item.routeModuleName];
        item.portfolioConnected = item?.portfolioConnectedIds?.map(id=>{
            return portfolios.find(f=>f?.id?.toString()===id?.toString());
        }) 
        return item;
    });

    if(getNameByRouteModule){
		return portfolios?.find(f=>f['routeModuleName']===routeModuleName)?.name;
	}
    if(getServicesByRouteModule){
		return portfolios?.find(f=>f['routeModuleName']===routeModuleName)?.services;
	}
    if(getRouteByRouteModule){
		return portfolios?.find(f=>f['routeModuleName']===routeModuleName)?.route;
	}
	if(routeModuleName){
		return portfolios?.find(f=>f['routeModuleName']===routeModuleName);
	}
    if(moduleName){
     
		return portfolios?.find(f=>f['moduleName']===moduleName);
	}
    if(name){
		return portfolios?.find(f=>f['name']===name);
	}
	return null;
}

const getAllMenuPortfolio = () => {
	
    let portfolios = sessionStorage.getItem("menu-portfolios");
	portfolios = JSON.parse(portfolios);
 
    portfolios = portfolios?.map(item=>{
        item.services = Services[item.routeModuleName];
        item.portfolioConnected = item?.portfolioConnectedIds?.map(id=>{
            return portfolios.find(f=>f?.id?.toString()===id?.toString());
        }) 
        return item;
    });
	return portfolios;
}

const settings = {
    home_page:1, // Short Video Home Page Link Id
}

export { 
    Services,
	staticSettingPortfolioList,
    setMenuPortfolio,
	getMenuPortfolio,
    getAllMenuPortfolio,
    staticSettingPortfolioList2,
    settings,
};
