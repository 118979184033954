import {
  ExclamationCircleFilled,
  LeftCircleOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import { Carousel, Modal, Spin, message } from "antd";
import React, { useEffect, useState, useRef } from "react";
import BPMLevel2 from "./grid/BPMLevel2";
import BusinessProcessServices from "../../services/services/BusinessProcessServices";
import { COLORS } from "../../utils/Common";
import { UpdateChangeLog } from "../../utils/ChangeLogs";
import LoadingBar from "react-top-loading-bar";
import { useLocation } from "react-router";
import ApqcProcessServices from "../../services/services/ApqcProcessServices";

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style }}
      onClick={onClick}
      onDragOver={onClick}
    >
      <RightCircleOutlined />
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style }}
      onClick={onClick}
      onDragOver={onClick}
    >
      <LeftCircleOutlined />
    </div>
  );
};

const BusinessProcessGrid = ({ HeaderContnet, moduleName, ...props }) => {
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);
  const [lists, setLists] = useState([]);
  const [tempList, setTempList] = useState(lists);
  const location = useLocation();
  const [bcm2Index, setBcm2Index] = useState(null);
  const [activeKey, setAcitveKey] = useState([]);
  const [defaultAtiveKey, setDefaultAcitveKey] = useState([]);
  const [draggingItem, setDraggingItem] = useState(null);
  const [dragStart, setDragStart] = useState("");
  const [dragend, setDragEnd] = useState("");
  const [completeEnd, setCompleteEnd] = useState(false);

  const getProcessList = () => {
    if (moduleName === "BusinessProcesses") {
      return BusinessProcessServices;
    } else if (moduleName === "ApqcsProcesses") {
      return ApqcProcessServices;
    }
  };

  useEffect(() => {
    setTempList(lists);
  }, [lists]);

  const createNewArrey = async () => {
    setLoading(true);
    // console.log("testing dragstart", dragStart);
    // console.log("testing dragend", dragend);
    try {
      if (dragStart !== dragend && typeof dragend === "number") {
        await getProcessList().drag_update({
          id: dragStart,
          parentId: dragend,
        });
        await getBusinessProcessList();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setDragStart("");
      setDragEnd("");
    }
  };

  useEffect(() => {
    getBusinessProcessList();
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    // slidesToScroll: 1,
    // initialSlide: 0,
    //swipeToSlide: true,
    //draggable: true,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const getBusinessProcessList = async (
    filter_By,
    filter_ByText,
    filter_levels,
    filterOrder
  ) => {
    setLoading(true);
    if (ref.current) {
      ref.current.continuousStart();
    }
    try {
      const list = await getProcessList().getList(
        filter_By,
        filter_ByText,
        filter_levels,
        filterOrder
      );
      const resultData = list?.data?.data;
      setLists(resultData);
      const dlist = resultData
        ?.filter((item) => item.parentid === 0)
        ?.map((item) => `${item?.id}`);
      setDefaultAcitveKey(dlist);

      setLoading(false);
      if (ref.current) {
        ref.current.complete();
      }
    } catch (error) {
      setLoading(false);
      if (ref.current) {
        ref.current.complete();
      }
    }
  };

  const showConfirmDelete = (item, type) => {
    const id = item?.id || 0;
    Modal.confirm({
      title: "Are you sure want to delete?",
      icon: <ExclamationCircleFilled />,
      async onOk() {
        try {
          let result;
          result = await getProcessList().deletebusinessProcess(id);
          if (result) {
            let obj = {
              id: id,
              NameInSourceSystem: item?.NameInSourceSystem || "",
              DisplayName: item?.DisplayName || "",
              Description: item?.Description || "",
            };

            let field_OldData = {
              DisplayName: item?.DisplayName || "",
              Description: item?.Description || "",
            };

            await UpdateChangeLog(
              obj.id,
              field_OldData,
              obj,
              "Delete",
              moduleName
            );

            await getBusinessProcessList();
            message.success(result?.data?.msg);
          } else {
            message.error(result?.data?.msg);
          }
        } catch (error) {
          message.error(error?.message);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const create_Duplicate = async (obj) => {
    try {
      let result = await getProcessList().create(obj);
      if (result.data?.code == "200") {
        getBusinessProcessList();
        setBcm2Index(null);
      } else if (result.data?.code == "400") {
        message.error(result.data?.msg);
      }
    } catch (error) {
      if (error?.response?.data?.code) {
        message.error(error?.response?.data?.msg);
      }
    }
  };

  return (
    <>
      <LoadingBar color="#1f1f1f" ref={ref} />
      {HeaderContnet}
      <div className="applicationlist-page">
        <div style={{ margin: 24 }} className="slider-container">
          <Carousel {...settings} style={{ width: window?.innerWidth - 300 }}>
            {tempList?.map((item, index) => {
              let color = COLORS[parseInt(index % COLORS.length)];
              return (
                <div key={index} className="align-items-start text-center">
                  <BPMLevel2
                    list={tempList}
                    draggingItem={draggingItem}
                    setDraggingItem={setDraggingItem}
                    defaultAtiveKey={defaultAtiveKey}
                    index={index}
                    backgroundColor={color}
                    setItemDelete={showConfirmDelete}
                    openModal={props.openModal}
                    visibleModal={props.visibleModal}
                    setModalData={props.setModalData}
                    data={item}
                    basePath={location?.pathname}
                    create_Duplicate={create_Duplicate}
                    bcm2Index={bcm2Index}
                    setBcm2Index={setBcm2Index}
                    activeKey={activeKey}
                    setAcitveKey={setAcitveKey}
                    dragStart={dragStart}
                    setDragStart={setDragStart}
                    dragend={dragend}
                    setDragEnd={setDragEnd}
                    completeEnd={completeEnd}
                    setCompleteEnd={setCompleteEnd}
                    createNewArrey={createNewArrey}
                    loading={loading}
                    setLoading={setLoading}
                  />
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </>
  );
};

BusinessProcessGrid.defaultProps = {};

export default BusinessProcessGrid;
