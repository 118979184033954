import React, { useState } from 'react'; 
import DrawioLayout from "../components/diagram/drawio";
import { useParams } from 'react-router';


function DiagramPage() {
    const props=useParams();
    const [refreshTab, setrefreshTab] = useState(false);
    
    return (
        <>
            <DrawioLayout {...props} refreshTab={refreshTab} setrefreshTab={setrefreshTab} modelIDScenario />
        </>
    );
}

export default DiagramPage