import React, { useState, useEffect } from "react";
import {
  Form,
  Select,
  Button,
  Typography,
  Row,
  Col,
  Divider,
  Spin,
} from "antd";
import { transformLevelString } from "../../utils/Common";
import { normalizeString } from "../../utils/linksCommon";
import TagsOptions from "../portfoliosingleentry/portfolioTabs/tabinformations/TagsOptions";
import { useParams } from "react-router";
import GlobalTags from "../portfoliosingleentry/portfolioTabs/tabinformations/GlobalTag";
import linkServices from "../../services/services/LinkService";
import { getMenuPortfolio } from "../../utils/settingCommon";
import {
  ContentForm,
  DatePickerForm,
  InputForm,
  InputNumberForm,
  SelectForm,
  TextAreaForm,
  TimePickerForm,
} from "./DynamicFormFields";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/en"; // Ensure you've imported the correct locale
import PortfolioTagServices from "../../services/services/PortfolioTagService";
import { checkRole } from "../../helper/useUserData";

dayjs.extend(localizedFormat); // Extend with the localizedFormat plugin
dayjs.locale("en"); // Set Day.js locale

const { Option } = Select;

const DynamicForm = ({
  data,
  form,
  formData,
  labelCol,
  wrapperCol,
  createAndUpdate,
  level = null,
  parentLevel = null,
  tagOptions = [],
  tagLoading = false,
  moduleName = null,
  showSaveButton,
  ExtraFormFields,
  GlobalTagOptions,
  ...props
}) => {
  const onFinish = (values) => {
    createAndUpdate(values);
  };

  const [showLoader, setShowLoader] = useState(false);
  const [template, setTemplate] = useState([]);
  const [formData2, setFormData2] = useState({});
  const [links, setLinks] = useState([]);
  const [newTagData, setNewTagData] = useState([]);

  useEffect(() => {
    const fetchUpdatedTags = async () => {
      try {
        const response = await PortfolioTagServices.getAllTags({
          moduleName: moduleName,
          addAssociation: false,
        });
        setNewTagData(response?.data?.data);
      } catch (err) {
        console.error("Something went wrong");
      }
    };
    if(moduleName){
      fetchUpdatedTags();
    }
  }, [moduleName]);



  useEffect(() => {
    setTemplate(GlobalTagOptions);
  }, [GlobalTagOptions]);

  useEffect(() => {
    setShowLoader(true);
    if (formData?.id && moduleName) {
      TempLinks({ setLinks, moduleName, moduleID: formData?.id });
    }
    setTimeout(() => {
      setShowLoader(false);
    }, 500);
  }, [moduleName, formData?.id]);

  const LevelDisplay = ({ index, data, property ,moduleName, formData}) => {
    let portfolioData = getMenuPortfolio({moduleName});
    
    return (
      (
        <div>
          {(level || parentLevel) && property.name === "level" ? (
            <div>
              <div className="mb-1 h7"> {level} </div>
              <div className="mb-1 h7">
                {
                  (()=>{
                    if(parentLevel){
                      return <React.Fragment>
                        (Parent : <a href={`${portfolioData?.route}/${formData?.parentid}`} target="_blank" rel="noopener noreferrer">{parentLevel}</a>)
                      </React.Fragment>
                    }
                  })()
                }
              </div>
            </div>
          ) : (
            <Typography.Text>{property.name}</Typography.Text>
          )}
        </div>
      ) || <></>
    );
  };

  useEffect(() => {
    form?.setFieldsValue({ ...formData, level: transformLevelString(level) });
  }, [level]);

  useEffect(() => {
    const formValuea = form.getFieldsValue("tags")
    // console.log('testing form value top', formValuea?.tags);
    if (!form) return; // Early exit if form is not available

    // Retrieve current form values for "tags"
    const formValue = form.getFieldsValue("tags") || {}; // Default to empty object if undefined
    const formTags = Array.isArray(formValue.tags) ? formValue.tags : []; // Ensure it's an array

    // console.log('testing new tags', newTagData);
    // console.log('testing form value', formTags);

    if (formTags.length > 0) {
      const zz = typeof(formTags[0])
      if (zz === 'number'){

        const filteredArrays = newTagData
                ?.filter((item) => formTags?.includes(item.id))
                ?.map((item) => ({ ...item, value: item.id, label: item.name })) || [];
            
            form.setFieldsValue({ ...formData, tags: filteredArrays });

      }else{
          // Update form values including the existing tags
          form.setFieldsValue({ ...formData, tags: formTags });
      }
      
    } else {
        if (Object.keys(formData).length > 0) {
            // Filter and map newTagData based on formData tags
            const filteredArray = newTagData
                ?.filter((item) => formData?.tags?.includes(item.id))
                ?.map((item) => ({ ...item, value: item.id, label: item.name })) || [];
            
            form.setFieldsValue({ ...formData, tags: filteredArray });
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [formData, newTagData, form]);

const setDateAndTimeFieldValue = (name, value) => {
  form.setFieldValue(name, value);
};

  return (
    <>
      <Spin spinning={showLoader} size="default">
        <div className=" position-relative p-4 signal-formsec">
          <Form
            //   name="dynamic_form"
            onFinish={onFinish}
            form={form}
            labelCol={{ span: labelCol }}
            wrapperCol={{ span: wrapperCol }}
            className="mt-1"
            requiredMark={false}
            initialValues={formData}
          >
            {data
              .sort((a, b) => a.position - b.position)
              .map((section, index) => {
                return (
                  section?.properties?.length > 0 && (
                    <React.Fragment key={Math.random()}>
                      <Typography.Title level={5} className="mb-2">
                        {normalizeString(section?.name)}
                      </Typography.Title>
                      <Row>
                        {section?.properties
                          ?.sort((a, b) => a.position - b.position)
                          ?.map((property, index) => {
                            return (
                              <Col
                                xs={24}
                                sm={12}
                                md={12}
                                lg={12}
                                key={Math.random()}
                              >
                                <React.Fragment key={Math.random()}>
                                  <Row
                                    gutter={[16, 16]}
                                    className="mb-0 formitem-sec align-items-center"
                                    key={Math.random()}
                                  >
                                    <Col
                                      className="gutter-row"
                                      span={24 - wrapperCol}
                                      key={`col-label-${property?.id || index}`}
                                    >
                                      <span>{property?.displayName}</span>
                                      {property?.isRequired && (
                                        <span className="text-danger">*</span>
                                      )}
                                    </Col>
                                    <Col
                                      className="gutter-row"
                                      span={wrapperCol}
                                      key={property?.id || index}
                                    >
                                      {(() => {
                                        let commonFormItemObject = {
                                          key: property?.id,
                                          name: property?.name,
                                          wrapperCol: {
                                            xs: 20,
                                            sm: 18,
                                            md: 16,
                                            lg: 16,
                                          },
                                          rules: [
                                            {
                                              required: property?.isRequired,
                                              message: `${property?.displayName} is required!`,
                                            },

                                            // property?.type === "Free text"
                                            //   ? [
                                            //       {
                                            //         max: 255,
                                            //         message: `${property?.displayName} must be 255 characters or less!`,
                                            //       },
                                            //     ]
                                            //   : [],
                                          ],
                                          className: "mb-2",
                                        };

                                        let ComnObj = {
                                          form,
                                          initialValue:
                                            formData[property?.name],
                                        };

                                        if (property?.isText) {
                                          console.log('testing property?.isText',property?.isText);
                                          
                                          let Content = () => (
                                            <LevelDisplay
                                              index={index}
                                              data={data}
                                              property={property}
                                              moduleName={moduleName}
                                              formData={formData}
                                            />
                                          );
                                          return (
                                            <ContentForm
                                              FormItemProps={
                                                commonFormItemObject
                                              }
                                              Content={Content}
                                              {...ComnObj}
                                            />
                                          );
                                        } else if (
                                          property?.type === "Single select" ||
                                          property?.type === "Multi select"
                                        ) {
                                          return (
                                            <SelectForm
                                              FormItemProps={
                                                commonFormItemObject
                                              }
                                              FormFieldProps={{
                                                mode:
                                                  property?.type ===
                                                  "Multi select"
                                                    ? "multiple"
                                                    : "default",
                                                options:
                                                  property?.propertyOptions?.map(
                                                    (option) => {
                                                      return {
                                                        value: option?.id,
                                                        label: option?.name,
                                                        option,
                                                      };
                                                    }
                                                  ),
                                              }}
                                              {...ComnObj}
                                            />
                                          );
                                        } else if (
                                          property?.type === "Date time"
                                        ) {
                                          return (
                                            <>
                                            <DatePickerForm
                                              FormItemProps={
                                                commonFormItemObject
                                              }
                                              FormFieldProps={{
                                                showTime: true,
                                                className:
                                                  "mb-2 text-start w-full",
                                              }}
                                              value={formData[property?.name]}
                                              setValue={setDateAndTimeFieldValue}
                                              {...ComnObj}
                                            />

                                            </>
                                          );
                                        } else if (property?.type === "Date") {
                                          return (
                                            <>
                                            <DatePickerForm
                                              FormItemProps={
                                                commonFormItemObject
                                              }
                                              FormFieldProps={{
                                                className:
                                                  "mb-2 text-start w-full",
                                              }}
                                              value={formData[property?.name]}
                                              setValue={setDateAndTimeFieldValue}
                                              {...ComnObj}
                                            />
                                            </>
                                          );
                                        } else if (property?.type === "Time") {
                                          return (
                                            <>
                                            <TimePickerForm
                                              FormItemProps={
                                                commonFormItemObject
                                              }
                                              FormFieldProps={{
                                                className:
                                                  "mb-2 text-start w-full",
                                              }}
                                              value={formData[property?.name]}
                                              setValue={setDateAndTimeFieldValue}
                                              {...ComnObj}
                                            />
                                            </>
                                          );
                                        } else if (
                                          property?.type === "Long text"
                                        ) {
                                          return (
                                            <TextAreaForm
                                              FormItemProps={
                                                commonFormItemObject
                                              }
                                              FormFieldProps={{
                                                className:
                                                  "mb-2 text-start w-full",
                                              }}
                                              {...ComnObj}
                                            />
                                          );
                                        } else if (
                                          property?.type === "Numeric"
                                        ) {
                                          return (
                                            <InputNumberForm
                                              FormItemProps={
                                                commonFormItemObject
                                              }
                                              FormFieldProps={{
                                                className:
                                                  "mb-2 text-start w-full",
                                              }}
                                              {...ComnObj}
                                            />
                                          );
                                        } else {
                                          let link = "";
                                          if (property?.name === "servicenow") {
                                            // let url = `https://volvocars.service-now.com/cmdb_ci_service_discovered.do?sys_id=${formData[property?.name]}&sysparm_view=Default+view&sysparm_view_forced=true`;
                                            link = getServiceNowLink(
                                              formData2[property?.name],
                                              links
                                            );
                                          }
                                          return (
                                            <InputForm
                                              FormItemProps={{
                                                ...commonFormItemObject,
                                                rules: [
                                                  {
                                                    required:
                                                      property?.isRequired,
                                                    message: `${property?.displayName} is required!`,
                                                  },
                                                  {
                                                    max: 255,
                                                    message: `${property?.displayName} must be 255 characters or less!`,
                                                  },
                                                ],
                                              }}
                                              link={link}
                                              updateKey="sys_id"
                                              linkKeys={[`sys_id`]}
                                              {...ComnObj}
                                            />
                                          );
                                        }
                                      })()}

                                      
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              </Col>
                            );
                          })}
                      </Row>
                      <Divider />
                    </React.Fragment>
                  )
                );
              })
            }
            {props?.ExtraFormFields2}
            <div className="formitem-alteropt">
              {!tagLoading && data && data?.length > 0 && moduleName && (
                  <TagsOptions
                    form={form}
                    formData={formData}
                    tagOptions={newTagData}
                    moduleName={moduleName}
                  />
                )
              }

              {template.length > 0 && data && data?.length > 0 && template?.map((item)=>{
                return(
                  <React.Fragment key={Math.random()}>
                    <GlobalTags
                      form={form}
                      formData={formData}
                      tagOptions={item?.templateOption}
                      templatename={item?.templatename}
                      templatePropName={item?.templatepropname}
                      type={item.type}
                      item={item}
                      
                    />
                  </React.Fragment>
                )
              })}
            </div>

            {ExtraFormFields}
            {!tagLoading && data && data?.length > 0 && showSaveButton && (
              <div className="text-start mt-5">
                { checkRole('save') && <Button
                  className="me-2"
                  size="middle"
                  type="primary"
                  htmlType="submit"
                >
                  Save
                </Button>}
              </div>
            )}
          </Form>
        </div>
      </Spin>
    </>
  );
};

DynamicForm.defaultProps = {
  showSaveButton: true,
  GlobalTagOptions: [],
  ExtraFormFields2: (props) => {
    return <React.Fragment />;
  },
  ExtraFormFields: (props) => {
    return <React.Fragment />;
  },
};

export default DynamicForm;

export const TempLinks = async ({ links, setLinks, moduleName, moduleID }) => {
  let routeModuleName = getMenuPortfolio({ moduleName })?.routeModuleName;
  let response = await linkServices.getListByModule({
    moduleName: routeModuleName,
    moduleID,
  });
  if (response?.data?.data?.length > 0) {
    let lists = response?.data?.data?.map((item) => {
      let itemUrl = new URL(item?.URL);
      let urlParams = new URLSearchParams(itemUrl.search.substring(1));
      item.URLOrigin = itemUrl.origin + itemUrl?.pathname;
      item.URLParams = Object.fromEntries(urlParams);
      return item;
    });
    setLinks(lists);
  }
};

const getServiceNowLink = (sys_id, links) => {
  let urlData = links.find(
    (f) => "sys_id" in f.URLParams && "sysparm_view" in f.URLParams
  );
  if (urlData) {
    let params = urlData?.URLParams || {};
    params.sys_id = sys_id;
    return urlData?.URLOrigin + "?" + new URLSearchParams(params).toString();
  }
  return "";
};
